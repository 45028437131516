<div class="emc-box">
  <div class="header">
    <div class="name">
      <mat-checkbox
        (change)="onChange($event)"
        [(ngModel)]="selected"
        [disabled]="disabled"
        color="primary"
      >
      </mat-checkbox>
      <h4>{{ adminReport.inspection_date | date: "MM-dd-yyyy" }}</h4>
    </div>
    <div class="action-buttons">
      <mat-icon
        (click)="downloadAttachments()"
        color="primary"
        matTooltip="Download File"
        >cloud_download</mat-icon
      >
      <mat-icon (click)="open('view')" color="primary" matTooltip="View"
        >visibility</mat-icon
      >
      <mat-icon
        (click)="open('edit')"
        *ngIf="
          PermissionUtils.hasPermission(PermissionType.INSPECTION_REPORT_EDIT)
        "
        color="primary"
        matTooltip="Edit"
        >edit
      </mat-icon>
      <mat-icon
        (click)="delete()"
        *ngIf="
          PermissionUtils.hasPermission(
            PermissionType.INSPECTION_REPORT_DELETE
          ) && !deleting
        "
        color="warn"
        matTooltip="Delete"
        >delete
      </mat-icon>
      <mat-spinner
        *ngIf="deleting"
        [diameter]="20"
        strokeWidth="2"
      ></mat-spinner>
    </div>
  </div>
  <div class="body">
    <div class="item">
      <p class="title">Project</p>
      <p class="content" matTooltip="{{ adminReport?.project?.title }}">
        {{ adminReport?.project?.title }}
      </p>
    </div>
    <div class="item">
      <p class="title">Client</p>
      <p class="content" matTooltip="{{ adminReport?.client?.name }}">
        {{ adminReport?.client?.name }}
      </p>
    </div>
    <div class="item">
      <p class="title">Type Of Service</p>
      <p class="content">{{ adminReport.type | unslugify | titlecase }}</p>
    </div>
  </div>
</div>
