import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Unsubscribe } from "../../../../../../../../../unsubscribe";
import * as _ from "lodash";
import { DynamicQuestionType } from "../../../../../../../../../utils/constants";
import { CommonUtils } from "../../../../../../../../../utils/common-utils";

@Component({
  selector: "ae-dynamic-question",
  templateUrl: "./dynamic-question.component.html",
  styleUrls: ["./dynamic-question.component.scss"]
})
export class DynamicQuestionComponent extends Unsubscribe implements OnInit {
  @Input() group: FormArray;
  @Input() templateId: number;
  @Output() delete = new EventEmitter();
  sortOptions: number[] = [];

  isPanelOpen = true;
  types = CommonUtils.iterateEnum(DynamicQuestionType);

  constructor() {
    super();
  }

  @Input() set length(length: number) {
    this.sortOptions = Array.from({ length }, (v, i) => i + 1);
  }

  ngOnInit() {}

  addOption(question: FormGroup) {
    const array: FormArray = question.get("options") as FormArray;
    array.push(
      new FormGroup({
        title: new FormControl(null, Validators.required)
      })
    );
  }

  changeSortOrder(oldIndex, newIndex: number) {
    const oldControl = this.group.at(oldIndex);
    this.group.removeAt(oldIndex);
    this.group.insert(newIndex, oldControl);
    this.computeSortOrder();
  }

  private computeSortOrder() {
    const array = this.group.value as any[];
    array.forEach((q, index) => {
      q.sort_order = index + 1;
    });
    this.group.patchValue(array);
  }

  addQuestion() {
    const newQuestion = new FormGroup({
      title: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      options: new FormArray([]),
      carryover_from_project: new FormControl(true, Validators.required),
      is_required: new FormControl(true, Validators.required),
      is_active: new FormControl(true, Validators.required),
      sort_order: new FormControl(this.group.length + 1)
    });
    this.group.push(newQuestion);

    this.computeSortOptions(this.group.length);
  }

  computeSortOptions(length) {
    this.sortOptions = Array.from({ length }, (v, i) => i + 1);
  }

  isOptionsVisible(type: string) {
    return CommonUtils.isOptionsVisible(type);
  }

  deleteOption(question: FormGroup, index: number) {
    const array: FormArray = question.get("options") as FormArray;
    array.removeAt(index);
  }

  deleteDynamicQuestion(question: FormGroup, index: number) {
    const questionId = question.value.id;
    this.delete.emit({ index, questionId });
    this.computeSortOrder();
  }
}
