import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PermissionsStore } from "./permissions.store";
import { ListResponse } from "../../responses/list.response";
import { Permission } from "@emc-models/entities/permission.model";
import { PermissionsQuery } from "@emc-state/permissions/permissions.query";
import { ApiService } from "@emc-modules/core/services/api.service";
import { take } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class PermissionsService {
  constructor(
    protected store: PermissionsStore,
    private apiService: ApiService,
    private query: PermissionsQuery
  ) {}

  ///////////////////////////////////////////////////
  // METHODS TO INTERACT WITH BACKEND
  ///////////////////////////////////////////////////

  list(): Observable<Permission[]> {
    let _loading = false;
    let _loaded = false;

    this.query.selectLoading$.pipe(take(1)).subscribe(l => (_loading = l));

    this.query.selectLoaded$.pipe(take(1)).subscribe(l => (_loaded = l));

    if (!_loading && !_loaded) {
      this.store.update({ loading: true, loaded: false });
      this.apiService
        .get<ListResponse<Permission>>("permissions", true)
        .subscribe(res => {
          this.store.add(res.data);
          this.store.update({ loading: false, loaded: true });
        });
    }
    return this.query.selectAll();
  }
}
