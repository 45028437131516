import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ae-jacksonville-energy",
  templateUrl: "./jacksonville-energy.component.html",
  styleUrls: ["./jacksonville-energy.component.scss"]
})
export class JacksonvilleEnergyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
