import { Injectable } from "@angular/core";
import { EntityStore, ID, StoreConfig } from "@datorama/akita";
import {
  MailingListCompact,
  MailingListFull
} from "@emc-models/entities/mailing-list.model";

export interface MailingListsState {
  ids: ID[];
  entities: { [id: number]: MailingListFull | MailingListCompact };
  isLoading: boolean;
  isLoaded: boolean;
  fullLoadingIds: ID[];
  fullLoadedIds: ID[];
}

const initialState: MailingListsState = {
  ids: [],
  entities: {},
  isLoading: false,
  isLoaded: false,
  fullLoadingIds: [],
  fullLoadedIds: []
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "mailing-lists" })
export class MailingListsStore extends EntityStore<MailingListsState> {
  constructor() {
    super(initialState);
  }

  setFullLoading(mailingListId: ID) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadingIds.indexOf(mailingListId) === -1) {
        fullLoadingIds = [...fullLoadingIds, mailingListId];
      }
      const loadedIndex = fullLoadedIds.indexOf(mailingListId);
      if (loadedIndex !== -1) {
        fullLoadedIds = [
          ...fullLoadedIds.slice(0, loadedIndex),
          ...fullLoadedIds.slice(loadedIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }

  setFullLoaded(mailingListId: ID) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadedIds.indexOf(mailingListId) === -1) {
        fullLoadedIds = [...fullLoadedIds, mailingListId];
      }
      const loadingIndex = fullLoadingIds.indexOf(mailingListId);
      if (loadingIndex !== -1) {
        fullLoadingIds = [
          ...fullLoadingIds.slice(0, loadingIndex),
          ...fullLoadingIds.slice(loadingIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }
}
