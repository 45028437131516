import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from "@angular/material/dialog";
import { ResponsiveActionItem } from "@emc-models/entities/responsive-action-item.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { ISelectOption } from "@emc-modules/shared/components/emc-search-field/emc-search-field.component";
import { OptionsService } from "@emc-services/options.service";
import { Unsubscribe } from "../../../../unsubscribe";
import { InspectionsService } from "@emc-modules/inspections/services/inspections.service";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { PermissionType } from "../../../../enums/permission-type.enum";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import { UserNameOnly } from "@emc-models/entities/user.model";

@Component({
  selector: "ae-bulk-update-actions",
  templateUrl: "./bulk-update-actions.component.html",
  styleUrls: ["./bulk-update-actions.component.scss"]
})
export class BulkUpdateActionsComponent extends Unsubscribe implements OnInit {
  userOptions: ISelectOption[] = [];
  actionSelectOptions: ISelectOption[] = [];
  updating = false;
  hasSignOffPermission = PermissionUtils.hasPermission(
    PermissionType.INSPECTION_SIGN_OFF_RESPONSIVE_ACTION
  );
  hasAddEditPermission = PermissionUtils.hasPermission(
    PermissionType.INSPECTION_ADD_EDIT_RESPONSIVE_ACTION
  );
  controls = {
    completed_by: new FormControl(null, [Validators.required]),
    completed_on: new FormControl(new Date(), [Validators.required]),
    responsive_actions: new FormControl([], [Validators.required])
  };

  form = new FormGroup(this.controls);

  constructor(
    private option: OptionsService,
    private dialogRef: MatDialogRef<BulkUpdateActionsComponent>,
    private dialog: MatDialog,
    private inspectionService: InspectionsService,
    private authManager: AuthManager,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    data?: { items: ResponsiveActionItem[]; selectedIds: number[] }
  ) {
    super();
    this.actionSelectOptions = data.items.map(i => {
      return {
        title: i.title,
        value: i.id
      };
    });

    if (data.selectedIds && data.selectedIds.length) {
      this.controls.responsive_actions.setValue(data.selectedIds);
    }
  }

  ngOnInit() {
    this.option
      .getUsers()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(u => {
        this.userOptions = u.map(user => {
          return {
            title: user.first_name + " " + user.last_name,
            value: user.id
          };
        });
      });
    this.authManager
      .getLoggedInUser()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(user => {
        this.controls.completed_by.setValue(user.id);
      });
  }

  close() {
    this.dialog.closeAll();
  }

  updateAllActions() {
    this.updating = true;
    const ids = this.form.value.responsive_actions;
    const data: any = {
      responsive_action_item_ids: ids
    };

    if (this.hasSignOffPermission) {
      data.completed_by = this.form.value.completed_by;
      data.completed_on = this.controls.completed_on.value
        ? moment(this.controls.completed_on.value).format("YYYY-MM-DD")
        : null;
    }

    this.inspectionService.bulkUpdateResponsiveActionItem(ids, data).subscribe(
      value => {
        this.updating = false;
        this.inspectionService.isActionUpdated.next();
        this.close();
        this.toastr.success("Action Items Successfully Updated");
      },
      error => {
        this.updating = false;
        console.log("error", error);
      }
    );
  }
}
