import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";

@Component({
  selector: "ae-generic-confirmation",
  templateUrl: "./generic-confirmation.component.html",
  styleUrls: ["./generic-confirmation.component.scss"]
})
export class GenericConfirmationComponent implements OnInit {
  @Input() title = "Delete";
  @Input() description = "Are you sure you want to delete this?";
  @Input() yesButtonTitle = "Yes";
  @Input() noButtonTitle = "No";
  @Input() showYesButton = true;
  @Input() showNoButton = true;
  showForceDelete = false;
  forceDeleteValue: FormControl;
  disableForceDelete = false;

  constructor(
    private dialogRef: MatDialogRef<GenericConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      description: string;
      showForceDelete: boolean;
      disableForceDelete: boolean;
    }
  ) {
    if (data) {
      this.title = data.title;
      this.description = data.description;
      this.showForceDelete = data.showForceDelete;
      this.disableForceDelete = data.disableForceDelete;
    }
    this.forceDeleteValue = new FormControl({
      value: this.disableForceDelete,
      disabled: this.disableForceDelete
    });
  }

  ngOnInit() {}

  close(data?: { yesClicked: boolean }) {
    this.dialogRef.close({
      ...data,
      force_delete: this.forceDeleteValue.value
    });
  }

  clickNo() {
    this.close({ yesClicked: false });
  }

  clickYes() {
    this.close({ yesClicked: true });
  }
}
