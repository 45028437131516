import { Injectable } from "@angular/core";
import { ID } from "@datorama/akita";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { ApiService } from "@emc-modules/core/services/api.service";
import { ShowResponse } from "../../../../../responses/show.response";
import { ListResponse } from "../../../../../responses/list.response";
import { InspectionCategoryQuery } from "@emc-state/tools/inspection-category/inspection-category.query";
import { InspectionCategoryStore } from "@emc-state/tools/inspection-category/inspection-category.store";
import { InspectionCategory } from "@emc-models/entities/inspection-category.model";
import * as moment from "moment";

@Injectable({ providedIn: "root" })
export class InspectionCategoryService {
  constructor(
    private query: InspectionCategoryQuery,
    private store: InspectionCategoryStore,
    private apiService: ApiService
  ) {}

  isLoading(): Observable<boolean> {
    return this.query.isLoading$;
  }

  isLoaded(): Observable<boolean> {
    return this.query.isLoaded$;
  }

  getCategories(force = false): Observable<InspectionCategory[]> {
    let _loading = false;
    let _loaded = false;

    this.isLoading()
      .pipe(take(1))
      .subscribe(v => (_loading = v));

    this.isLoaded()
      .pipe(take(1))
      .subscribe(v => (_loaded = v));

    if (force || (!_loaded && !_loading)) {
      this.store.update({
        loading: true,
        loaded: false
      });
      this.apiService
        .get<ListResponse<InspectionCategory>>("question-categories", true)
        .pipe(map(res => res.data))
        .subscribe(inspectionCategories => {
          this.store.add(inspectionCategories);
          this.store.update({
            loaded: true,
            loading: false
          });
        });
    }
    return this.query.selectAll() as Observable<InspectionCategory[]>;
  }

  addCategory(data: any): Observable<InspectionCategory> {
    return this.apiService
      .post<ShowResponse<InspectionCategory>>("question-categories", true, data)
      .pipe(
        map(res => {
          this.store.add(res.data);
          return res.data;
        })
      );
  }

  deleteCategory(categoryId: ID, forceDelete: boolean, title: string) {
    return this.apiService
      .delete(`question-categories/${categoryId}`, true)
      .pipe(
        map(res => {
          if (forceDelete) {
            this.store.remove(categoryId);
          } else {
            this.store.update(categoryId, {
              title: "(Deleted) " + title,
              is_active: false,
              deleted_at: moment().format()
            });
          }
        })
      );
  }

  updateCategory(categoryId: ID, data: any): Observable<InspectionCategory> {
    return this.apiService
      .put<
        ShowResponse<InspectionCategory>
      >(`question-categories/${categoryId}`, true, data)
      .pipe(
        map(res => {
          this.store.upsert(categoryId, res.data);
          return res.data;
        })
      );
  }

  rearrage(data: any[]): Observable<InspectionCategory[]> {
    return this.apiService
      .put<
        ListResponse<InspectionCategory>
      >(`question-categories/rearrange`, true, data)
      .pipe(
        map(res => {
          this.store.upsertMany(res.data);
          return res.data;
        })
      );
  }
}
