import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonUtils } from "@emc-utils/common-utils";
import { ID } from "@datorama/akita";
import { Observable } from "rxjs";

@Component({
  selector: "ae-edit-dialog",
  templateUrl: "./edit-dialog.component.html",
  styleUrls: ["./edit-dialog.component.scss"]
})
export class EditDialogComponent implements OnInit {
  editForm: FormGroup;
  formData: { id: ID; title: string };
  saving: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      data: any;
      callback: (id: ID, title: string) => Observable<any>;
    },
    private dialogRef: MatDialogRef<EditDialogComponent>
  ) {
    this.formData = data.data;
  }

  ngOnInit() {
    this.editForm = new FormGroup({
      title: new FormControl(this.formData.title, {
        validators: [Validators.required]
      })
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.editForm.invalid) {
      CommonUtils.markControlsAsTouched(this.editForm);
      return;
    }

    this.saving = true;
    const title = this.editForm.controls.title.value;
    this.data.callback(this.formData.id, title).subscribe(
      () => this.onClose(),
      () => (this.saving = false)
    );
  }
}
