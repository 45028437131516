import { Component, Inject, OnInit } from "@angular/core";
import { Unsubscribe } from "../../../../unsubscribe";
import { Comment } from "@emc-models/entities/compliance.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import { takeUntil } from "rxjs/operators";
import { UserFull } from "@emc-models/entities/user.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonUtils } from "@emc-utils/common-utils";

@Component({
  selector: "ae-add-edit-note",
  templateUrl: "./add-edit-note.component.html",
  styleUrls: ["./add-edit-note.component.scss"]
})
export class AddEditNoteComponent extends Unsubscribe implements OnInit {
  controls = {
    comment: new FormControl(null, [Validators.required]),
    date: new FormControl(moment().format("YYYY-MM-DD"), [Validators.required]),
    user_name: new FormControl(null, [Validators.required])
  };
  form = new FormGroup(this.controls);
  authUser: UserFull;
  note: Comment;

  constructor(
    private authManager: AuthManager,
    private dialogRef: MatDialogRef<AddEditNoteComponent>,
    @Inject(MAT_DIALOG_DATA) data?: Comment
  ) {
    super();

    if (data?.comment) {
      this.note = data;
    }

    if (data.date) {
      this.controls.date.patchValue(data.date);
    }

    if (this.note) {
      this.form.patchValue({
        comment: this.note.comment,
        date: this.note.date,
        user_name: this.note.user_name
      });
    }
  }

  ngOnInit() {
    this.authManager
      .getLoggedInUser()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(user => {
        this.authUser = user;
        if (!this.note) {
          this.controls.user_name.setValue(
            user.first_name + " " + user.last_name
          );
        }
      });
  }

  save() {
    if (this.form.invalid) {
      CommonUtils.markControlsAsTouched(this.form);
      return;
    }
    this.close(this.form.value);
  }

  close(data?: Comment) {
    if (data?.date) {
      data.date = moment(data.date).format("YYYY-MM-DD");
    }

    this.dialogRef.close(data);
  }
}
