import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ae-mi-homes-energy",
  templateUrl: "./mi-homes-energy.component.html",
  styleUrls: ["./mi-homes-energy.component.scss"]
})
export class MiHomesEnergyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
