import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ae-dr-horton",
  templateUrl: "./dr-horton.component.html",
  styleUrls: ["./dr-horton.component.scss"]
})
export class DrHortonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
