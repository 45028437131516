import * as _ from "lodash";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { Error } from "@emc-models/error.model";
import { Injectable } from "@angular/core";

@Injectable()
export class ErrorService {
  private subject = new Subject<Error>();

  all(): Observable<Error> {
    return this.subject.asObservable();
  }

  only(code: number): Observable<Error> {
    return this.subject.asObservable().pipe(
      filter((err: Error) => {
        return err.code === code;
      })
    );
  }

  emit(model: Error) {
    this.subject.next(model);
  }

  include(codes: number[]): Observable<Error> {
    return this.subject
      .asObservable()
      .pipe(filter((err: Error) => _.includes(codes, err.code)));
  }

  exclude(codes: number[]): Observable<Error> {
    return this.subject
      .asObservable()
      .pipe(filter((err: Error) => !_.includes(codes, err.code)));
  }
}
