import { Injectable } from "@angular/core";
import { EntityState, EntityStore, ID, StoreConfig } from "@datorama/akita";
import { Inspection } from "@emc-models/entities/inspection.model";
import { QuestionType } from "../../enums/question-type.enum";
import { ResponsiveActionItem } from "@emc-models/entities/responsive-action-item.model";
import { DischargePoint } from "@emc-models/entities/discharge-point.model";
import { ERecipients } from "@emc-models/entities/project.model";
import { EnergyInspectionType } from "../../enums/energy-inspection-type.enum";

export interface InspectionState extends EntityState<Inspection> {
  selectedInspectionIds: ID[];
  stormWaterSiteInspectionIds: number[];
  safetyInspectionIds: number[];
  qaInspectionIds: number[];
  energyInspectionIds: number[];
  qaEnergyInspectionIds: number[];
  stormWaterSiteInspectionOffset: number;
  safetyInspectionOffset: number;
  qaInspectionOffset: number;
  energyInspectionOffset: number;
  qaEnergyInspectionOffset: number;
  isStormWaterSiteInspectionLoading: boolean;
  isStormWaterSiteInspectionLoaded: boolean;
  isSafetyInspectionLoading: boolean;
  isSafetyInspectionLoaded: boolean;
  isQaInspectionLoading: boolean;
  isQaInspectionLoaded: boolean;
  isQaEnergyInspectionLoading: boolean;
  isQaEnergyInspectionLoaded: boolean;
  isEnergyInspectionLoading: boolean;
  isEnergyInspectionLoaded: boolean;
  fullLoadingIds: ID[];
  fullLoadedIds: ID[];
  isLoading: boolean;
  isLoaded: boolean;
  offset: number;
  startDate: string;
  endDate: string;
  selectedUserIds: ID[];
  selectedClientIds: ID[];
  selectedProjectIds: ID[];
  searchQuery?: string;
  inspectionType?: QuestionType;
  priorFinalizedActionItems: { [inspectionId: number]: ResponsiveActionItem[] };
  priorFinalizedActionItemsLoading: { [inspectionId: number]: boolean };
  priorFinalizedActionItemsLoaded: { [inspectionId: number]: boolean };
  dischargePoints: { [projectId: number]: DischargePoint[] };
  dischargePointsLoading: { [projectId: number]: boolean };
  dischargePointsLoaded: { [projectId: number]: boolean };
  eRecipients: { [projectId: string]: ERecipients };
  eRecipientsLoading: { [projectId: string]: boolean };
  eRecipientsLoaded: { [projectId: string]: boolean };
  projectMarkersLoading: { [projectId: number]: boolean };
  projectMarkersLoaded: { [projectId: number]: boolean };
  projectMarkers: { [projectId: number]: ResponsiveActionItem[] };
  energyInspectionTypes: EnergyInspectionType[];
  QAServiceTypes: number[];
  ui: {
    selectedTab: number;
  };
  qaEnergyGroupInspection: {
    [key: string]: {
      offset: number;
      loaded: boolean;
      loading: boolean;
      ids: number[];
    };
  };
}

const initialState: InspectionState = {
  selectedInspectionIds: [],
  stormWaterSiteInspectionIds: [],
  qaEnergyGroupInspection: {},
  safetyInspectionIds: [],
  qaInspectionIds: [],
  energyInspectionIds: [],
  qaEnergyInspectionIds: [],
  preAcquisitionInspectionIds: [],
  stormWaterSiteInspectionOffset: 0,
  safetyInspectionOffset: 0,
  preAcquisitionInspectionOffset: 0,
  qaInspectionOffset: 0,
  energyInspectionOffset: 0,
  qaEnergyInspectionOffset: 0,
  isStormWaterSiteInspectionLoading: false,
  isPreAcquisitionInspectionLoading: false,
  isPreAcquisitionInspectionLoaded: false,
  isStormWaterSiteInspectionLoaded: false,
  isSafetyInspectionLoading: false,
  isSafetyInspectionLoaded: false,
  isQaInspectionLoading: false,
  isQaInspectionLoaded: false,
  isEnergyInspectionLoading: false,
  isEnergyInspectionLoaded: false,
  fullLoadingIds: [],
  startDate: null,
  endDate: null,
  fullLoadedIds: [],
  isLoading: false,
  isLoaded: false,
  offset: 0,
  selectedUserIds: [],
  selectedClientIds: [],
  selectedProjectIds: [],
  priorFinalizedActionItems: {},
  priorFinalizedActionItemsLoading: {},
  priorFinalizedActionItemsLoaded: {},
  dischargePoints: {},
  dischargePointsLoading: {},
  dischargePointsLoaded: {},
  eRecipients: {},
  eRecipientsLoading: {},
  eRecipientsLoaded: {},
  projectMarkers: {},
  projectMarkersLoading: {},
  projectMarkersLoaded: {},
  energyInspectionTypes: [],
  QAServiceTypes: [],
  ui: {
    selectedTab: 0
  },
  isQaEnergyInspectionLoading: false,
  isQaEnergyInspectionLoaded: false
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "inspections" })
export class InspectionsStore extends EntityStore<InspectionState, Inspection> {
  constructor() {
    super(initialState);
  }

  setFullLoading(inspectionId: ID) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadingIds.indexOf(inspectionId) === -1) {
        fullLoadingIds = [...fullLoadingIds, inspectionId];
      }
      const loadedIndex = fullLoadedIds.indexOf(inspectionId);
      if (loadedIndex !== -1) {
        fullLoadedIds = [
          ...fullLoadedIds.slice(0, loadedIndex),
          ...fullLoadedIds.slice(loadedIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }

  setFullLoaded(inspectionId: ID) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadedIds.indexOf(inspectionId) === -1) {
        fullLoadedIds = [...fullLoadedIds, inspectionId];
      }
      const loadingIndex = fullLoadingIds.indexOf(inspectionId);
      if (loadingIndex !== -1) {
        fullLoadingIds = [
          ...fullLoadingIds.slice(0, loadingIndex),
          ...fullLoadingIds.slice(loadingIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }

  setPriorFinalizedActionItemsLoading(inspectionId: ID) {
    this.update(state => {
      return {
        priorFinalizedActionItemsLoading: {
          ...state.priorFinalizedActionItemsLoading,
          [inspectionId]: true
        },
        priorFinalizedActionItemsLoaded: {
          ...state.priorFinalizedActionItemsLoaded,
          [inspectionId]: false
        }
      };
    });
  }

  setPriorFinalizedActionItemsLoaded(inspectionId: ID) {
    this.update(state => {
      return {
        priorFinalizedActionItemsLoading: {
          ...state.priorFinalizedActionItemsLoading,
          [inspectionId]: false
        },
        priorFinalizedActionItemsLoaded: {
          ...state.priorFinalizedActionItemsLoaded,
          [inspectionId]: true
        }
      };
    });
  }

  setDischargePointsLoading(projectId: ID) {
    this.update(state => {
      return {
        dischargePointsLoading: {
          ...state.dischargePointsLoading,
          [projectId]: true
        },
        dischargePointsLoaded: {
          ...state.dischargePointsLoaded,
          [projectId]: false
        }
      };
    });
  }

  setDischargePointsLoaded(projectId: ID) {
    this.update(state => {
      return {
        dischargePointsLoading: {
          ...state.dischargePointsLoading,
          [projectId]: false
        },
        dischargePointsLoaded: {
          ...state.dischargePointsLoaded,
          [projectId]: true
        }
      };
    });
  }

  setERecipientsLoading(projectId: ID, inspectionType: string) {
    this.update(state => {
      return {
        eRecipientsLoading: {
          ...state.eRecipientsLoading,
          [`${projectId}-${inspectionType}`]: true
        },
        eRecipientsLoaded: {
          ...state.eRecipientsLoaded,
          [`${projectId}-${inspectionType}`]: false
        }
      };
    });
  }

  setERecipientsLoaded(projectId: ID, inspectionType: string) {
    this.update(state => {
      return {
        eRecipientsLoading: {
          ...state.eRecipientsLoading,
          [`${projectId}-${inspectionType}`]: false
        },
        eRecipientsLoaded: {
          ...state.eRecipientsLoaded,
          [`${projectId}-${inspectionType}`]: true
        }
      };
    });
  }

  setProjectMarkersLoading(projectId: ID) {
    this.update(state => {
      return {
        projectMarkersLoading: {
          ...state.projectMarkersLoading,
          [projectId]: true
        },
        projectMarkersLoaded: {
          ...state.projectMarkersLoaded,
          [projectId]: false
        }
      };
    });
  }

  setProjectMarkersLoaded(projectId: ID) {
    this.update(state => {
      return {
        projectMarkersLoading: {
          ...state.projectMarkersLoading,
          [projectId]: false
        },
        projectMarkersLoaded: {
          ...state.projectMarkersLoaded,
          [projectId]: true
        }
      };
    });
  }

  setSelectedTab(index: number) {
    this.update({
      ui: {
        selectedTab: index
      }
    });
  }
}
