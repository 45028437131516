import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ae-dr-horton",
  templateUrl: "./dream-finder-orlando.component.html",
  styleUrls: ["./dream-finder-orlando.component.scss"]
})
export class DreamFinderOrlandoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
