import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({ name: "sortBy" })
export class SortByPipe implements PipeTransform {
  transform(
    value: any[],
    column: string,
    order: "asc" | "desc" = "asc"
  ): any[] {
    if (!value) {
      return value;
    } // no array
    if (value.length <= 1) {
      return value;
    } // array with only one item
    if (!column || column === "") {
      if (order === "asc") {
        return value.sort();
      } else {
        return value.sort().reverse();
      }
    } // sort 1d array
    return _.orderBy(value, [column], [order]);
  }
}
