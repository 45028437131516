import {
  Component,
  ContentChild,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef
} from "@angular/core";
import { EmcMainContainerBodyDirective } from "@emc-modules/shared/directives/emc-main-container-body.directive";
import { EmcMainContainerSideNavDirective } from "@emc-modules/shared/directives/emc-main-container-side-nav.directive";
import { BreakpointObserver } from "@angular/cdk/layout";
import { WindowresizeService } from "@emc-modules/core/services/windowresize.service";
import { ScrollService } from "@emc-modules/core/services/scroll.service";
import { VisibilityState } from "@emc-utils/constants";

@Component({
  selector: "ae-main-container",
  templateUrl: "./emc-main-container.component.html",
  styleUrls: ["./emc-main-container.component.scss"]
})
export class EmcMainContainerComponent implements OnInit, OnDestroy {
  @Input() showToggleButton = true;
  isNavOver: boolean;
  isSideNavOpen: boolean;
  mode: "side" | "over" | "push" = "over";
  @ContentChild(EmcMainContainerBodyDirective, { read: TemplateRef })
  bodyContent;
  @ContentChild(EmcMainContainerSideNavDirective, { read: TemplateRef })
  sideNavContent;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private scrollService: ScrollService,
    private windowResize: WindowresizeService
  ) {}

  ngOnInit() {
    this.breakpointObserver
      .observe(["(min-width: 959.99px)"])
      .subscribe(res => {
        if (!!res.matches) {
          this.mode = "side";
          this.isSideNavOpen = true;
          this.isNavOver = false;
        } else {
          this.mode = "over";
          this.isSideNavOpen = false;
          this.isNavOver = true;
        }
      });
  }

  toggle() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }

  ngOnDestroy(): void {
    this.scrollService.setScrollVisibilityState(VisibilityState.Visible);
  }
}
