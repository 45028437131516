import { Component, OnInit, Renderer2 } from "@angular/core";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import { combineLatest } from "rxjs";
import {
  NavigationCancel,
  NavigationError,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent
} from "@angular/router";
import { Location } from "@angular/common";
import { delay } from "rxjs/operators";
import { DeviceDetectorService } from "ngx-device-detector";
import { TemplateService } from "@emc-modules/tools/modules/templates/services/template.service";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { PermissionType } from "./enums/permission-type.enum";
import { SwUpdate } from "@angular/service-worker";
import { SignatureRegisterComponent } from "@emc-modules/shared/components/signature-register/signature-register.component";
import { CommonUtils, directAccessUrlsWithoutLogin } from "@emc-utils/common-utils";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";

@Component({
  selector: "ae-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  url: string;
  isDataLoading = false;

  constructor(
    private authManager: AuthManager,
    private templateService: TemplateService,
    private deviceDetectorService: DeviceDetectorService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private swUpdate: SwUpdate,
    private router: Router,
    private location: Location
  ) {
    if (
      this.deviceDetectorService.os === "Windows" ||
      this.deviceDetectorService.os === "Linux"
    ) {
      const styleElement = document.createElement("style");
      styleElement.appendChild(
        document.createTextNode(
          "::-webkit-scrollbar {\n" +
            "  -webkit-appearance: none;\n" +
            "}\n" +
            "\n" +
            "::-webkit-scrollbar:vertical {\n" +
            "  width: 11px;\n" +
            "}\n" +
            "\n" +
            "::-webkit-scrollbar:horizontal {\n" +
            "  height: 11px;\n" +
            "}\n" +
            "\n" +
            "::-webkit-scrollbar-thumb {\n" +
            "  border-radius: 8px;\n" +
            "  border: 2px solid white;\n" +
            "  background-color: rgba(0, 0, 0, .5);\n" +
            "}\n" +
            "\n" +
            "::-webkit-scrollbar-track {\n" +
            "  background-color: #fff;\n" +
            "  border-radius: 8px;\n" +
            "}"
        )
      );
      document.getElementsByTagName("head")[0].appendChild(styleElement);
    }
  }

  ngOnInit(): void {
    const loaded$ = combineLatest(
      this.authManager.isLoaded(),
      this.authManager.isBootstrapped(),
      (user: boolean, bootstrapped: boolean) => ({ user, bootstrapped })
    );

    loaded$.subscribe((data: any) => {
      if (!data.user && !data.bootstrapped) {
        if (!!this.location.path()) {
          this.authManager.setReturnUrl(this.location.path());
        }
        this.router.navigate(["/"]);
      }
    });

    this.router.events
      .pipe(delay(200))
      .subscribe((routerEvent: RouterEvent) => {
        this.checkRouterEvent(routerEvent);
      });
    if (PermissionUtils.hasPermission(PermissionType.TOOL_TEMPLATE_VIEW)) {
      this.templateService.indexTemplates().subscribe();
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate
        .checkForUpdate()
        .then(r => console.log("check for update", r));
      this.swUpdate.available.subscribe(() => {
        this.swUpdate
          .activateUpdate()
          .then(() => console.log("activated update"));
      });
    }

    if (!_.includes(directAccessUrlsWithoutLogin, this.location.path())) {
      this.authManager.getLoggedInUser().subscribe(user => {
        if (user && !user.signature) {
          this.showSignatureRegisterDialog();
        }
      });
    }
  }

  showSignatureRegisterDialog() {
    setTimeout(
      () =>
        this.dialog.open(SignatureRegisterComponent, {
          disableClose: true,
          width: "90%",
          maxHeight: "92%",
          panelClass: CommonUtils.getSizeClass()
        }),
      1000 * 30
    );
  }

  private checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof RouteConfigLoadStart) {
      this.isDataLoading = true;
    } else if (
      routerEvent instanceof RouteConfigLoadEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.isDataLoading = false;
    }
  }
}
