import { Injectable } from "@angular/core";
import { Order, QueryConfig, QueryEntity } from "@datorama/akita";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { CommonUtils } from "@emc-utils/common-utils";
import { RainLogState, RainLogStore } from "./rain-log.store";

@Injectable({ providedIn: "root" })
@QueryConfig({
  sortBy: "title",
  sortByOrder: Order.ASC
})
export class RainLogQuery extends QueryEntity<RainLogState> {
  $selectedProjectIds = this.select("selectedProjectIds");
  $selectedClientIds = this.select("selectedClientIds");

  $searchQuery = this.select("searchQuery");

  $entities = this.select("entities");
  $ids = this.select("ids");
  startDate$ = this.select("startDate");
  endDate$ = this.select("endDate");

  // All RainLog
  $isAllRainLogsLoading = this.select("isAllRainLogsLoading");
  $isAllRainLogsLoaded = this.select("isAllRainLogsLoaded");
  $allRainLogsOffset = this.select("allRainLogsOffset");

  constructor(protected store: RainLogStore) {
    super(store);
  }

  $selectIsFullLoading = (id: number) =>
    this.select(state => state.fullLoadingIds.indexOf(id) !== -1);

  $selectIsFullLoaded = (id: number) =>
    this.select(state => state.fullLoadedIds.indexOf(id) !== -1);

  getAllRainLog() {
    return combineLatest(this.$ids, this.$entities).pipe(
      map(([ids, entities]) => {
        const rainLogs = ids
          .filter(id => !!entities[id])
          .map(id => entities[id]);
        return CommonUtils.sortByKey(rainLogs, "createdAt", "desc");
      })
    );
  }
}
