import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ae-dr-horton",
  templateUrl: "./dream-finders-tampa.component.html",
  styleUrls: ["./dream-finders-tampa.component.scss"]
})
export class DreamFindersTampaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
