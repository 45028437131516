import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { PermissionType } from "../../../enums/permission-type.enum";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GenericConfirmationComponent } from "@emc-modules/shared/components/generic-confirmation/generic-confirmation.component";
import { filter } from "rxjs/operators";

@Injectable()
export class PermissionGuard implements CanActivate {
  isDialogShown: boolean;

  constructor(
    private dialog: MatDialog,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const permissions = route.data.permissions as PermissionType[];
    const hasPermission = PermissionUtils.hasAnyPermission(permissions);

    if (!hasPermission && !this.isDialogShown) {
      this.showDialog();
      this.isDialogShown = true;
    }

    return hasPermission;
  }

  showDialog() {
    const dialog = this.dialog.open(GenericConfirmationComponent, {
      height: "200px"
    });
    const instance = dialog.componentInstance;
    instance.title = "Unauthorized!";
    instance.description =
      "You do not have sufficient permissions to access this resource!";
    instance.showNoButton = false;
    instance.yesButtonTitle = "Go to Home Page";
    dialog
      .afterClosed()
      .pipe(filter(d => !!d?.yesClicked))
      .subscribe(() => this.router.navigate(["/dashboard"]));
  }
}
