import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Unsubscribe } from "../../../unsubscribe";
import { takeUntil } from "rxjs/operators";
import { Subscription } from "rxjs";
import { InspectionsService } from "@emc-modules/inspections/services/inspections.service";
import {
  ERecipients,
  ProjectNameOnly
} from "@emc-models/entities/project.model";

@Component({
  selector: "ae-e-recepients-dialog-box",
  templateUrl: "./e-recipients-dialog-box.component.html",
  styleUrls: ["./e-recipients-dialog-box.component.scss"]
})
export class ERecipientsDialogBoxComponent
  extends Unsubscribe
  implements OnInit
{
  projectId: number;
  inspectionType: string;
  projectName: ProjectNameOnly[] = [];
  title = "E-Recipients";
  eRecipientsLoading$: Subscription;
  eRecipients$: Subscription;
  eRecipientsLoading: boolean;
  eRecipients: ERecipients;

  constructor(
    private dialogRef: MatDialogRef<ERecipientsDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      projectId: number;
      inspectionType: string;
      projectName: ProjectNameOnly[];
    },
    private inspectionsService: InspectionsService
  ) {
    super();
    if (data) {
      this.projectId = data.projectId;
      this.inspectionType = data.inspectionType;
      this.projectName = data.projectName;
    }
  }

  ngOnInit() {
    if (this.projectId) {
      this.setUpERecipients(this.projectId, this.inspectionType);
    }
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  private setUpERecipients(projectId: number, inspectionType: string) {
    if (this.eRecipientsLoading$ && !this.eRecipientsLoading$.closed) {
      this.eRecipientsLoading$.unsubscribe();
    }

    if (this.eRecipientsLoading$ && !this.eRecipientsLoading$.closed) {
      this.eRecipientsLoading$.unsubscribe();
    }

    this.eRecipientsLoading$ = this.inspectionsService
      .getERecipientsLoading(projectId, inspectionType)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(l => (this.eRecipientsLoading = l));

    this.eRecipients$ = this.inspectionsService
      .getERecipientsForProject(projectId, inspectionType)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(recepients => {
        this.eRecipients = recepients;
      });
  }
}
