<i
  (click)="p.toggle()"
  aeTouchClick
  [ngStyle]="{
    color: pin.type,
    border: pin.isSelected ? '1px dotted' : 'none'
  }"
  [satPopoverAnchor]="p"
  class="material-icons"
  style="font-size: 65px; cursor: pointer"
>
  room
</i>

<sat-popover #p horizontalAlign="center" verticalAlign="above">
  <div class="popover" (mouseleave)="p.close()">
    <div class="header">
      <h5 class="title">{{ pin.title }}</h5>
      <mat-icon
        *ngIf="!viewOnly"
        (click)="onPinClicked()"
        matTooltip="Edit Location"
        >edit</mat-icon
      >
    </div>
    <p class="comment">{{ pin.comment }}</p>
  </div>
</sat-popover>
