<mat-form-field class="field" floatLabel="never">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-error>This field is required</mat-error>
  <input
    [formControl]="control"
    [matAutocomplete]="auto"
    matInput
    type="text"
  />
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
    {{ option.title }}
  </mat-option>
</mat-autocomplete>
