import {
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Optional,
  Renderer2,
  Self
} from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "input"
})
export class InputDirective implements OnInit {
  constructor(
    @Self() @Optional() private ngControl: NgControl,
    private _elRef: ElementRef,
    private _renderer: Renderer2
  ) {}

  ngOnInit() {
    this._renderer.setAttribute(
      this._elRef.nativeElement,
      "autocomplete",
      "off"
    );
  }

  @HostListener("keyup", ["$event"]) onKeyDowns(event: KeyboardEvent) {
    try {
      if (this.ngControl?.value?.trim() === "") {
        this.ngControl.reset(null);
      }
    } catch (e) {
      console.log(e);
    }
  }
}
