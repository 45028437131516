import { Injectable } from "@angular/core";
import { QueryConfig, QueryEntity } from "@datorama/akita";
import {
  AdminReportsState,
  AdminReportsStore
} from "@emc-state/request-reports/admin-reports/admin-reports.store";

@Injectable({
  providedIn: "root"
})
@QueryConfig({})
export class AdminReportsQuery extends QueryEntity<AdminReportsState> {
  selectOffset$ = this.select("offset");
  selectLimit$ = this.select("limit");
  selectAdminReportLoading$ = this.select("loading");
  selectAdminReportLoaded$ = this.select("loaded");
  selectStartDate$ = this.select("startDate");
  selectEndDate$ = this.select("endDate");
  selectQuery$ = this.select("query");
  selectedClientIds$ = this.select("clientIds");
  selectedProjectIds$ = this.select("projectIds");
  selectedUserIds$ = this.select("userIds");
  selectedInspectionType$ = this.select("inspectionType");

  constructor(protected store: AdminReportsStore) {
    super(store);
  }

  $selectIsFullLoading = (id: number) =>
    this.select(state => state.fullLoadingIds.indexOf(id) !== -1);

  $selectIsFullLoaded = (id: number) =>
    this.select(state => state.fullLoadedIds.indexOf(id) !== -1);
}
