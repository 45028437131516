<div class="container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>{{ title }}</span>
      <span class="spacer"></span>
      <mat-icon (click)="close()">clear</mat-icon>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="content">
    <ng-container *ngIf="!priorActionItemsLoading">
      <button
        (click)="downloadPdf()"
        *ngIf="inspectionId"
        class="tw-items-end tw-place-self-end !tw-mb-4 !tw-mx-3 tw-w-fit primary"
        mat-flat-button
      >
        Download Pdf
      </button>

      <ae-view-responsive-action-item-card
        (selectedChanged)="selectedItemChanged($event, item.id)"
        [selected]="getIsSelected(item.id)"
        [showCheckBox]="
          (hasAddEditPermission || hasSignOffPermission) && !item.completed_on
        "
        *ngFor="let item of items"
        [showButtons]="false"
        [showNoteButton]="hasAddEditPermission || hasSignOffPermission"
        [item]="item"
        [inspectionType]="inspectionType"
        [inspectionId]="item.inspection_id"
      >
      </ae-view-responsive-action-item-card>
      <ng-container *ngIf="hasAddEditPermission || hasSignOffPermission">
        <p class="message">
          Please Select/Enter Sign Off date and Sign Off by entries for checked
          Responsive action items
        </p>
        <div class="elements">
          <p class="label">Completed By</p>
          <ae-search-field
            [control]="controls.completed_by"
            [options]="userOptions"
            placeholder="Completed By"
          ></ae-search-field>
        </div>
        <div class="elements">
          <p class="label">Completed On</p>
          <mat-form-field floatLabel="never">
            <input
              [formControl]="controls.completed_on"
              [matDatepicker]="startpicker"
              matInput
              placeholder="Completed On"
              type="text"
            />
            <mat-datepicker-toggle
              [for]="startpicker"
              matSuffix
            ></mat-datepicker-toggle>
            <mat-datepicker #startpicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="buttons">
          <button (click)="close()" type="button" color="warn" mat-flat-button>
            Cancel
          </button>
          <div class="spacer"></div>
          <button
            *ngIf="!updating"
            [disabled]="!selectedItems.length"
            (click)="update()"
            color="primary"
            mat-flat-button
            type="button"
          >
            Update
          </button>
          <mat-spinner
            *ngIf="updating"
            diameter="20"
            strokeWidth="2"
          ></mat-spinner>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!priorActionItemsLoading">
      <p class="empty-list" *ngIf="!this.items.length">
        No Responsive Action Items Found
      </p>
    </ng-container>
    <ng-container>
      <div *ngIf="priorActionItemsLoading" class="center-spinner">
        <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>
