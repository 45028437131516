import { Injectable } from "@angular/core";
import { EntityStore, ID, StoreConfig } from "@datorama/akita";
import {
  ClientCompact,
  ClientFull,
  ClientNameOnly
} from "@emc-models/entities/client.model";
import {
  ProjectCompact,
  ProjectFull,
  ProjectNameOnly
} from "@emc-models/entities/project.model";
import { State } from "@emc-models/entities/state.model";
import {
  UserCompact,
  UserFull,
  UserNameOnly
} from "@emc-models/entities/user.model";
import { Role } from "@emc-models/entities/role.model";
import { MarketCompact } from "@emc-models/entities/market.model";
import { UserStatus } from "../../enums/user-status.enum";
import { ClientStatus, ProjectStatus } from "../../enums/status.enum";
import { SiteMap } from "@emc-models/entities/site-map.model";
import { MailingListCompact } from "@emc-models/entities/mailing-list.model";

export interface OptionsState {
  states: State[];
  statesLoading: boolean;
  statesLoaded: boolean;

  roles: Role[];
  rolesLoading: boolean;
  rolesLoaded: boolean;

  users: UserNameOnly[];
  usersLoading: boolean;
  usersLoaded: boolean;

  clients: ClientNameOnly[];
  clientsLoading: boolean;
  clientsLoaded: boolean;

  projects: ProjectNameOnly[];
  projectsLoading: boolean;
  projectsLoaded: boolean;

  markets: MarketCompact[];
  marketsLoading: boolean;
  marketsLoaded: boolean;

  siteMaps: SiteMap[];
  siteMapsLoading: boolean;
  siteMapsLoaded: boolean;

  mailingLists: MailingListCompact[];
  mailingListLoading: boolean;
  mailingListLoaded: boolean;
}

const initialState: OptionsState = {
  states: [],
  statesLoading: false,
  statesLoaded: false,

  roles: [],
  rolesLoading: false,
  rolesLoaded: false,

  users: [],
  usersLoading: false,
  usersLoaded: false,

  clients: [],
  clientsLoading: false,
  clientsLoaded: false,

  projects: [],
  projectsLoading: false,
  projectsLoaded: false,

  markets: [],
  marketsLoading: false,
  marketsLoaded: false,

  siteMaps: [],
  siteMapsLoading: false,
  siteMapsLoaded: false,

  mailingLists: [],
  mailingListLoading: false,
  mailingListLoaded: false
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "options" })
export class OptionsStore extends EntityStore<OptionsState> {
  constructor() {
    super(initialState);
  }

  updateUser(user: UserCompact, newUser: UserCompact) {
    this.update(state => {
      let users = state.users || [];
      const index = users.findIndex(u => u.id === user.id);

      if (newUser.status === UserStatus.ACTIVE) {
        if (index !== -1) {
          users = [
            ...users.slice(0, index),
            newUser,
            ...users.slice(index + 1)
          ];
        } else {
          users = [...users, newUser];
        }
      } else if (index !== -1) {
        users = [...users.slice(0, index), ...users.slice(index + 1)];
      }

      return {
        users
      };
    });
  }

  createUser(data: UserFull) {
    if (data.status === UserStatus.ACTIVE) {
      this.update(state => {
        return {
          users: [...state.users, data]
        };
      });
    }
  }

  deleteUser(id: ID) {
    this.update(state => {
      let users = state.users || [];
      const index = users.findIndex(u => u.id === id);

      if (index !== -1) {
        users = [...users.slice(0, index), ...users.slice(index + 1)];
      }

      return {
        users
      };
    });
  }

  updateProject(projectId: number, newPorject: ProjectCompact) {
    this.update(state => {
      let projects = state.projects || [];
      const index = projects.findIndex(p => p.id === projectId);

      if (newPorject.status === ProjectStatus.ACTIVE) {
        if (index !== -1) {
          projects = [
            ...projects.slice(0, index),
            newPorject,
            ...projects.slice(index + 1)
          ];
        } else {
          projects = [...projects, newPorject];
        }
      } else if (index !== -1) {
        projects = [...projects.slice(0, index), ...projects.slice(index + 1)];
      }
      return {
        projects
      };
    });
  }

  createProject(data: ProjectFull) {
    if (data.status === ProjectStatus.ACTIVE) {
      this.update(state => {
        return {
          projects: [...state.projects, data]
        };
      });
    }
  }

  deleteProject(id: ID) {
    this.update(state => {
      let projects = state.projects || [];
      const index = projects.findIndex(p => p.id === id);

      if (index !== -1) {
        projects = [...projects.slice(0, index), ...projects.slice(index + 1)];
      }

      return {
        projects
      };
    });
  }

  updateClient(client: ClientCompact, newClient: ClientCompact) {
    this.update(state => {
      let clients = state.clients || [];
      const index = clients.findIndex(c => c.id === client.id);

      if (newClient.status === ClientStatus.ACTIVE) {
        if (index !== -1) {
          clients = [
            ...clients.slice(0, index),
            newClient,
            ...clients.slice(index + 1)
          ];
        } else {
          clients = [...clients, newClient];
        }
      } else if (index !== -1) {
        clients = [...clients.slice(0, index), ...clients.slice(index + 1)];
      }
      return {
        clients
      };
    });
  }

  createClient(data: ClientFull) {
    if (data.status === ClientStatus.ACTIVE) {
      this.update(state => {
        return {
          clients: [...state.clients, data]
        };
      });
    }
  }

  deleteClient(id: ID) {
    this.update(state => {
      let clients = state.clients || [];
      const index = clients.findIndex(c => c.id === id);

      if (index !== -1) {
        clients = [...clients.slice(0, index), ...clients.slice(index + 1)];
      }

      return {
        clients
      };
    });
  }

  updateMarkets(marketId: ID, newMarket: MarketCompact) {
    this.update(state => {
      let markets = state.markets || [];
      const index = markets.findIndex(m => m.id === marketId);

      if (newMarket.is_active) {
        if (index !== -1) {
          markets = [
            ...markets.slice(0, index),
            newMarket,
            ...markets.slice(index + 1)
          ];
        } else {
          markets = [...markets, newMarket];
        }
      } else if (index !== -1) {
        markets = [...markets.slice(0, index), ...markets.slice(index + 1)];
      }
      return {
        markets
      };
    });
  }

  createMarket(data: MarketCompact) {
    if (data.is_active) {
      this.update(state => {
        return {
          markets: [...state.markets, data]
        };
      });
    }
  }

  deleteMarket(id: ID) {
    this.update(state => {
      let markets = state.markets || [];
      const index = markets.findIndex(m => m.id === id);
      if (index !== -1) {
        markets = [...markets.slice(0, index), ...markets.slice(index + 1)];
      }

      return {
        markets
      };
    });
  }

  updateMailingList(mailingListId: ID, newMailingLists: MailingListCompact) {
    this.update(state => {
      let mailingLists = state.mailingLists || [];
      const index = mailingLists.findIndex(m => m.id === mailingListId);
      if (index !== -1) {
        mailingLists = [
          ...mailingLists.slice(0, index),
          newMailingLists,
          ...mailingLists.slice(index + 1)
        ];
      } else {
        mailingLists = [...mailingLists, newMailingLists];
      }

      return {
        mailingLists
      };
    });
  }

  createMailingList(data: MailingListCompact) {
    this.update(state => {
      return {
        mailingLists: [...state.mailingLists, data]
      };
    });
  }

  deleteMailingList(id: ID) {
    this.update(state => {
      let mailingLists = state.mailingLists || [];
      const index = mailingLists.findIndex(m => m.id === id);

      if (index !== -1) {
        mailingLists = [
          ...mailingLists.slice(0, index),
          ...mailingLists.slice(index + 1)
        ];
      }

      return {
        mailingLists
      };
    });
  }

  updateRoles(roleId: ID, newRole: Role) {
    this.update(state => {
      let roles = state.roles || [];
      const index = roles.findIndex(r => r.id === roleId);
      if (index !== -1) {
        roles = [...roles.slice(0, index), newRole, ...roles.slice(index + 1)];
      } else {
        roles = [...roles, newRole];
      }

      return {
        roles
      };
    });
  }

  createRole(data: Role) {
    this.update(state => {
      return {
        roles: [...state.roles, data]
      };
    });
  }

  deleteRole(id: ID) {
    this.update(state => {
      let roles = state.roles || [];
      const index = roles.findIndex(r => r.id === id);

      if (index !== -1) {
        roles = [...roles.slice(0, index), ...roles.slice(index + 1)];
      }

      return {
        roles
      };
    });
  }

  createSiteMap(map: SiteMap) {
    this.update(state => {
      return {
        siteMaps: [...state.siteMaps, map]
      };
    });
  }

  updateSiteMap(id: ID, map: SiteMap) {
    this.update(state => {
      let siteMaps = state.siteMaps || [];
      const index = siteMaps.findIndex(r => r.id === id);

      if (index !== -1) {
        siteMaps = [
          ...siteMaps.slice(0, index),
          map,
          ...siteMaps.slice(index + 1)
        ];
      }

      return {
        siteMaps
      };
    });
  }

  deleteSiteMap(id: ID) {
    this.update(state => {
      let siteMaps = state.siteMaps || [];
      const index = siteMaps.findIndex(r => r.id === id);

      if (index !== -1) {
        siteMaps = [...siteMaps.slice(0, index), ...siteMaps.slice(index + 1)];
      }
      return {
        siteMaps
      };
    });
  }

  updateState(stateId: ID, newState: State) {
    this.update(state => {
      let states = state.states || [];
      const index = states.findIndex(r => r.id === stateId);
      if (index !== -1) {
        states = [
          ...states.slice(0, index),
          newState,
          ...states.slice(index + 1)
        ];
      } else {
        states = [...states, newState];
      }

      return {
        states
      };
    });
  }

  createState(data: State) {
    this.update(state => {
      return {
        states: [...state.states, data]
      };
    });
  }

  deleteState(id: ID) {
    this.update(state => {
      let states = state.states || [];
      const index = states.findIndex(r => r.id === id);

      if (index !== -1) {
        states = [...states.slice(0, index), ...states.slice(index + 1)];
      }

      return {
        states
      };
    });
  }
}
