<div [formGroup]="group" class="elements">
  <p aeMarkAsterisk class="label">Responsive Action</p>
  <div class="info">
    <mat-form-field floatLabel="never">
      <input formControlName="title" matInput placeholder="Title" type="text" />
    </mat-form-field>
    <div class="actions">
      <mat-checkbox color="primary" formControlName="is_active"
        >Active</mat-checkbox
      >
      <button (click)="onDelete()" mat-icon-button>
        <mat-icon color="warn" matTooltip="Delete">delete</mat-icon>
      </button>
    </div>
  </div>
</div>
