import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CommonUtils } from "@emc-utils/common-utils";

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}
  open(component, config?: MatDialogConfig) {
    return this.dialog.open(component, {
      panelClass: CommonUtils.getSizeClass(),
      ...config
    });
  }
}
