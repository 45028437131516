import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "ae-search-box",
  templateUrl: "./emc-search-box.component.html",
  styleUrls: ["./emc-search-box.component.scss"]
})
export class EmcSearchBoxComponent implements OnInit {
  @Output() textChanged = new EventEmitter();
  @Input() control: FormControl = new FormControl();
  @Input() placeholder = "Search";

  ngOnInit() {
    this.control.valueChanges.subscribe((value: string) => {
      this.textChanged.emit(value);
    });
  }

  clearSearchField() {
    this.control.reset();
  }
}
