import { Component, OnInit } from "@angular/core";
import { AppService } from "@emc-modules/core/services/app.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { PermissionType } from "../../../../enums/permission-type.enum";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { VisibilityState } from "@emc-utils/constants";
import { ScrollService } from "@emc-modules/core/services/scroll.service";
import { takeUntil } from "rxjs/operators";
import { Unsubscribe } from "../../../../unsubscribe";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import { UserFull } from "@emc-models/entities/user.model";
import { environment } from "@emc-environment/environment";

@Component({
  selector: "ae-side-nav-container",
  templateUrl: "./side-nav-container.component.html",
  styleUrls: ["./side-nav-container.component.scss"],
  animations: [
    trigger("toggle", [
      transition(":enter", [animate(300)]),
      transition(":leave", [animate(300)]),
      transition("* => *", animate("200ms ease-in"))
    ]),
    trigger("sideNavToggle", [
      state(VisibilityState.Hidden, style({ marginTop: 0, height: "100vh" })),
      transition("* => *", animate("200ms ease-in"))
    ])
  ]
})
export class SideNavContainerComponent extends Unsubscribe implements OnInit {
  isNavOver: boolean;
  isSideNavOpen: boolean;
  mode: "side" | "over" | "push" = "over";
  PermissionUtils = PermissionUtils;
  PermissionType = PermissionType;
  state: VisibilityState = VisibilityState.Visible;
  VisibilityState = VisibilityState;
  showProfile = true;
  user: UserFull;
  appVersion = environment.version;
  hasToolsPermission = PermissionUtils.hasAnyPermission([
    PermissionType.TOOL_LEAD_VIEW,
    PermissionType.TOOL_LEAD_CREATE,
    PermissionType.TOOL_TROUBLE_TICKET_VIEW,
    PermissionType.TOOL_TROUBLE_TICKET_CREATE,
    PermissionType.TOOL_FILE_UPLOAD_VIEW,
    PermissionType.TOOL_FILE_UPLOAD_UPLOAD,
    PermissionType.TOOL_EMAIL_DISTRIBUTION_CREATE,
    PermissionType.TOOL_EMAIL_DISTRIBUTION_VIEW,
    PermissionType.TOOL_TEMPLATE_VIEW,
    PermissionType.TOOL_SAFETY_INSPECTION_TEMPLATE_VIEW,
    PermissionType.TOOL_TEMPLATE_CREATE,
    PermissionType.TOOL_MAILING_LIST_VIEW,
    PermissionType.TOOL_MAILING_LIST_CREATE,
    PermissionType.TOOL_ROLE_AND_PERMISSION_VIEW,
    PermissionType.TOOL_ROLE_AND_PERMISSION_CREATE,

    PermissionType.TOOL_SITE_MAP_VIEW,
    PermissionType.TOOL_SITE_MAP_CREATE,
    PermissionType.TOOL_QUESTION_CATEGORY_VIEW,
    PermissionType.TOOL_QUESTION_CATEGORY_CREATE,
    PermissionType.TOOL_SAFETY_OBSERVATION_CATEGORY_VIEW,
    PermissionType.TOOL_SAFETY_OBSERVATION_CATEGORY_CREATE,
    PermissionType.TOOL_SAFETY_OBSERVATION_ISSUE_VIEW,
    PermissionType.TOOL_SAFETY_OBSERVATION_ISSUE_CREATE,
    PermissionType.TOOL_QA_OBSERVATION_CATEGORY_VIEW,
    PermissionType.TOOL_QA_OBSERVATION_CATEGORY_CREATE,
    PermissionType.TOOL_QA_OBSERVATION_ISSUE_VIEW,
    PermissionType.TOOL_QA_OBSERVATION_ISSUE_CREATE,
    PermissionType.TOOL_OPTION_CREATE,
    PermissionType.TOOL_OPTION_EDIT
  ]);

  hasRequestReportPermission = PermissionUtils.hasAnyPermission([
    PermissionType.TOOL_COMPLIANCE_REQUEST_VIEW,
    PermissionType.TOOL_COMPLIANCE_REQUEST_CREATE,
    PermissionType.TOOL_PROJECT_ACTIVITY_REPORT_VIEW,
    PermissionType.TOOL_PROJECT_REQUEST_VIEW,
    PermissionType.TOOL_PROJECT_REQUEST_CREATE,
    PermissionType.TOOL_PROJECT_STOP_REQUEST_VIEW,
    PermissionType.TOOL_PROJECT_STOP_REQUEST_CREATE
  ]);

  constructor(
    private appService: AppService,
    private authManager: AuthManager,
    private breakpointObserver: BreakpointObserver,
    private scrollService: ScrollService
  ) {
    super();
  }

  // @HostBinding("@toggle")
  get toggle(): VisibilityState {
    return this.mode === "over" ? this.state : VisibilityState.Visible;
  }

  ngOnInit() {
    this.breakpointObserver
      .observe(["(min-width: 959.99px)"])
      .subscribe(res => {
        if (!!res.matches) {
          this.mode = "side";
          this.isSideNavOpen = true;
          this.isNavOver = false;
        } else {
          this.mode = "over";
          this.isSideNavOpen = false;
          this.isNavOver = true;
        }
      });

    this.scrollService
      .getScrollVisibilityState()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(visibilityState => (this.state = visibilityState));

    this.scrollService
      .getProfileVisibility()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(visible => (this.showProfile = visible));

    this.authManager
      .getLoggedInUser()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(u => {
        this.user = u;
      });
  }

  logout() {
    this.appService.logout();
  }

  toggleNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }
}
