import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { VisibilityState } from "@emc-utils/constants";

@Injectable({ providedIn: "root" })
export class ScrollService {
  private state$ = new BehaviorSubject<VisibilityState>(
    VisibilityState.Visible
  );
  private profileVisibility$ = new BehaviorSubject(true);

  constructor() {}

  setScrollVisibilityState(state: VisibilityState) {
    this.state$.next(state);
  }

  getScrollVisibilityState(): Observable<VisibilityState> {
    return this.state$.asObservable();
  }

  setProfileVisiblity(visible: boolean) {
    this.profileVisibility$.next(visible);
  }

  getProfileVisibility(): Observable<boolean> {
    return this.profileVisibility$.asObservable();
  }
}
