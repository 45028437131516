import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { InspectionGroup } from "../../../models/entities/inspection-group.model";
import { Inspection } from "../../../models/entities/inspection.model";
import { CommonUtils } from "../../../utils/common-utils";

export interface InspectionGroupsState extends EntityState<InspectionGroup> {
  isLoading: boolean;
  isLoaded: boolean;
  offset: number;
  limit?: number;
}
const initialState: InspectionGroupsState = {
  isLoading: false,
  isLoaded: false,
  offset: 0
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "inspection-groups" })
export class InspectionGroupsStore extends EntityStore<InspectionGroupsState> {
  constructor() {
    super(initialState);
  }
}
