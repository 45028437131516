import { Injectable } from "@angular/core";
import { EntityStore, ID, StoreConfig } from "@datorama/akita";
import { RainLog } from "../../models/entities/rain-log.model";

export interface RainLogState {
  ids: ID[];
  entities: { [id: string]: RainLog };
  fullLoadingIds: ID[];
  fullLoadedIds: ID[];
  allRainLogsOffset: number;
  isAllRainLogsLoading: boolean;
  isAllRainLogsLoaded: boolean;

  startDate?: string;
  endDate?: string;
  selectedRainLogIds: ID[];
  selectedProjectIds: ID[];
  selectedClientIds: ID[];
  searchQuery?: string;
}

const initialState = {
  ids: [],
  entities: {},
  fullLoadingIds: [],
  fullLoadedIds: [],

  allRainLogsOffset: 0,
  isAllRainLogsLoading: false,
  isAllRainLogsLoaded: false
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "rain-log" })
export class RainLogStore extends EntityStore<RainLogState> {
  constructor() {
    super(initialState);
  }

  setFullLoading(projectId: ID) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadingIds.indexOf(projectId) === -1) {
        fullLoadingIds = [...fullLoadingIds, projectId];
      }
      const loadedIndex = fullLoadedIds.indexOf(projectId);
      if (loadedIndex === -1) {
        fullLoadedIds = [
          ...fullLoadedIds.slice(0, loadedIndex),
          ...fullLoadedIds.slice(loadedIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }

  setFullLoaded(projectId: ID) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadedIds.indexOf(projectId) === -1) {
        fullLoadedIds = [...fullLoadedIds, projectId];
      }
      const loadingIndex = fullLoadingIds.indexOf(projectId);
      if (loadingIndex !== -1) {
        fullLoadingIds = [
          ...fullLoadingIds.slice(0, loadingIndex),
          ...fullLoadingIds.slice(loadingIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }
}
