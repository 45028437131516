<div class="container tw-bg-primary/5">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>{{ title }}</span>
      <span class="spacer"></span>
      <mat-icon (click)="close()">clear</mat-icon>
    </mat-toolbar-row>
  </mat-toolbar>
  <ng-container *ngIf="rainLogLoading || saving">
    <div class="center-spinner">
      <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
    </div>
  </ng-container>
  <div
    *ngIf="!rainLogLoading && !saving"
    class="content !tw-h-[calc(100%-24px)] !tw-overflow-y-auto tw-pl-3 tw-overflow-x-hidden"
  >
    <div class="elements tw-flex-grow-0">
      <p class="label" aeMarkAsterisk>Rain Source</p>
      <mat-form-field floatLabel="never">
        <input
          [formControl]="controls.rain_source"
          matInput
          placeholder="Rain Source"
          type="text"
        />
        <mat-error *ngIf="controls.rain_source.hasError('validation')"
          >Seems like this field is invalid
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-elements tw-flex-grow-0">
      <table class="form-element-table">
        <tr>
          <th>Rain Date</th>
          <th>Rain Quantity</th>
          <!-- <th>Initials</th> -->
        </tr>
        <tr *ngFor="let control of controls.rain_data.controls.slice(0, index)">
          <td>
            <mat-form-field class="date" floatLabel="never">
              <input
                (focus)="startpicker.open()"
                [formControl]="control.get('date')"
                [disabled]="control.get('disabled').value"
                [matDatepicker]="startpicker"
                [max]="maxDate"
                matInput
                placeholder="Choose Rain Date"
                type="text"
              />
              <mat-datepicker-toggle
                [for]="startpicker"
                matSuffix
              ></mat-datepicker-toggle>
              <mat-datepicker #startpicker></mat-datepicker>
              <mat-error *ngIf="control.get('date')?.hasError('validation')"
                >Seems like this field is invalid
              </mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field floatLabel="never">
              <input
                [formControl]="control.get('quantity')"
                type="number"
                matInput
                placeholder="Rain Quantity"
                type="text"
              />
              <mat-error *ngIf="control.get('quantity')?.hasError('validation')"
                >Seems like this field is invalid
              </mat-error>
            </mat-form-field>
          </td>
          <!-- <td>
            <mat-form-field floatLabel="never">
              <input
                [formControl]="control.get('duration')"
                type="number"
                matInput
                placeholder="Initials"
                type="text"
              />
              <mat-error *ngIf="control.get('duration')?.hasError('validation')"
                >Seems like this field is invalid
              </mat-error>
            </mat-form-field>
          </td> -->
        </tr>
        <button
          (click)="expand()"
          class="tw-w-fit !tw-ml-auto !tw-mr-3"
          [disabled]="
            this.controls.rain_data.controls.length === index ||
            this.controls.rain_data.controls.length < 8
          "
          color="primary"
          mat-flat-button
          type="button"
        >
          Expand
        </button>
      </table>
    </div>

    <div class="elements tw-flex-grow-0">
      <button
        (click)="submit()"
        class="tw-w-fit !tw-ml-auto"
        color="primary"
        mat-flat-button
        type="button"
        [disabled]="controls.rain_source.disabled"
      >
        Add Rain Log
      </button>
    </div>
    <ng-container>
      <div *ngIf="saving" class="center-spinner">
        <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>
