import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "ae-option-card",
  templateUrl: "./option-card.component.html",
  styleUrls: ["./option-card.component.scss"]
})
export class OptionCardComponent implements OnInit {
  @Input() group: FormGroup;
  @Output() delete = new EventEmitter();

  constructor() {}

  onDelete() {
    this.delete.emit();
  }

  ngOnInit() {}
}
