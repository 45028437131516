<div class="container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>{{ !note ? "Add" : "Edit" }} Note</span>
      <span class="spacer"></span>
      <mat-icon (click)="close()">clear</mat-icon>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="content">
    <mat-form-field floatLabel="never">
      <input
        [formControl]="controls.comment"
        matInput
        placeholder="Description"
        type="text"
      />
    </mat-form-field>

    <mat-form-field floatLabel="never">
      <input
        (focus)="startpicker.open()"
        [formControl]="controls.date"
        placeholder="Noted On"
        [matDatepicker]="startpicker"
        matInput
        type="text"
      />
      <mat-datepicker-toggle
        [for]="startpicker"
        matSuffix
      ></mat-datepicker-toggle>
      <mat-datepicker #startpicker></mat-datepicker>
    </mat-form-field>

    <span class="spacer"></span>
    <div class="buttons">
      <button (click)="save()" color="primary" mat-flat-button>Save</button>
    </div>
  </div>
</div>
