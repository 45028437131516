import { Injectable } from "@angular/core";
import { ApiService } from "@emc-modules/core/services/api.service";
import { Observable } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import { CommonUtils } from "@emc-utils/common-utils";
import { ID } from "@datorama/akita";
import { ExportFormat } from "@emc-utils/constants";
import * as _ from "lodash";
import * as moment from "moment";
import { RainLogStore } from "../../../state/rain-log/rain-log.store";
import { RainLogQuery } from "../../../state/rain-log/rain-log.query";
import { RainLog } from "../../../models/entities/rain-log.model";
import { ListResponse } from "../../../responses/list.response";
import { ProjectCompact } from "../../../models/entities/project.model";

@Injectable({ providedIn: "root" })
export class RainLogService {
  constructor(
    private store: RainLogStore,
    private query: RainLogQuery,
    private apiService: ApiService
  ) {}

  setFilters(data: {
    rain_log_ids: number[];
    start_date: string;
    end_date: string;
    search_query?: string;
    client_ids: number[];
    project_ids: number[];
  }) {
    this.store.reset();
    this.store.update({
      selectedRainLogIds: data.rain_log_ids,
      startDate: data.start_date,
      endDate: data.end_date,

      selectedClientIds: data.client_ids,
      selectedProjectIds: data.project_ids,

      searchQuery: data.search_query
    });
  }

  setSubFilters(data: { start_date: string; end_date: string }) {
    this.store.reset();
    this.store.update({
      startDate: data.start_date,
      endDate: data.end_date
    });
  }

  getAllRainLogForProject(projectId: number): Observable<{
    project: ProjectCompact;
    rainLogs: RainLog[];
    last_rain_log_date: string;
  }> {
    const _data = {} as any;
    this.query.startDate$.pipe(take(1)).subscribe(l => {
      if (l) {
        _data.start_date = l;
      }
    });

    this.query.endDate$.pipe(take(1)).subscribe(l => {
      if (l) {
        _data.end_date = l;
      }
    });

    this.store.update({
      isAllRainLogsLoading: true
    });

    return this.apiService
      .get<any>(`rain-log/project/${projectId}`, true, _data)
      .pipe(
        tap(
          this.store.update({
            isAllRainLogsLoading: false,
            isAllRainLogsLoaded: true
          })
        ),
        map(res => res)
      );
  }

  getRainLogForProject(projectId: number, data) {
    return this.apiService
      .get<any>(`rain-log/project/${projectId}`, true, data)
      .pipe(map(res => res));
  }

  getRainLogById(rainLogId: number): Observable<RainLog> {
    let _loading = false;
    let _loaded = false;

    this.query
      .$selectIsFullLoaded(rainLogId)
      .pipe(take(1))
      .subscribe(l => (_loaded = l));

    this.query
      .$selectIsFullLoading(rainLogId)
      .pipe(take(1))
      .subscribe(l => (_loading = l));

    if (!_loading && !_loaded) {
      this.store.setFullLoading(rainLogId);
      this.apiService.get<any>(`rain-log/${rainLogId}`, true).subscribe(res => {
        this.store.update(state => {
          let ids = [...state.ids, rainLogId];
          ids = _.uniq(ids);
          return {
            ids,
            entities: {
              ...state.entities,
              [rainLogId]: res.data
            }
          };
        });
        this.store.setFullLoaded(rainLogId);
      });
    }
    return this.query
      .selectEntity(rainLogId)
      .pipe(filter((rainLog: RainLog) => !!rainLog));
  }

  getAllRainLogs(offset = 0) {
    let _loading = false;
    let _loaded = false;
    let _offset = 0;

    this.query.$allRainLogsOffset.pipe(take(1)).subscribe(o => (_offset = o));

    this.query.$isAllRainLogsLoaded.pipe(take(1)).subscribe(l => (_loaded = l));

    this.query.$isAllRainLogsLoading
      .pipe(take(1))
      .subscribe(l => (_loading = l));

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({
        isAllRainLogsLoading: true
      });
      this.fetchRainLogs({
        offset
      })
        .pipe(
          tap((rainLogs: RainLog[]) => {
            if (rainLogs.length > 0) {
              const rainLogIds = rainLogs.map(rainLog => rainLog.id);
              const entities = CommonUtils.normalize(rainLogs);
              this.store.update(state => {
                state.fullLoadedIds.forEach(id => {
                  delete entities[id];
                });
                return {
                  isAllRainLogsLoading: false,
                  allRainLogsOffset: _offset + rainLogIds.length,
                  ids: _.uniq([...state.ids, ...rainLogIds]),
                  entities: {
                    ...state.entities,
                    ...entities
                  }
                };
              });
            } else {
              this.store.update({
                isAllRainLogsLoading: false,
                isAllRainLogsLoaded: true
              });
            }
          })
        )
        .subscribe();
    }
    return this.query.getAllRainLog();
  }

  exportRainLogs(format: ExportFormat, project_id: number): Observable<void> {
    let data: any = {
      format,
      project_id
    };
    this.query.startDate$.pipe(take(1)).subscribe(l => {
      if (l) {
        data.start_date = moment(l).format("YYYY-MM-DD");
      }
    });

    this.query.endDate$.pipe(take(1)).subscribe(l => {
      if (l) {
        data.end_date = moment(l).format("YYYY-MM-DD");
      }
    });

    return this.apiService.export("export/rain-log", data);
  }

  // Not used
  updateRainLog(rainLog: RainLog, data: RainLog): Observable<RainLog> {
    const rainLogId = +rainLog.id;
    return this.apiService.put<any>(`rain-log/${rainLogId}`, true, data).pipe(
      map(res => {
        this.store.upsert(rainLogId, res.data);
        return res.data;
      })
    );
  }

  bulkUpdateRainLog(data: any): Observable<RainLog> {
    return this.apiService.put<any>(`rain-log/bulk-update`, true, data).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  bulkCreateRainLog(data: any): Observable<RainLog> {
    return this.apiService.post<any>(`rain-log/bulk-create`, true, data).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  deleteRainLog(rainLogId: ID, forceDelete: boolean): Observable<void> {
    return this.apiService.delete<void>(`rain-log/${rainLogId}`, true).pipe(
      tap(() => {
        if (forceDelete) {
          this.store.remove(rainLogId);
        }
      })
    );
  }

  // Api Call
  private fetchRainLogs(data: { offset: number }): Observable<RainLog[]> {
    let _searchQuery: string;

    const _data = {
      offset: data.offset
    } as any;

    this.query.startDate$.pipe(take(1)).subscribe(l => {
      if (l) {
        _data.start_date = l;
      }
    });

    this.query.endDate$.pipe(take(1)).subscribe(l => {
      if (l) {
        _data.end_date = l;
      }
    });

    this.query.$selectedClientIds.pipe(take(1)).subscribe(l => {
      if (l && l.length) {
        _data.client_ids = l;
      }
    });

    this.query.$selectedProjectIds.pipe(take(1)).subscribe(u => {
      if (u && u.length) {
        _data.project_ids = u;
      }
    });

    this.query.$searchQuery.pipe(take(1)).subscribe(s => (_searchQuery = s));

    if (!!_searchQuery) {
      _data.query = _searchQuery;
    }

    return this.apiService
      .get<ListResponse<RainLog>>("rain-log", true, _data)
      .pipe(map(res => res.data));
  }
}
