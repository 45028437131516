import { Permission } from "@emc-models/entities/permission.model";
import { PermissionType } from "../enums/permission-type.enum";

export class PermissionUtils {
  private static assignedPermissions: Permission[] = [];

  static setAssignedPermissions(permissions: Permission[]) {
    this.assignedPermissions = permissions;
  }

  static hasPermission(permission: PermissionType): boolean {
    return (
      this.assignedPermissions.findIndex(p => p.identifier === permission) !==
      -1
    );
  }

  static hasAnyPermission(permissions: PermissionType[]): boolean {
    return (
      this.assignedPermissions.filter(
        p => permissions.indexOf(p.identifier) !== -1
      ).length > 0
    );
  }
}
