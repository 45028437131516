<div [id]="item?.reference_log?.substr(0, 5)" class="emc-box">
  <div class="buttons">
    <h4 style="font-size: 14px; font-weight: bold">
      {{ item?.reference_log }}
    </h4>
    <span class="space-filler"></span>
    <ng-container *ngIf="!editing">
      <ng-container *ngIf="!deleting && showButtons">
        <ng-container>
          <mat-icon
            (click)="deleteCard()"
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.INSPECTION_DELETE_RESPONSIVE_ACTION
              )
            "
            color="warn"
            matTooltip="Delete"
            >delete
          </mat-icon>
        </ng-container>
        <ng-container *ngIf="hasAddEditPermission">
          <mat-icon (click)="editItemCard()" color="primary" matTooltip="Edit"
            >edit</mat-icon
          >
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="deleting">
      <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="editing">
      <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
    </ng-container>
  </div>

  <div class="elements">
    <p class="label">Responsive Action</p>
    <p class="info">{{ item.title }}</p>
  </div>

  <div class="elements">
    <p class="label">
      {{ inspectionType === QuestionType.PULTE ? "Assigned To" : "Location" }}
    </p>
    <p class="info">{{ item.comment || "-" }}</p>
    <span class="space-filler"></span>
    <button
      (click)="openMap()"
      [disabled]="!item.site_map_id || !item.coordinates"
      class="location-button"
      color="warn"
      mat-button
    >
      View on Map
    </button>
  </div>

  <div class="elements">
    <p class="label">Date Noted</p>
    <p class="info">
      {{ (item.created_at | date: "MM-dd-yyyy, h:mm:ss a") || "-" }}
    </p>
  </div>

  <div class="elements">
    <p class="label">Completed On</p>
    <p class="info">{{ (item.completed_on | date: "MM-dd-yyyy") || "-" }}</p>
  </div>

  <div class="elements">
    <p class="label">Completed By</p>
    <p class="info">
      {{
        item.completedBy
          ? item.completedBy.first_name + " " + item.completedBy.last_name
          : "-"
      }}
    </p>
  </div>

  <div *ngIf="config?.show_assigned_to" class="elements">
    <p class="label">Assigned To</p>
    <p class="info">
      {{
        !!item.assignedTo
          ? item.assignedTo.first_name + " " + item.assignedTo.last_name
          : "-"
      }}
    </p>
  </div>

  <div class="buttons">
    <span class="space-filler"></span>
    <mat-checkbox
      (change)="onChange($event)"
      *ngIf="showCheckBox"
      [(ngModel)]="selected"
      color="primary"
    ></mat-checkbox>
    <button
      (click)="addNote()"
      *ngIf="showNoteButton"
      class="secondary"
      mat-flat-button
    >
      Add Note
    </button>
  </div>

  <div *ngIf="notes.length" class="notes-container">
    <h2 class="color">Notes</h2>
    <ng-container *ngFor="let comment of notes; let i = index">
      <div class="add-card">
        <div *ngIf="!saving && showNoteButton" class="buttons">
          <span class="space-filler"></span>
          <mat-icon (click)="deleteNote(i)" color="warn" matTooltip="Delete"
            >delete</mat-icon
          >
          <mat-icon (click)="editNote(i)" color="primary" matTooltip="Edit"
            >edit
          </mat-icon>
        </div>

        <div class="row">
          <h3>Date</h3>
          <p>{{ comment.date | date }}</p>
        </div>
        <div class="row">
          <h3>Comment</h3>
          <p>{{ comment.comment }}</p>
        </div>
        <div class="row">
          <h3>User</h3>
          <p>{{ comment.user_name }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
