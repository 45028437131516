import { Injectable } from "@angular/core";
import { ID } from "@datorama/akita";
import { ApiService } from "@emc-modules/core/services/api.service";
import { Observable } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { ListResponse } from "../../../responses/list.response";
import { ShowResponse } from "../../../responses/show.response";
import { Inspection } from "@emc-models/entities/inspection.model";
import { ResponsiveActionItem } from "@emc-models/entities/responsive-action-item.model";
import { DischargePoint } from "@emc-models/entities/discharge-point.model";
import { ERecipients } from "@emc-models/entities/project.model";
import { saveAs } from "file-saver/dist/FileSaver";
import * as moment from "moment";
import { InspectionGroup } from "../../../models/entities/inspection-group.model";

@Injectable({ providedIn: "root" })
export class InspectionsApiService {
  constructor(private apiService: ApiService) {}

  list(data: any): Observable<Inspection[]> {
    return this.apiService
      .get<ListResponse<Inspection>>("inspections", true, data)
      .pipe(map(res => res.data));
  }

  addSiteInspection(data: any): Observable<Inspection> {
    return this.apiService
      .post<
        ShowResponse<Inspection>
      >("inspections/site-inspections", true, data)
      .pipe(map(res => res.data));
  }

  addSafetyInspection(data: any): Observable<Inspection> {
    return this.apiService
      .post<
        ShowResponse<Inspection>
      >("inspections/safety-inspections", true, data)
      .pipe(map(res => res.data));
  }

  addPreAcquisitionInspection(data: any): Observable<Inspection> {
    return this.apiService
      .post<
        ShowResponse<Inspection>
      >("inspections/pre-acquisition-inspections", true, data)
      .pipe(map(res => res.data));
  }

  addQaInspection(data: any): Observable<Inspection> {
    return this.apiService
      .post<ShowResponse<Inspection>>("inspections/qa-inspections", true, data)
      .pipe(map(res => res.data));
  }

  addEnergyInspection(data: any): Observable<Inspection> {
    return this.apiService
      .post<
        ShowResponse<Inspection>
      >("inspections/energy-inspections", true, data)
      .pipe(map(res => res.data));
  }

  showInspection(inspectionId: ID): Observable<Inspection> {
    return this.apiService
      .get<ShowResponse<Inspection>>(`inspections/${inspectionId}`, true)
      .pipe(map(res => res.data));
  }

  fetchInspectionGroups(data: any): Observable<InspectionGroup[]> {
    return this.apiService
      .get<ListResponse<InspectionGroup>>("inspection-groups", true, data)
      .pipe(map(res => res.data));
  }

  deleteInspection(inspectionId: ID): Observable<void> {
    return this.apiService.delete(`inspections/${inspectionId}`, true);
  }

  updateSiteInspection(
    siteInspectionId: ID,
    data: any
  ): Observable<Inspection> {
    return this.apiService
      .put<
        ShowResponse<Inspection>
      >(`inspections/site-inspections/${siteInspectionId}`, true, data)
      .pipe(map(res => res.data));
  }

  updateEnergyInspection(
    energyInspectionId: ID,
    data: any
  ): Observable<Inspection> {
    return this.apiService
      .put<
        ShowResponse<Inspection>
      >(`inspections/energy-inspections/${energyInspectionId}`, true, data)
      .pipe(map(res => res.data));
  }

  updateSafetyInspection(
    safetyInspectionId: ID,
    data: any
  ): Observable<Inspection> {
    return this.apiService
      .put<
        ShowResponse<Inspection>
      >(`inspections/safety-inspections/${safetyInspectionId}`, true, data)
      .pipe(map(res => res.data));
  }

  updatePreAcquisitionInspection(
    preAcquisitionInspectionId: ID,
    data: any
  ): Observable<Inspection> {
    return this.apiService
      .put<
        ShowResponse<Inspection>
      >(`inspections/pre-acquisition-inspections/${preAcquisitionInspectionId}`, true, data)
      .pipe(map(res => res.data));
  }

  updateQaInspection(qaInspectionId: ID, data: any): Observable<Inspection> {
    return this.apiService
      .put<
        ShowResponse<Inspection>
      >(`inspections/qa-inspections/${qaInspectionId}`, true, data)
      .pipe(map(res => res.data));
  }

  followUpInspection(inspectionId: ID): Observable<Inspection> {
    return this.apiService
      .post<
        ShowResponse<Inspection>
      >(`inspections/qa-inspections/${inspectionId}/followup`, true, null)
      .pipe(map(res => res.data));
  }

  exportFinaliseInspections(data: any) {
    return this.apiService
      .post("inspections/export", true, data, null, true, "blob", true)
      .pipe(
        map((res: Response) => {
          return;
        })
      );
  }

  exportNormalInspections(data: any, type: string) {
    return this.apiService.export(`export/${type}`, data);
  }

  exportSafetyAssessmentReportExcel(data: any) {
    return this.apiService
      .post<any>(`safety-assessments/excel-export`, true, { ...data })
      .pipe(
        map(res => {
          saveAs(
            new Blob([JSON.stringify(res)], { type: "application/json" }),
            `Safety Assessment-${moment().format("MM-DD-YYYY")}.json`
          );
        })
      );
  }

  exportSafetyAssessmentReportPDF(data: any) {
    return this.apiService.export(`safety-assessments/pdf-export`, {
      ...data,
      format: "pdf"
    });
  }

  getPriorFinalizedItems(
    projectId: number,
    inspectionId?: number
  ): Observable<ResponsiveActionItem[]> {
    let data: any;

    if (inspectionId) {
      data = {
        inspectionId
      };
    }

    return this.apiService
      .get<
        ListResponse<ResponsiveActionItem>
      >(`prior-finalized-responsive-items/${projectId}`, true, data)
      .pipe(map(res => res.data));
  }

  exportOpenActionItems(
    projectId: number,
    inspectionId?: number
  ): Observable<ResponsiveActionItem[]> {
    let data: any;

    if (inspectionId) {
      data = {
        inspectionId,
        format: "pdf"
      };
    }

    return this.apiService.export(
      `open-responsive-action-items/${projectId}/export-pdf`,
      data
    );
  }

  getOpenActionItems(
    projectId: number,
    inspectionId?: number
  ): Observable<ResponsiveActionItem[]> {
    let data: any;
    if (inspectionId) {
      data = {
        inspectionId
      };
    }
    return this.apiService
      .get<
        ListResponse<ResponsiveActionItem>
      >(`open-responsive-action-items/${projectId}`, true, data)
      .pipe(map(res => res.data));
  }

  updateResponsiveActionItem(
    itemId: number,
    data: any
  ): Observable<ResponsiveActionItem> {
    return this.apiService
      .put<
        ShowResponse<ResponsiveActionItem>
      >(`responsive-action-items/${itemId}`, true, data)
      .pipe(map(res => res.data));
  }

  bulkUpdateResponsiveActionItem(data: any): Observable<any> {
    return this.apiService.put<any>(
      `responsive-action-items/bulk-updates/`,
      true,
      data
    );
  }

  deleteResponsiveActionItem(itemId: number): Observable<void> {
    return this.apiService.delete<void>(
      `responsive-action-items/${itemId}`,
      true
    );
  }

  deleteDischargePoint(id: number): Observable<void> {
    return this.apiService.delete<void>(`discharge-points/${id}`, true);
  }

  getDischargePointsForProject(
    projectId: number
  ): Observable<DischargePoint[]> {
    return this.apiService
      .get<ListResponse<DischargePoint>>(`discharge-points/${projectId}`, true)
      .pipe(map(res => res.data));
  }

  getERecipientsForProject(
    projectId: number,
    inspectionType: string
  ): Observable<ERecipients> {
    return this.apiService
      .get<ShowResponse<ERecipients>>(
        `projects/${projectId}/recipients`,
        true,
        {
          type: inspectionType
        }
      )
      .pipe(map(res => res.data));
  }

  deleteAttachment(id: number): Observable<void> {
    return this.apiService.delete("inspection-attachments/" + id, true);
  }

  addQaObservation(qaInspectionId: number, data: any) {
    return this.apiService
      .post<any>(
        `inspections/qa-inspections/${qaInspectionId}/qa-observations`,
        true,
        data
      )
      .pipe(map(res => res.data));
  }

  updateQaObservation(observationId: number, data: any) {
    return this.apiService
      .put<any>(`qa-observations/${observationId}`, true, data)
      .pipe(map(res => res.data));
  }

  deleteQaObservation(observationId: number): Observable<void> {
    return this.apiService.delete(`qa-observations/${observationId}`, true);
  }

  addPreAcquisitionObservation(preAcquisitionInspectionId: number, data: any) {
    return this.apiService
      .post<any>(
        `inspections/pre-acquisition-inspections/${preAcquisitionInspectionId}/pre-acquisition-observations`,
        true,
        data
      )
      .pipe(map(res => res.data));
  }

  updatePreAcquisitionObservation(observationId: number, data: any) {
    return this.apiService
      .put<any>(`pre-acquisition-observations/${observationId}`, true, data)
      .pipe(map(res => res.data));
  }

  deletePreAcquisitionObservation(observationId: number): Observable<void> {
    return this.apiService.delete(
      `pre-acquisition-observations/${observationId}`,
      true
    );
  }

  addSafetyObservation(safetyInspectionId: number, data: any) {
    return this.apiService
      .post<any>(
        `inspections/safety-inspections/${safetyInspectionId}/safety-observations`,
        true,
        data
      )
      .pipe(map(res => res.data));
  }

  updateSafetyObservation(observationId: number, data: any) {
    return this.apiService
      .put<any>(`safety-observations/${observationId}`, true, data)
      .pipe(map(res => res.data));
  }

  deleteSafetyObservation(observationId: number): Observable<void> {
    return this.apiService.delete(`safety-observations/${observationId}`, true);
  }

  exportInspection(id: ID, format: "pdf" | "docx"): Observable<void> {
    return this.apiService
      .post<{
        url: string;
      }>(`inspections/${id}/export`, true, { format }, null, true)
      .pipe(concatMap(res => this.apiService.download(res.url)));
  }

  remail(id: ID): Observable<void> {
    return this.apiService.get(`inspections/${id}/re-email`, true);
  }

  getProjectMarkers(projectId: number): Observable<ResponsiveActionItem[]> {
    return this.apiService
      .get<{
        data: ResponsiveActionItem[];
      }>(`responsive-action-items/pins/${projectId}`, true)
      .pipe(map(val => val.data));
  }

  sswrCertifyInspection(inspectionId: number): Observable<Inspection> {
    return this.apiService
      .put<
        ShowResponse<Inspection>
      >(`inspections/${inspectionId}/sswr-certify`, true)
      .pipe(map(res => res.data));
  }

  fetchLastSiteInspectionComment(projectId: number): Observable<string> {
    return this.apiService
      .get<{
        comment: string;
      }>(`inspections/site-inspections/${projectId}/latest-comment`, true)
      .pipe(map(res => res.comment));
  }

  fetchLastMarylandInspectionInfo(
    projectId: number
  ): Observable<{
    certification_number: string;
    earth_disturbance_date: string;
  }> {
    return this.apiService.get<{
      certification_number: string;
      earth_disturbance_date: string;
    }>(`inspections/site-inspections/${projectId}/maryland-information`, true);
  }

  fetchLastPulteInspectionDetails(projectId: number): Observable<{
    recent_storm_event_date: string;
    rainfall_information: string;
    master_site_list_id: string;
    site_location: string;
  }> {
    return this.apiService.get<{
      recent_storm_event_date: string;
      rainfall_information: string;
      master_site_list_id: string;
      site_location: string;
    }>(
      `inspections/site-inspections-pulte/${projectId}/rainfall-information`,
      true
    );
  }
}
