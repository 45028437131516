<div class="container">
  <mat-sidenav-container [hasBackdrop]="false">
    <mat-sidenav
      [fixedInViewport]="true"
      [mode]="mode"
      [opened]="isSideNavOpen"
    >
      <div class="logo">
        <img src="../../../../assets/logo/emc-logo-lg.png" />
      </div>
      <div class="nav">
        <div class="back-button">
          <button (click)="toggleNav()" mat-icon-button>
            <mat-icon>keyboard_backspace</mat-icon>
          </button>
        </div>
        <div (click)="isNavOver ? toggleNav() : ''" class="nav-list">
          <a
            *ngIf="PermissionUtils.hasPermission(PermissionType.DASHBOARD_VIEW)"
            routerLink="/dashboard/home"
            routerLinkActive="active-link"
            >Dashboard</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.DASHBOARD_INSPECTION_VIEW
              )
            "
            routerLink="/dashboard/inspections"
            routerLinkActive="active-link"
            >Inspections</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.DASHBOARD_CLIENT_VIEW
              )
            "
            routerLink="/dashboard/clients"
            routerLinkActive="active-link"
            >Clients</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.DASHBOARD_PROJECT_VIEW
              )
            "
            routerLink="/dashboard/projects"
            routerLinkActive="active-link"
            >Projects</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.DASHBOARD_PROJECT_VIEW
              )
            "
            routerLink="/dashboard/rain-log"
            routerLinkActive="active-link"
            >Rain Log</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(PermissionType.DASHBOARD_USER_VIEW)
            "
            routerLink="/dashboard/users"
            routerLinkActive="active-link"
            >Users</a
          >
          <mat-divider
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.DASHBOARD_TOOL_VIEW
              ) ||
              PermissionUtils.hasPermission(
                PermissionType.DASHBOARD_REQUEST_AND_REPORT_VIEW
              )
            "
          ></mat-divider>

          <a
            *ngIf="
              PermissionUtils.hasPermission(PermissionType.DASHBOARD_TOOL_VIEW)
            "
            routerLink="/dashboard/tools/"
            routerLinkActive="active-link"
            >Tools</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.TOOL_TROUBLE_TICKET_VIEW
              )
            "
            routerLink="/dashboard/trouble-ticket/"
            routerLinkActive="active-link"
            >Trouble Ticket</a
          >
          <a
            *ngIf="
              PermissionUtils.hasAnyPermission([
                PermissionType.INSPECTION_REPORT_VIEW,
                PermissionType.INSPECTION_REPORT_VIEW_ASSIGNED
              ])
            "
            routerLink="/dashboard/upload-reports"
            routerLinkActive="active-link"
            >Upload Reports</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.TOOL_PROJECT_ACTIVITY_REPORT_VIEW
              )
            "
            routerLink="/dashboard/project-activity-reports"
            routerLinkActive="active-link"
            >Project Activity Reports</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.TOOL_EMAIL_CHANGE_MODULE_VIEW
              )
            "
            routerLink="/dashboard/email-change"
            routerLinkActive="active-link"
            >Email Change</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.DASHBOARD_REQUEST_AND_REPORT_VIEW
              )
            "
            routerLink="/dashboard/requests-reports/"
            routerLinkActive="active-link"
            >Requests & Reports</a
          >
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.TOOL_LEADS_MODULE_VIEW
              )
            "
            routerLink="/dashboard/leads/"
            routerLinkActive="active-link"
            >Leads</a
          >
          <mat-divider
            *ngIf="hasRequestReportPermission || hasToolsPermission"
          ></mat-divider>
          <a
            *ngIf="
              PermissionUtils.hasPermission(
                PermissionType.TOOL_VIEW_EXTERNAL_LINK
              )
            "
            routerLink="/dashboard/external-links/"
            routerLinkActive="active-link"
            >External Links</a
          >
          <span>
            <button (click)="logout()" class="logout-btn">Logout</button>
          </span>
          <span style="color: white">{{ "v." + appVersion }}</span>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content [@sideNavToggle]="toggle">
      <div
        *ngIf="isNavOver && state === VisibilityState.Visible"
        [@toggle]="toggle"
        class="header"
      >
        <button (click)="toggleNav()" class="menu" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
        <div class="logo">
          <img src="../../../../assets/logo/emc-logo-lg.png" />
        </div>
        <img
          [src]="user?.image_url || '/assets/placeholder.svg'"
          routerLink="/dashboard/user-profile"
        />
      </div>
      <div *ngIf="showProfile" class="profile-icon">
        <img
          [src]="user?.image_url || '/assets/placeholder.svg'"
          routerLink="/dashboard/user-profile"
        />
      </div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
