import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Pin } from "@emc-modules/shared/components/map-engine/map-engine.component";
import { MatDialog } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { Unsubscribe } from "../../../../unsubscribe";
import { MapDialogueComponent } from "@emc-modules/shared/components/map-dialogue/map-dialogue.component";

@Component({
  selector: "ae-map-pin",
  templateUrl: "./map-pin.component.html",
  styleUrls: ["./map-pin.component.scss"]
})
export class MapPinComponent extends Unsubscribe {
  @Input() pin: Pin;
  @Input() viewOnly: boolean;
  @Output() pinUpdated = new EventEmitter<Pin>();

  constructor(private _dialog: MatDialog) {
    super();
  }

  onPinClicked() {
    const refCategory = this._dialog.open(MapDialogueComponent, {
      width: "500px",
      height: "auto",
      data: {
        title: this.pin.title,
        comment: this.pin.comment,
        isViewOnly: this.viewOnly
      }
    });
    refCategory.componentInstance.onSubmit
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data: { title: string; comment: string }) => {
        this.pin.comment = data.comment;
        this.pin.title = data.title;
        this.pinUpdated.emit(this.pin);
      });
    refCategory.afterClosed().pipe(takeUntil(this._destroyed$)).subscribe();
  }
}
