import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "ae-search-field",
  templateUrl: "./emc-search-field.component.html",
  styleUrls: ["./emc-search-field.component.scss"]
})
export class EmcSearchFieldComponent implements OnInit, AfterViewInit {
  @Output() selectionChanged = new EventEmitter();
  @Input() control: FormControl = new FormControl();
  @Input() placeholder: string;
  @Input() message: string;
  @Input() multiple: boolean;
  @Input() disabled: boolean;
  @Input() sortOptions: boolean = true;

  public title: any;
  searchControl = new FormControl();
  filteredOptions: ISelectOption[] = [];
  private _options: ISelectOption[] = [];

  get options(): ISelectOption[] {
    return this._options;
  }

  @Input() set options(options: ISelectOption[]) {
    if (this.sortOptions) {
      this._options = options.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      this._options = options;
    }

    this.filteredOptions = this._options;
  }

  ngOnInit() {
    this.searchControl.valueChanges.subscribe((value: string) => {
      if (value) {
        this.filteredOptions = this.options.filter(
          option =>
            option.title.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
      } else {
        this.filteredOptions = this.options;
      }
    });
  }

  ngAfterViewInit() {
    // if (this.options && this.control.value) {
    //   const shouldClearField = !this.options.filter(o => o.value === this.control.value).length;
    //   if (shouldClearField) {
    //     this.control.setValue(null);
    //   }
    // }
  }

  isVisible(value: any) {
    return this.filteredOptions.findIndex(o => o.value === value) !== -1;
  }

  selectAll() {
    if (this.control) {
      this.control.setValue(this.options.map(option => option.value));
    }
    this.onSelectionChanged(this.options.map(option => option.value));
  }

  selectNone() {
    if (this.control) {
      this.control.setValue([]);
    }
    this.onSelectionChanged([]);
  }

  onSelectionChanged(value: any) {
    this.selectionChanged.emit(value);
  }

  onLongPress(title: any) {
    this.title = title;
    console.log("Title", title);
    setTimeout((this.title = null), 5000);
  }

  reset() {
    if (!this.disabled) {
      this.control.reset();
    }
  }

  trackByValue(index, item: ISelectOption) {
    return item.value;
  }
}
export interface ISelectOption {
  value: any;
  title: string;
}
