import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IBreadcrumb } from "@emc-modules/shared/interfaces/breadcrumb.interface";
import { AppPath, AppTitle } from "../../../../../../../app.path";
import { ToolsPath, ToolsTitle } from "@emc-modules/tools/tools.path";
import { ActivatedRoute, Router } from "@angular/router";
import { footerAnimation, VisibilityState } from "@emc-utils/constants";
import { ScrollService } from "@emc-modules/core/services/scroll.service";
import { takeUntil } from "rxjs/operators";
import { Unsubscribe } from "../../../../../../../unsubscribe";
import { TemplateService } from "@emc-modules/tools/modules/templates/services/template.service";
import { QuestionSuggestion } from "@emc-models/entities/question-suggestion.model";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Template } from "@emc-models/entities/template.model";
import { CommonUtils } from "@emc-utils/common-utils";
import { ToastrService } from "ngx-toastr";
import { State } from "@emc-models/entities/state.model";
import { OptionsService } from "@emc-services/options.service";
import { QuestionType } from "../../../../../../../enums/question-type.enum";
import { ClientFull } from "@emc-models/entities/client.model";
import { ProjectFull } from "@emc-models/entities/project.model";
import { DynamicQuestion } from "../../../../../../../models/entities/dynamic-question.model";

@Component({
  selector: "ae-add-edit-template",
  templateUrl: "./add-edit-template.component.html",
  styleUrls: ["./add-edit-template.component.scss"],
  animations: footerAnimation
})
export class AddEditTemplateComponent extends Unsubscribe implements OnInit {
  breadcrumbs: IBreadcrumb[] = [
    {
      url: AppPath.dashboard,
      label: AppTitle[AppPath.dashboard]
    },
    {
      url: AppPath.tools,
      label: AppTitle[AppPath.tools]
    },
    {
      url: ToolsPath.templates,
      label: ToolsTitle[ToolsPath.templates]
    }
  ];

  visibilityState: VisibilityState = VisibilityState.Visible;
  VisibilityState = VisibilityState;

  controls = {
    title: new FormControl(null, Validators.required),
    type: new FormControl(QuestionType.STORM_WATER, [Validators.required]),
    is_active: new FormControl(null, Validators.required),
    question_suggestions: new FormArray([]),
    dynamic_questions: new FormArray([])
  };

  saving: boolean;
  template: Template;
  @Input() templateId: number;
  @Input() isDialog = false;
  @Input() client: ClientFull;
  @Input() project: ProjectFull;
  @Output() closeDialog = new EventEmitter();
  stateId: number;
  state: State;

  isLoading: boolean;
  isStateTemplate: boolean;
  questionTypes: QuestionType[] = [
    QuestionType.STORM_WATER,
    QuestionType.MARYLAND,
    QuestionType.PULTE
  ];
  form = new FormGroup(this.controls);
  deletedQuestionIds: number[] = [];
  deletedDynamicQuestionIds: number[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private scrollService: ScrollService,
    private optionsService: OptionsService,
    private templateService: TemplateService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.scrollService
      .getScrollVisibilityState()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(state => {
        this.visibilityState = state;
      });
    this.isStateTemplate = this.activatedRoute.snapshot.data.isState;
    if (!this.templateId) {
      this.templateId = +this.activatedRoute.snapshot.params.id;
    }
    this.stateId = +this.activatedRoute.snapshot.params.stateId;

    if (!this.client) {
      this.client = window.history.state.client;
    }

    if (!this.project) {
      this.project = window.history.state.project;
    }

    if (this.isStateTemplate) {
      this.setUpStateTemplate();
    } else if (!isNaN(this.templateId)) {
      this.setUpTemplate();
    }
  }

  setUpStateTemplate() {
    this.isLoading = true;
    this.templateService
      .getTemplateForState(this.stateId)
      .subscribe(template => {
        this.template = template;
        this.templateId = template.id;
        this.initialiseForm(template);
        this.isLoading = false;
      });

    this.optionsService
      .getStates()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(states => {
        const selectedState = states.find(s => s.id === this.stateId);
        this.state = selectedState;
        if (selectedState) {
          this.breadcrumbs.push({
            url: "",
            label: "Edit " + selectedState.title
          });
        }
      });
  }

  setUpTemplate() {
    this.isLoading = true;
    this.templateService
      .getTemplateForId(this.templateId)
      .subscribe(template => {
        this.template = template;
        this.isLoading = false;
        this.initialiseForm(template);
        this.breadcrumbs.push({
          url: `${this.templateId}`,
          label: "Edit " + template.title
        });
      });
  }

  navigateBack() {
    if (this.isDialog) {
      this.closeDialog.emit();
    } else {
      this.router.navigate(["/dashboard/tools/templates"]);
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      CommonUtils.markControlsAsTouched(this.form);
      return;
    }
    console.log(
      this.form.value,
      this.controls.dynamic_questions,
      this.controls.dynamic_questions.value,
      "dynamic_questions"
    );
    this.saving = true;
    const data = {
      ...this.form.value,
      question_suggestions: [
        ...(this.controls.question_suggestions.value as any[]).map(
          suggestion => {
            const d: any = {
              title: suggestion.title,
              category_id: suggestion.category_id,
              config: {
                sort_order: suggestion.sort_order,
                is_active: suggestion.is_active,
                has_sub_questions: suggestion.has_sub_questions,
                has_textfield: suggestion.has_textfield,
                required_root_response: suggestion.required_root_response,
                show_assigned_to: suggestion.show_assigned_to,
                sub_questions: suggestion.sub_questions,
                options: suggestion.options,
                default_option: suggestion.default_option,
                responsive_action_item_suggestions:
                  suggestion.responsive_action_item_suggestions
              }
            };
            if (this.controls.type.value === QuestionType.PULTE) {
              if (!d.title) {
                d.title = "";
              }
              d.config.extras = {
                blank_question: suggestion.blank_question,
                show_justification_for_non_completion:
                  suggestion?.show_justification_for_non_completion || false
              };
            }
            if (suggestion.id) {
              d.id = suggestion.id;
            }
            return d;
          }
        )
      ],
      dynamic_questions: [
        ...(this.controls.dynamic_questions.value as any[]).map(question => {
          const d: any = {
            title: question.title,
            type: question.type,
            carryover_from_project: question.carryover_from_project,
            is_active: question.is_active,
            is_required: question.is_required,
            config: {
              options: CommonUtils.isOptionsVisible(question.type)
                ? question.options
                : [],
              sort_order: question.sort_order
            }
          };
          if (question.id) {
            d.id = question.id;
          }
          return d;
        })
      ]
    };

    if (this.deletedQuestionIds.length) {
      data.deleted_question_ids = this.deletedQuestionIds;
    }

    if (this.deletedDynamicQuestionIds.length) {
      data.deleted_dynamic_question_ids = this.deletedDynamicQuestionIds;
    }

    !!this.templateId ? this.update(data) : this.create(data);
  }

  create(data: any) {
    if (this.client) {
      data.client_id = this.client.id;
    } else if (this.project) {
      data.project_id = this.project.id;
    }
    this.templateService.createTemplate(data).subscribe(
      template => {
        this.toastr.success("Template Created Successfully");
        this.saving = false;
        this.navigateBack();
      },
      () => (this.saving = false)
    );
  }

  update(data: any) {
    this.templateService.updateTemplate(this.templateId, data).subscribe(
      template => {
        this.toastr.success("Template Updated Successfully");
        this.saving = false;
        this.navigateBack();
      },
      () => (this.saving = false)
    );
  }

  cloneQuestion(index: number) {
    const suggestion: FormGroup = this.controls.question_suggestions.at(
      index
    ) as FormGroup;

    const questionSuggestionGroup = new FormGroup({
      title: new FormControl(null, Validators.required),
      category_id: new FormControl(null, Validators.required),
      sort_order: new FormControl(
        this.controls.question_suggestions.length + 1
      ),
      is_active: new FormControl(false, Validators.required),
      has_sub_questions: new FormControl(false, Validators.required),
      has_textfield: new FormControl(false, Validators.required),
      show_assigned_to: new FormControl(false, Validators.required),
      required_root_response: new FormControl(false),
      sub_questions: new FormArray([]),
      options: new FormArray([]),
      default_option: new FormControl(null),
      responsive_action_item_suggestions: new FormArray([]),
      blank_question: new FormControl(false),
      show_justification_for_non_completion: new FormControl(false)
    });

    questionSuggestionGroup.patchValue({
      ...suggestion.value,
      sort_order: this.controls.question_suggestions.length + 1
    });

    (
      (suggestion.get("responsive_action_item_suggestions").value as any[]) ||
      []
    ).forEach(item => {
      const responsiveActionGroup = new FormGroup({
        title: new FormControl(item.title, Validators.required),
        is_active: new FormControl(item.is_active, Validators.required)
      });
      (
        questionSuggestionGroup.get(
          "responsive_action_item_suggestions"
        ) as FormArray
      ).push(responsiveActionGroup);
    });

    ((suggestion.get("sub_questions").value as any[]) || []).forEach(item => {
      const subQuestion = new FormGroup({
        title: new FormControl(item.title, Validators.required),
        options: new FormControl(item.options),
        has_textfield: new FormControl(item.has_textfield, Validators.required),
      });
      (questionSuggestionGroup.get("sub_questions") as FormArray).push(
        subQuestion
      );
    });

    this.controls.question_suggestions.push(questionSuggestionGroup);
  }

  addQuestion() {
    const responsiveActionGroup = new FormGroup({
      // sort_order: new FormControl(null),
      title: new FormControl(null, Validators.required),
      is_active: new FormControl(false, Validators.required)
    });

    const questionSuggestionGroup = new FormGroup({
      title: new FormControl(null, Validators.required),
      category_id: new FormControl(null, Validators.required),
      sort_order: new FormControl(
        this.controls.question_suggestions.length + 1
      ),
      is_active: new FormControl(false, Validators.required),
      has_sub_questions: new FormControl(false, Validators.required),
      has_textfield: new FormControl(false, Validators.required),
      show_assigned_to: new FormControl(false, Validators.required),
      required_root_response: new FormControl(false),
      sub_questions: new FormArray([]),
      options: new FormArray([]),
      default_option: new FormControl(null),
      responsive_action_item_suggestions: new FormArray([
        responsiveActionGroup
      ]),
      blank_question: new FormControl(false),
      show_justification_for_non_completion: new FormControl(false)
    });

    this.controls.question_suggestions.push(questionSuggestionGroup);
  }

  deleteQuestion(index: number, questionId?: number) {
    if (questionId) {
      this.deletedQuestionIds.push(questionId);
    }

    this.controls.question_suggestions.removeAt(index);
    this.computeSortOrder();
  }

  deleteDynamicQuestion(obj: { index: number; questionId?: number }) {
    const { index, questionId } = obj;
    console.log(index, questionId, "index, questionId");

    if (questionId) {
      this.deletedDynamicQuestionIds.push(questionId);
    }

    this.controls.dynamic_questions.removeAt(index);
  }

  changeSortOrder(newIndex: number, oldIndex: number) {
    const oldControl = this.controls.question_suggestions.at(oldIndex);
    this.controls.question_suggestions.removeAt(oldIndex);
    this.controls.question_suggestions.insert(newIndex, oldControl);
    this.computeSortOrder();
  }

  OnClick() {
    this.closeDialog.emit();
  }

  private initialiseForm(template: Template) {
    this.form.reset();
    this.initialiseQuestionSuggestions(template.question_suggestions);
    this.initialiseDynamicQuestions(template.dynamic_questions);
    this.form.patchValue({
      title: template.title,
      is_active: template.is_active,
      type: template.type
    });
    this.sortForm();
  }

  private initialiseQuestionSuggestions(
    questionSuggestions: QuestionSuggestion[]
  ) {
    questionSuggestions.forEach(questionSuggestion => {
      const rActionGroup = [];
      questionSuggestion.config.responsive_action_item_suggestions.forEach(
        responsiveAction => {
          const responsiveActionGroup = new FormGroup({
            // sort_order: new FormControl(responsiveAction.sort_order),
            title: new FormControl(responsiveAction.title, Validators.required),
            is_active: new FormControl(
              responsiveAction.is_active,
              Validators.required
            )
          });
          rActionGroup.push(responsiveActionGroup);
        }
      );

      const subQuestionGroups = [];

      if (questionSuggestion.config.has_sub_questions) {
        questionSuggestion.config.sub_questions.forEach(subQuestion => {
          const subQuestionGroup = new FormGroup({
            title: new FormControl(subQuestion.title),
            options: new FormControl(
              !!subQuestion?.options?.length ? subQuestion?.options : []
            ),
            has_textfield: new FormControl(!!subQuestion.has_textfield)
          });
          subQuestionGroups.push(subQuestionGroup);
        });
      }

      const optionGroups = [];

      if (questionSuggestion.config.options) {
        questionSuggestion.config.options.forEach(option => {
          const optionGroup = new FormGroup({
            title: new FormControl(option.title),
            is_trigger: new FormControl(option.is_trigger)
          });
          optionGroups.push(optionGroup);
        });
      }

      const questionSuggestionGroup = new FormGroup({
        id: new FormControl(questionSuggestion.id),
        title: new FormControl(questionSuggestion.title, Validators.required),
        category_id: new FormControl(
          questionSuggestion.category_id,
          Validators.required
        ),
        sort_order: new FormControl(questionSuggestion.config.sort_order),
        is_active: new FormControl(
          questionSuggestion.config.is_active,
          Validators.required
        ),
        has_sub_questions: new FormControl(
          !!questionSuggestion.config.has_sub_questions
        ),
        has_textfield: new FormControl(
          !!questionSuggestion.config.has_textfield
        ),
        show_assigned_to: new FormControl(
          !!questionSuggestion.config.show_assigned_to
        ),
        required_root_response: new FormControl(
          !!questionSuggestion.config.required_root_response
        ),
        sub_questions: new FormArray(subQuestionGroups),
        options: new FormArray(optionGroups),
        default_option: new FormControl(
          questionSuggestion.config.default_option
        ),
        responsive_action_item_suggestions: new FormArray(rActionGroup),
        blank_question: new FormControl(
          questionSuggestion.config?.extras?.blank_question
        ),
        show_justification_for_non_completion: new FormControl(
          questionSuggestion.config?.extras?.show_justification_for_non_completion
        )
      });

      this.controls.question_suggestions.push(questionSuggestionGroup);
    });
  }

  private initialiseDynamicQuestions(dynamicQuestions: DynamicQuestion[]) {
    dynamicQuestions.forEach(dynamicQuestion => {
      const optionGroups = [];

      if (dynamicQuestion.config.options) {
        dynamicQuestion.config.options.forEach(option => {
          const optionGroup = new FormGroup({
            title: new FormControl(option.title)
          });
          optionGroups.push(optionGroup);
        });
      }

      const dynamicQuestionGroup = new FormGroup({
        id: new FormControl(dynamicQuestion.id),
        title: new FormControl(dynamicQuestion.title, Validators.required),
        type: new FormControl(dynamicQuestion.type, Validators.required),
        sort_order: new FormControl(dynamicQuestion.config.sort_order),
        options: new FormArray(optionGroups),
        is_active: new FormControl(dynamicQuestion.is_active),
        is_required: new FormControl(dynamicQuestion.is_required),
        carryover_from_project: new FormControl(
          dynamicQuestion.carryover_from_project
        )
      });

      this.controls.dynamic_questions.push(dynamicQuestionGroup);
    });
  }

  private sortForm() {
    const array = this.controls.question_suggestions.value;
    array.sort((a, b) => a.sort_order - b.sort_order);
    this.controls.question_suggestions.patchValue(array);
  }

  private computeSortOrder() {
    const array = this.controls.question_suggestions.value as any[];
    array.forEach((q, index) => {
      q.sort_order = index + 1;
    });
    this.controls.question_suggestions.patchValue(array);
  }
}
