import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";

export const AUTH_TOKEN = "auth_token";
export const DATE_FORMAT = "EEE, MMM d, y";
export const DATE_TIME_FORMAT = "medium";
export const TIME_FORMAT = "h:mm a";
export const SEARCH_LIMIT = 10;

export enum __HTTPResponseType {
  ARRAY_BUFFER = "arraybuffer",
  BLOB = "blob",
  TEXT = "text",
  JSON = "json"
}

export enum ExportFormat {
  EXCEL = "xlsx",
  CSV = "csv"
}

export enum InspectionExportFormat {
  PDF = "pdf"
}

export enum VisibilityState {
  Visible = "visible",
  Hidden = "hidden"
}

export enum Direction {
  Up = "Up",
  Down = "Down"
}

export enum DynamicQuestionType {
  DATE = "DATE",
  NUMBER = "NUMBER",
  TEXT = "TEXT",
  DROPDOWN = "DROPDOWN",
  CHECKBOX = "CHECKBOX",
  MULTISELECT_DROPDOWN = "MULTISELECT_DROPDOWN",
  MULTILINE_TEXT = "MULTILINE_TEXT",
  BUTTON_TOGGLE = "BUTTON_TOGGLE",
  MULTI_BUTTON_TOGGLE = "MULTI_BUTTON_TOGGLE"
}

export const footerAnimation = [
  trigger("footerToggle", [
    transition(":enter", [animate(300)]),
    transition(":leave", [animate(300)]),
    transition("* => *", animate("500ms ease-in-out"))
  ]),
  trigger("contentToggle", [
    state(VisibilityState.Hidden, style({ paddingBottom: "0" })),
    transition("* => *", animate("500ms ease-in-out"))
  ])
];

export const timepickerTheme: NgxMaterialTimepickerTheme = {
  container: {
    bodyBackgroundColor: "#fff",
    buttonColor: "#007EC5"
  },
  dial: {
    dialBackgroundColor: "#007EC5"
  },
  clockFace: {
    clockFaceBackgroundColor: "#F0F0F0",
    clockHandColor: "#007EC5",
    clockFaceTimeInactiveColor: "#6c6c6c"
  }
};
