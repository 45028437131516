import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "ae-email-text-area",
  templateUrl: "./email-text-area.component.html",
  styleUrls: ["./email-text-area.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmailTextAreaComponent,
      multi: true
    }
  ]
})
export class EmailTextAreaComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  public value: any[] = [];
  disabled: boolean;
  @Input() placeholder = "Emails";
  @Input() inArray = true;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.onTouch();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value): void {
    this.value = !!value ? (!this.inArray ? value : value.join(",")) : null;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValueChange(value: string) {
    if (this.inArray) {
      this.onChange(
        value
          ?.split(",")
          ?.filter(v => v !== "")
          ?.map(v => v.trim()) || []
      );
    } else {
      this.onChange(value);
    }
  }

  private onChange = value => {
    // TODO "split logic will come here"
  };

  private onTouch = () => {};
}
