import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { AuthManager } from "../managers/auth.manager";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router,
    private authManager: AuthManager
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authManager.hasAuthToken()) {
      this.authManager.setReturnUrl(state.url);
      this.router.navigateByUrl("auth/login");
      return false;
    }
    return true;
  }
}
