import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";

@Component({
  selector: "ae-generic-save-confirmation",
  templateUrl: "./generic-save-confirmation.component.html",
  styleUrls: ["./generic-save-confirmation.component.scss"]
})
export class GenericSaveConfirmationComponent implements OnInit {
  @Input() title = "Discard Unsaved Changes?";
  @Input() description =
    "You have unsaved changes! If you leave, your changes will be lost. Are you sure you want to discard changes?";
  @Input() yesButtonTitle = "Yes";
  @Input() noButtonTitle = "No";
  @Input() showYesButton = true;
  @Input() showNoButton = true;
  @Output() saveButtonClicked = new EventEmitter<boolean>();

  constructor(
    private dialogRef: MatDialogRef<GenericSaveConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) data: { title: string; description: string }
  ) {
    if (data) {
      this.title = data.title;
      this.description = data.description;
    }
  }

  ngOnInit() {}

  close(data: boolean) {
    this.dialogRef.close(data);
  }

  save() {
    this.saveButtonClicked.emit(true);
    this.close(false);
  }

  clickNo() {
    this.close(false);
  }

  clickYes() {
    this.close(true);
  }
}
