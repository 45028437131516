import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ResponsiveActionItem } from "@emc-models/entities/responsive-action-item.model";
import { InspectionsService } from "@emc-modules/inspections/services/inspections.service";
import { Unsubscribe } from "../../../../unsubscribe";
import { Comment } from "@emc-models/entities/compliance.model";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { AddEditNoteComponent } from "@emc-modules/inspections/components/add-edit-note/add-edit-note.component";
import { filter, takeUntil } from "rxjs/operators";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { PermissionType } from "../../../../enums/permission-type.enum";
import { DialogService } from "@emc-modules/shared/services/dialog.service";
import { GenericConfirmationComponent } from "@emc-modules/shared/components/generic-confirmation/generic-confirmation.component";
import { ToastrService } from "ngx-toastr";
import { Config } from "@emc-models/entities/config.model";
import { AddEditActionItemComponent } from "@emc-modules/inspections/components/add-edit-action-item/add-edit-action-item.component";
import { SiteMap } from "@emc-models/entities/site-map.model";
import {
  MapData,
  MapOperationMode
} from "@emc-modules/shared/components/map-engine/map-engine.component";
import { FullScreenMapComponent } from "@emc-modules/inspections/components/dialogues/full-screen-map/full-screen-map.component";
import { QuestionType } from "../../../../enums/question-type.enum";
import * as moment from "moment";

@Component({
  selector: "ae-view-responsive-action-item-card",
  templateUrl: "./view-responsive-action-item-card.component.html",
  styleUrls: ["./view-responsive-action-item-card.component.scss"]
})
export class ViewResponsiveActionItemCardComponent
  extends Unsubscribe
  implements OnInit
{
  saving: boolean;
  editing: boolean;
  deleting: boolean;
  notes: Comment[] = [];
  data: ResponsiveActionItem[] = [];
  @Input() siteMaps: SiteMap[] = [];
  @Input() inspectionId: number;
  @Input() showButtons = true;
  @Input() showNoteButton = true;
  @Output() delete = new EventEmitter();
  @Input() showCheckBox = false;
  @Output() selectedChanged = new EventEmitter<boolean>();
  @Input() selected = false;
  @Input() inspectionType = QuestionType.STORM_WATER;
  @Input() inspectionDate = moment().format("YYYY-MM-DD");
  PermissionUtils = PermissionUtils;
  PermissionType = PermissionType;
  QuestionType = QuestionType;
  config: Config;
  response: string | { [id: string]: string };
  hasAddEditPermission = PermissionUtils.hasPermission(
    PermissionType.INSPECTION_ADD_EDIT_RESPONSIVE_ACTION
  );

  constructor(
    private inspectionService: InspectionsService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private _dialog: MatDialog
  ) {
    super();
  }

  private _item: ResponsiveActionItem;

  get item(): ResponsiveActionItem {
    return this._item;
  }

  @Input() set item(item: ResponsiveActionItem) {
    this._item = item;
    if (this._item) {
      this.notes = [...this._item.notes];
    }
  }

  ngOnInit() {
    if (this.item.site_map_id && this.siteMaps.length) {
      const map = this.siteMaps.find(s => s.id === this.item.site_map_id);
      if (map) {
        const img = new Image();
        img.src = map.image_url;
      }
    }
  }

  deleteCard() {
    this.dialogService
      .open(GenericConfirmationComponent, {
        height: "200px",
        data: {
          title: `Delete Responsive Action Item`,
          description: `Are you sure you want to delete this responsive action item?`
        }
      })
      .afterClosed()
      .pipe(filter(d => !!d?.yesClicked))
      .subscribe(v => {
        if (v) {
          this.deleting = true;
          this.inspectionService
            .deleteResponsiveActionItem(
              this.item.id as number,
              this.inspectionId
            )
            .subscribe(
              () => {
                this.deleting = false;
                this.delete.emit();
                this.toastr.success(
                  "Responsive Action Item Successfully Deleted"
                );
              },
              () => (this.deleting = false)
            );
        }
      });
  }

  updateItemCard(data: any) {
    this.editing = true;
    this.inspectionService
      .updateResponsiveActionItem(
        this.item.id as number,
        this.inspectionId,
        data
      )
      .subscribe(
        item => {
          this.item = item;
          this.editing = false;
        },
        () => {
          this.editing = false;
        }
      );
  }

  editItemCard() {
    this.dialogService
      .open(AddEditActionItemComponent, {
        data: {
          actionItem: this.item,
          siteMaps: this.siteMaps
        },
        width: "90vw"
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(data => {
        if (data) {
          this.updateItemCard(data);
        }
      });
  }

  update(data: any) {
    this.saving = true;
    this.inspectionService
      .updateResponsiveActionItem(
        this.item.id as number,
        this.inspectionId,
        data
      )
      .subscribe(
        item => {
          this.saving = false;
          this.item = item;
        },
        () => (this.saving = false)
      );
  }

  addNote() {
    this.dialog
      .open(AddEditNoteComponent, {
        data: {
          date: this.inspectionDate
        },
        width: "100vw",
        maxWidth: "600px"
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(data => {
        if (data) {
          this.notes.push(data);
          this.update({
            notes: this.notes
          });
        }
      });
  }

  editNote(index: number) {
    this.dialog
      .open(AddEditNoteComponent, {
        data: this.notes[index]
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(data => {
        if (data) {
          this.notes[index] = data;
          this.update({
            notes: this.notes
          });
        }
      });
  }

  deleteNote(index: number) {
    const notes = [
      ...this.notes.slice(0, index),
      ...this.notes.slice(index + 1)
    ];
    this.update({
      notes
    });
  }

  openMap() {
    const index = this.siteMaps.findIndex(s => s.id === this.item.site_map_id);

    const data: MapData = {} as MapData;
    data.uId = this.item.site_map_id;
    data.defaultTitle = this.item.title;
    data.mapImageUrl = this.item.siteMap.image_url;
    data.pins = [
      {
        x: this.item.coordinates.x,
        y: this.item.coordinates.y,
        type: this.item.coordinates.type,
        title: this.item.title,
        comment: this.item.comment
      }
    ];
    const refCategory = this._dialog.open(FullScreenMapComponent, {
      width: "99vw",
      height: "99vh",
      data: { maps: [data], mapOperationMode: MapOperationMode.VIEW }
    });
  }

  onChange(event: MatCheckboxChange) {
    this.selectedChanged.emit(event.checked);
  }
}
