import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { InspectionCategory } from "@emc-models/entities/inspection-category.model";

export interface InspectionCategoryState
  extends EntityState<InspectionCategory> {
  loading: boolean;
  loaded: boolean;
}

const initialState: InspectionCategoryState = {
  loading: false,
  loaded: false
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "inspection-category" })
export class InspectionCategoryStore extends EntityStore<
  InspectionCategoryState,
  InspectionCategory
> {
  constructor() {
    super(initialState);
  }
}
