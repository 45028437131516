import { Component, EventEmitter, Inject } from "@angular/core";
import {
  MapData,
  MapOperationMode
} from "@emc-modules/shared/components/map-engine/map-engine.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "ae-full-screen-map",
  templateUrl: "./full-screen-map.component.html",
  styleUrls: ["./full-screen-map.component.scss"]
})
export class FullScreenMapComponent {
  mapUpdated = new EventEmitter<MapData>();
  inspectionChanged = new EventEmitter<number>();
  mapOperationMode: MapOperationMode;
  inspectionList: { title: string; id: number }[] = [];
  initialIndex: number;
  maps: MapData[];

  constructor(
    public dialogRef: MatDialogRef<FullScreenMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mapOperationMode = data.mapOperationMode
      ? data.mapOperationMode
      : MapOperationMode.EDIT;
    data.inspectionList
      ? (this.inspectionList = data.inspectionList)
      : (this.inspectionList = []);
    this.initialIndex = data.mapIndex ? data.mapIndex : 0;
    this.maps = data.maps;
  }

  onMapUpdated(newData: MapData) {
    this.mapUpdated.emit(newData);
    this.dialogRef.close();
  }

  onInspectionSelected(inspectionId: number) {
    this.inspectionChanged.emit(inspectionId);
  }
}
