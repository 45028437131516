<div class="emc-box !tw-mb-4">
  <div
    class="tw-bg-white tw-w-full tw-px-2"
    *ngFor="let dynamicQuestion of group.controls; let i = index"
  >
    <button [matMenuTriggerFor]="menu" mat-icon-button>
      {{ dynamicQuestion.get("sort_order").value }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        (click)="changeSortOrder(i, option - 1)"
        *ngFor="let option of sortOptions"
        mat-menu-item
      >
        {{ option }}
      </button>
    </mat-menu>
    <div class="tw-flex tw-items-center tw-justify-end tw-space-x-2 tw-mt-3">
      <mat-checkbox
        color="primary"
        [formControl]="dynamicQuestion.get('is_active')"
      ></mat-checkbox>
      <mat-icon
        (click)="deleteDynamicQuestion(dynamicQuestion, i)"
        color="warn"
        matTooltip="Delete"
        >delete</mat-icon
      >
    </div>
    <div class="elements">
      <p aeMarkAsterisk class="label">Title</p>
      <mat-form-field class="question" floatLabel="never">
        <input
          [formControl]="dynamicQuestion.get('title')"
          matInput
          placeholder="Title"
          type="text"
        />
      </mat-form-field>
    </div>
    <div class="elements">
      <p aeMarkAsterisk class="label">Type</p>
      <mat-form-field floatLabel="never">
        <mat-select
          [formControl]="dynamicQuestion.get('type')"
          placeholder="Type"
        >
          <mat-option *ngFor="let type of types" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="elements">
      <mat-checkbox
        color="primary"
        [formControl]="dynamicQuestion.get('carryover_from_project')"
        >Carryover from project</mat-checkbox
      >
    </div>
    <div class="elements">
      <mat-checkbox
        color="primary"
        [formControl]="dynamicQuestion.get('is_required')"
        >Required</mat-checkbox
      >
    </div>

    <div *ngIf="isOptionsVisible(dynamicQuestion.get('type').value)">
      <div class="elements">
        <p class="label">Options</p>
        <div class="sub-element">
          <button
            (click)="addOption(dynamicQuestion)"
            class="secondary"
            mat-button
          >
            Add Option
          </button>
        </div>
      </div>
      <div class="responsive-action-container">
        <ae-option-card
          (delete)="deleteOption(dynamicQuestion, i)"
          *ngFor="
            let option of dynamicQuestion?.get('options')?.controls;
            let i = index
          "
          [group]="option"
        >
        </ae-option-card>
      </div>
    </div>
  </div>
  <button (click)="addQuestion()" class="secondary" mat-button>
    Add Dynamic Question
  </button>
</div>
