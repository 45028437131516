import { EntityState, EntityStore, ID, StoreConfig } from "@datorama/akita";
import { AdminUploadReport } from "@emc-models/entities/admin-upload-reports.model";
import { Injectable } from "@angular/core";

export interface AdminReportsState extends EntityState<AdminUploadReport> {
  offset: number;
  limit?: number;
  loading: boolean;
  loaded: boolean;
  fullLoadingIds: ID[];
  fullLoadedIds: ID[];
  startDate?: string;
  endDate?: string;
  clientIds: ID[];
  projectIds: ID[];
  userIds: ID[];
  query?: string;
  inspectionType: string[];
}

const initialState = {
  offset: 0,
  loading: false,
  loaded: false,
  clientIds: [],
  projectIds: [],
  userIds: [],
  fullLoadingIds: [],
  fullLoadedIds: [],
  inspectionType: []
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "admin-reports" })
export class AdminReportsStore extends EntityStore<AdminReportsState> {
  constructor() {
    super(initialState);
  }

  setFullLoading(adminReportId: ID) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadingIds.indexOf(adminReportId) === -1) {
        fullLoadingIds = [...fullLoadingIds, adminReportId];
      }
      const loadedIndex = fullLoadedIds.indexOf(adminReportId);
      if (loadedIndex !== -1) {
        fullLoadedIds = [
          ...fullLoadedIds.slice(0, loadedIndex),
          ...fullLoadedIds.slice(loadedIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }

  setFullLoaded(adminReportId: ID) {
    this.update(state => {
      let fullLoadingIds = state.fullLoadingIds;
      let fullLoadedIds = state.fullLoadedIds;

      if (fullLoadedIds.indexOf(adminReportId) === -1) {
        fullLoadedIds = [...fullLoadedIds, adminReportId];
      }
      const loadingIndex = fullLoadingIds.indexOf(adminReportId);
      if (loadingIndex !== -1) {
        fullLoadingIds = [
          ...fullLoadingIds.slice(0, loadingIndex),
          ...fullLoadingIds.slice(loadingIndex + 1)
        ];
      }
      return {
        fullLoadingIds,
        fullLoadedIds
      };
    });
  }
}
