import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "@emc-modules/core/services/app.service";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { AlertService } from "./alert.service";
import { ErrorService } from "./error.service";
import { environment } from "@emc-environment/environment";
import { saveAs } from "file-saver/dist/FileSaver";

@Injectable()
export class ApiService {
  private baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private appService: AppService,
    private errorService: ErrorService,
    private alertService: AlertService
  ) {}

  private static hasToken(): boolean {
    return !!localStorage.getItem("auth_token");
  }

  private static getToken(): string {
    return localStorage.getItem("auth_token");
  }

  private static deleteToken(): void {
    localStorage.removeItem("auth_token");
  }

  exportUploadReports(data: any) {
    return this.export("export/uploaded-reports", data);
  }

  exportOpenResponsiveActions(data) {
    return this.export("export/open-responsive-actions", data);
  }

  export(url: string, data: any): Observable<any> {
    return this.post(
      url,
      true,
      data,
      null,
      true,
      data.format === "pdf" ? "json" : "blob",
      true
    ).pipe(
      map((res: Response) => {
        if (data.format !== "pdf") {
          const contentDispositionHeader = res.headers.get(
            "Content-Disposition"
          );
          const result = contentDispositionHeader
            .split(";")[1]
            .trim()
            .split("=")[1];
          const fileName = result.replace(/"/g, "");
          saveAs(res.body, fileName);
          return;
        }
        return (res.body as any).url;
      }),
      concatMap((downloadUrl: any) => {
        console.log(downloadUrl);
        if (downloadUrl) {
          return this.download(downloadUrl);
        }
        return of(true);
      })
    );
  }

  download(
    url: string,
    fileName?: string,
    useFileNameFromContent: boolean = false,
    useAuthHeaders: boolean = false
  ): Observable<void> {
    const decodedUrl = decodeURIComponent(url).replace(/\+/g, " ");
    let name = fileName
      ? fileName
      : decodedUrl.substr(decodedUrl.lastIndexOf("/") + 1);
    return this.get(url, useAuthHeaders, null, null, false, "blob").pipe(
      map((response: Response) => {
        if (useFileNameFromContent) {
          const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
          );
          const result = contentDispositionHeader
            .split(";")[1]
            .trim()
            .split("=")[1];
          name = result.replace(/"/g, "");
        }
        saveAs(url, name);
        return;
      })
    );
  }

  get<T>(
    url: string,
    useAuthHeaders: boolean,
    data?: any,
    headers?: HttpHeaders,
    useBaseUrl: boolean = true,
    responseType?: string
  ): Observable<T> {
    const options = {
      params: this.buildParams(data),
      withCredentials: false,
      headers: this.buildHeaders(useAuthHeaders, headers)
    };
    if (responseType) {
      options["responseType"] = responseType;
    }
    return this.http
      .get<T>(useBaseUrl ? this.baseUrl + url : url, options)
      .pipe(catchError(err => this.handleError(err)));
  }

  post<T>(
    url: string,
    useAuthHeaders: boolean,
    data: any,
    headers?: HttpHeaders,
    useBaseUrl: boolean = true,
    responseType?: string,
    fullResponse = false
  ): Observable<T> {
    const options = {
      headers: this.buildHeaders(useAuthHeaders, headers),
      withCredentials: false
    };
    if (responseType) {
      options["responseType"] = responseType;
    }

    if (fullResponse) {
      options["observe"] = "response";
    }

    return this.http
      .post<T>(useBaseUrl ? this.baseUrl + url : url, data, options)
      .pipe(catchError(err => this.handleError(err)));
  }

  put<T>(
    url: string,
    useAuthHeaders: boolean,
    data?: any,
    headers?: HttpHeaders,
    useBaseUrl: boolean = true,
    responseType?: string
  ): Observable<T> {
    const options = {
      headers: this.buildHeaders(useAuthHeaders, headers),
      withCredentials: false
    };

    if (responseType) {
      options["responseType"] = responseType;
    }
    return this.http
      .put<T>(useBaseUrl ? this.baseUrl + url : url, data, options)
      .pipe(catchError(err => this.handleError(err)));
  }

  patch<T>(
    url: string,
    useAuthHeaders: boolean,
    data?: any,
    headers?: HttpHeaders,
    useBaseUrl: boolean = true,
    responseType?: string
  ): Observable<T> {
    console.log("[api.service] patch", url, data);
    const options = {
      headers: this.buildHeaders(useAuthHeaders, headers),
      withCredentials: false
    };
    if (responseType) {
      options["responseType"] = responseType;
    }
    return this.http
      .patch<T>(useBaseUrl ? this.baseUrl + url : url, data, options)
      .pipe(catchError(err => this.handleError(err)));
  }

  delete<T>(
    url: string,
    useAuthHeaders: boolean,
    data?: any,
    headers?: HttpHeaders,
    useBaseUrl: boolean = true,
    responseType?: string
  ): Observable<T> {
    console.log("[api.service] delete", url, data);

    const options = {
      body: this.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers),
      withCredentials: false
    };

    if (responseType) {
      options["responseType"] = responseType;
    }
    return this.http
      .delete<T>(useBaseUrl ? this.baseUrl + url : url, options)
      .pipe(catchError(err => this.handleError(err)));
  }

  upload(
    url: string,
    file: File,
    useAuthHeaders: boolean,
    key?: string,
    useBaseUrl: boolean = true
  ): Observable<any> {
    let formData: any = file;
    if (key) {
      formData = new FormData();
      formData.append(key, file, file.name);
    }

    return this.http.put(useBaseUrl ? this.baseUrl + url : url, formData, {
      reportProgress: true,
      observe: "events",
      headers: this.buildHeaders(useAuthHeaders),
      withCredentials: false
    });
  }

  /*
   * Error thrown from backend is of this form
   * message : exception.message,
   * code    : exception.errorCode,
   * meta    : exception.meta,
   * errors  : exception.errors
   */
  public handleError(httpErrorResponse: HttpErrorResponse) {
    console.log(httpErrorResponse);
    if (httpErrorResponse.status === 401 && ApiService.getToken()) {
      this.appService.logout();
    }
    const error = httpErrorResponse.error;
    let message = "";
    // Error if user is rejected or inactive
    if (error?.code === 103 || error?.code === 104) {
      return throwError(error);
    }
    // This error code correspond to Validation Error.
    if (error?.code === 9998) {
      const additionalProperties = [];
      const missingProperties = [];
      const invalidValues = [];
      Object.keys(error.errors).forEach(key => {
        if (error.errors[key].params.missingProperty) {
          missingProperties.push(error.errors[key].params.missingProperty);
        } else if (error.errors[key].params.additionalProperty) {
          additionalProperties.push(
            error.errors[key].params.additionalProperty
          );
        } else if (error.errors[key].params.keyword) {
          invalidValues.push(error.errors[key].params.keyword);
        }
      });

      if (additionalProperties.length) {
        message +=
          "Additional Properties: " + additionalProperties.join(",") + "\n";
      }

      if (missingProperties.length) {
        message += "Missing Properties: " + missingProperties.join(",") + "\n";
      }

      if (invalidValues.length) {
        message += "Invalid Values: " + invalidValues.join(",") + "\n";
      }
    } else {
      message = error?.message ? error?.message : httpErrorResponse.statusText;
    }
    this.alertService.error(message);
    return throwError({ message, error });
  }

  private buildParams(data: any): HttpParams {
    let params = new HttpParams();
    if (data) {
      for (const key of Object.keys(data)) {
        params = params.append(key, data[key]);
      }
    }
    return params;
  }

  private buildHeaders(
    useAuthHeaders: boolean,
    headers?: HttpHeaders
  ): HttpHeaders {
    if (!useAuthHeaders) {
      return headers;
    }
    let mutatedHeaders: HttpHeaders;

    if (!!headers) {
      mutatedHeaders = headers;
    } else {
      mutatedHeaders = new HttpHeaders();
    }
    return ApiService.hasToken()
      ? mutatedHeaders.set("Authorization", ApiService.getToken())
      : mutatedHeaders;
  }
}
