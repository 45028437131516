import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "[aeMarkAsterisk]"
})
export class MarkAsteriskDirective {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  private _show = true;

  @Input("aeMarkAsterisk") set show(value) {
    if (value !== null && value !== "") {
      this._show = value;
    }
    this.displayAsterisk();
  }

  displayAsterisk() {
    if (this._show) {
      const parent = this.renderer.parentNode(this.el.nativeElement);
      if (!parent.getElementsByClassName("required-asterisk").length) {
        this.renderer.addClass(this.el.nativeElement, "required-asterisk");
      }
    } else {
      this.renderer.removeClass(this.el.nativeElement, "required-asterisk");
    }
  }
}
