<div class="map-container">
  <div class="map-wrapper">
    <div (click)="previousMap()" *ngIf="maps.length > 1" class="nav-wrapper">
      <i class="material-icons"> skip_previous </i>
    </div>
    <div
      #currentImageWrapper
      (drag)="handleMapPanning($event)"
      (dragend)="mapPanningEnded()"
      (dragstart)="hideGhostElement($event)"
      (wheel)="handleMousePadPinch($event)"
      [ngStyle]="{ 'pointer-events': isPinDragging ? 'none' : 'auto' }"
      class="current-map-wrapper"
      mwlDroppable
    >
      <mat-spinner
        *ngIf="!isCurrentMapDownloaded"
        diameter="60"
        strokeWidth="5"
      ></mat-spinner>
      <img
        #currentImage
        (click)="onMapClicked($event)"
        [ngStyle]="{ display: isCurrentMapDownloaded ? 'block' : 'none' }"
        class="map-image"
      />
      <ng-container
        *ngIf="maps[currentMapIndex].pins && maps[currentMapIndex].pins.length"
      >
        <ng-container *ngIf="mapOperationMode !== 'view'">
          <ae-map-pin
            (click)="onPinSelected(i)"
            (dragEnd)="pinDragEnded($event, pin)"
            (dragStart)="pinDragStarted($event)"
            (pinUpdated)="onPinUpdated($event, pin)"
            *ngFor="let pin of maps[currentMapIndex].pins; let i = index"
            [ghostDragEnabled]="mapOperationMode !== 'view'"
            [pin]="pin"
            [style.top]="isPinDragging ? 10000 : getPinYDimension(pin)"
            [style.left]="isPinDragging ? 10000 : getPinXDimension(pin)"
            [showOriginalElementWhileDragging]="mapOperationMode === 'view'"
            [viewOnly]="mapOperationMode === 'view'"
            class="map-pin"
            mwlDraggable
          ></ae-map-pin>
        </ng-container>
        <ng-container *ngIf="mapOperationMode === MapOperationMode.VIEW">
          <ae-map-pin
            (click)="onPinSelected(i)"
            *ngFor="let pin of maps[currentMapIndex].pins; let i = index"
            [ngStyle]="{
              top: isPinDragging ? 10000 : getPinYDimension(pin),
              left: isPinDragging ? 10000 : getPinXDimension(pin)
            }"
            [pin]="pin"
            [viewOnly]="mapOperationMode === MapOperationMode.VIEW"
            class="map-pin"
            style="user-select: none"
          ></ae-map-pin>
        </ng-container>
      </ng-container>
      <span class="zoom-control">
        <ng-container *ngIf="mapOperationMode !== MapOperationMode.VIEW">
          <i
            (click)="save()"
            class="material-icons zoom-icons"
            matTooltip="Save Changes"
            >done</i
          >
          <i
            (click)="clearPins()"
            class="material-icons zoom-icons"
            matTooltip="Delete Pins"
            >delete</i
          >
        </ng-container>
        <i
          (click)="zoomIn()"
          class="material-icons zoom-icons"
          matTooltip="Zoom In"
          >zoom_in</i
        >
        <i
          (click)="zoomOut()"
          class="material-icons zoom-icons"
          matTooltip="Zoom Out"
          >zoom_out</i
        >
        <i
          *ngIf="inspectionList && inspectionList.length"
          [matMenuTriggerFor]="appMenu"
          class="material-icons zoom-icons"
        >
          filter_list
        </i>
      </span>
      <div
        *ngIf="
          mapOperationMode === MapOperationMode.CREATE ||
          mapOperationMode === MapOperationMode.STRICT_CREATE
        "
        class="pin-type-selector"
      >
        <i
          (click)="onDropPinTypeSelected(i)"
          *ngFor="let clr of allowedPinColors; let i = index"
          [ngStyle]="{
            color: clr,
            border: i == dropPinType ? '1px solid' : 'none'
          }"
          class="material-icons"
        >
          room
        </i>
      </div>
    </div>
    <div (click)="nextMap()" *ngIf="maps.length > 1" class="nav-wrapper">
      <i class="material-icons"> skip_next </i>
    </div>
  </div>
</div>

<mat-menu #appMenu="matMenu">
  <button
    (click)="newInspectionSelected.emit(inspection.id)"
    *ngFor="let inspection of inspectionList"
    mat-menu-item
  >
    {{ inspection.title }}
  </button>
</mat-menu>
