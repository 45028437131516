<ae-main-container>
  <ng-template mainContainerBody>
    <ng-container *ngIf="!isMailingListLoading && !!mailingList">
      <div [@contentToggle]="state" class="view-container">
        <div class="header">
          <div class="title">
            <h1 class="page-title">Mailing List Information - View</h1>
          </div>
          <div
            *ngIf="state === VisibilityState.Visible"
            [@footerToggle]="state"
            class="buttons"
          >
            <mat-icon *ngIf="isDialog" color="warn" (click)="close()"
              >clear</mat-icon
            >
            <button
              *ngIf="
                !isDialog &&
                PermissionUtils.hasPermission(
                  PermissionType.TOOL_MAILING_LIST_EDIT
                )
              "
              type="button"
              (click)="edit()"
              class="primary"
              mat-button
            >
              Edit
            </button>
          </div>
        </div>
        <ae-breadcrumb
          *ngIf="!isDialog"
          [breadcrumbs]="breadcrumbs"
        ></ae-breadcrumb>
        <div aeScrollListener class="body">
          <ae-section-container title="Basic Information">
            <div class="elements">
              <p class="label">List Name:</p>
              <p class="info">{{ mailingList.title || "-" }}</p>
            </div>
            <div class="elements">
              <p class="label">Users:</p>
              <p *ngIf="!mailingList?.users?.length" class="info">-</p>
            </div>
            <div *ngIf="mailingList?.users?.length">
              <div class="client-card">
                <mat-chip-list class="chip-list">
                  <mat-chip
                    *ngFor="let user of mailingList.users"
                    color="primary"
                  >
                    {{ user.first_name }} {{ user.last_name }}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </ae-section-container>

          <ae-section-container title="External Emails">
            <div class="elements">
              <p class="label">External Emails:</p>
              <p *ngIf="!mailingList?.external_emails?.length" class="info">
                -
              </p>
            </div>
            <div *ngIf="mailingList?.external_emails?.length">
              <div class="client-card">
                <mat-chip-list class="chip-list">
                  <mat-chip
                    *ngFor="let email of mailingList.external_emails"
                    color="primary"
                  >
                    {{ email }}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </ae-section-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isMailingListLoading">
      <div class="center-spinner">
        <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
      </div>
    </ng-container>
  </ng-template>
  <ng-template *ngIf="!isDialog" mainContainerSideNav></ng-template>
</ae-main-container>
