import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import { DbService } from "@emc-modules/shared/services/db.service";

@Injectable()
export class DbResolver implements Resolve<any> {
  constructor(private _dbService: DbService) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    console.log("[Resolver] DbResolve called", this._dbService.init());
    return await this._dbService.init();
  }
}
