import { Injectable } from "@angular/core";
import { Order, QueryConfig, QueryEntity } from "@datorama/akita";
import {
  InspectionsStore,
  InspectionState
} from "@emc-state/inspection/inspections.store";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { CommonUtils } from "@emc-utils/common-utils";
import { BroadcastEvent } from "../../modules/core/services/broadcaster.service";
import * as _ from "lodash";

@Injectable({ providedIn: "root" })
@QueryConfig({
  sortBy: "inspection_date",
  sortByOrder: Order.DESC
})
export class InspectionsQuery extends QueryEntity<InspectionState> {
  $selectedUserIds = this.select("selectedUserIds");
  $selectedClientIds = this.select("selectedClientIds");
  $selectedProjectIds = this.select("selectedProjectIds");
  $selectedStartDate = this.select("startDate");
  $selectedEndDate = this.select("endDate");
  $searchQuery = this.select("searchQuery");
  $entities = this.select("entities");
  $selectEnergyInspectionTypes = this.select("energyInspectionTypes");
  $selectQAServiceTypes = this.select("QAServiceTypes");

  // Storm Water Inspections

  $isStormWaterSiteInspectionLoading = this.select(
    "isStormWaterSiteInspectionLoading"
  );
  $isStormWaterSiteInspectionLoaded = this.select(
    "isStormWaterSiteInspectionLoaded"
  );
  $stormWaterSiteInspectionIds = this.select("stormWaterSiteInspectionIds");
  $getStormWaterSiteInspectionOffset = this.select(
    "stormWaterSiteInspectionOffset"
  );

  // Safety Inspections

  $isSafetyInspectionLoading = this.select("isSafetyInspectionLoading");
  $isSafetyInspectionLoaded = this.select("isSafetyInspectionLoaded");
  $safetyInspectionIds = this.select("safetyInspectionIds");
  $getSafetyInspectionOffset = this.select("safetyInspectionOffset");

  //Pre Acquisitions Inspections

  $isPreAcquisitionInspectionLoading = this.select(
    "isPreAcquisitionInspectionLoading"
  );
  $isPreAcquisitionInspectionLoaded = this.select(
    "isPreAcquisitionInspectionLoaded"
  );
  $preAcquisitionInspectionIds = this.select("preAcquisitionInspectionIds");
  $getPreAcquisitionInspectionOffset = this.select(
    "preAcquisitionInspectionOffset"
  );

  $isQaInspectionLoading = this.select("isQaInspectionLoading");
  $isQaInspectionLoaded = this.select("isQaInspectionLoaded");
  $qaInspectionIds = this.select("qaInspectionIds");
  $getQaInspectionOffset = this.select("qaInspectionOffset");

  // Energy Inspections

  $isEnergyInspectionLoading = this.select("isEnergyInspectionLoading");
  $isEnergyInspectionLoaded = this.select("isEnergyInspectionLoaded");
  $energyInspectionIds = this.select("energyInspectionIds");
  $getEnergyInspectionOffset = this.select("energyInspectionOffset");

  // Qa/Energy Inspections

  $isQaEnergyInspectionLoading = this.select("isQaEnergyInspectionLoading");
  $isQaEnergyInspectionLoaded = this.select("isQaEnergyInspectionLoaded");
  $qaEnergyInspectionIds = this.select("qaEnergyInspectionIds");
  $getQaEnergyInspectionOffset = this.select("qaEnergyInspectionOffset");

  // Qa/Energy Group Inspections

  $qaEnergyGroupInspection = this.select("qaEnergyGroupInspection");

  $selectEntities = this.select("entities");

  $selectIsLoading = this.select("isLoading");
  $selectIsLoaded = this.select("isLoaded");
  $getOffset = this.select("offset");
  $selectIds = this.select("ids");
  $selectedTab = this.select(store => store.ui.selectedTab);

  constructor(protected store: InspectionsStore) {
    super(store);
  }

  $selectIsPriorFinalizedActionItemsLoading = (id: number) =>
    this.select(state => state.priorFinalizedActionItemsLoading[id] || false);
  $selectIsPriorFinalizedActionItemsLoaded = (id: number) =>
    this.select(state => state.priorFinalizedActionItemsLoaded[id] || false);
  $selectPriorFinalizedActionItems = (id: number) =>
    this.select(state => state.priorFinalizedActionItems[id] || []);

  $selectIsDischargePointsLoading = (projectId: number) =>
    this.select(state => state.dischargePointsLoading[projectId] || false);
  $selectIsDischargePointsLoaded = (projectId: number) =>
    this.select(state => state.dischargePointsLoaded[projectId] || false);
  $selectDischargePoints = (projectId: number) =>
    this.select(state => state.dischargePoints[projectId] || []);

  $selectIsERecepientsLoading = (projectId: number, inspectionType: string) =>
    this.select(
      state =>
        state.eRecipientsLoading[`${projectId}-${inspectionType}`] || false
    );
  $selectIsERecepientsLoaded = (projectId: number, inspectionType: string) =>
    this.select(
      state =>
        state.eRecipientsLoaded[`${projectId}-${inspectionType}`] || false
    );
  $selectERecipients = (projectId: number, inspectionType: string) =>
    this.select(state => state.eRecipients[`${projectId}-${inspectionType}`]);

  $selectIsFullLoading = (id: number) =>
    this.select(state => state.fullLoadingIds.indexOf(id) !== -1);

  $selectIsFullLoaded = (id: number) =>
    this.select(state => state.fullLoadedIds.indexOf(id) !== -1);

  $selectMarkersForProject = (id: number) =>
    this.select(state => state.projectMarkers[id]);
  $selectIsProjectMarkersLoading = (projectId: number) =>
    this.select(state => state.projectMarkersLoading[projectId] || false);
  $selectIsProjectMarkersLoaded = (projectId: number) =>
    this.select(state => state.projectMarkersLoaded[projectId] || false);

  getStormWaterSiteInspections() {
    return combineLatest(
      this.$stormWaterSiteInspectionIds,
      this.$entities
    ).pipe(
      map(([ids, entities]) => {
        const inspections = ids
          .filter(id => !!entities[id])
          .map(id => entities[id]);
        return CommonUtils.sortByKey(inspections, "inspection_date", "desc");
      })
    );
  }

  getPreAcquisitionInspections() {
    return combineLatest(
      this.$preAcquisitionInspectionIds,
      this.$entities
    ).pipe(
      map(([ids, entities]) => {
        const inspections = ids
          .filter(id => !!entities[id])
          .map(id => entities[id]);
        return CommonUtils.sortByKey(inspections, "inspection_date", "desc");
      })
    );
  }

  getSafetyInspections() {
    return combineLatest(this.$safetyInspectionIds, this.$entities).pipe(
      map(([ids, entities]) => {
        const inspections = ids
          .filter(id => !!entities[id])
          .map(id => entities[id]);
        return CommonUtils.sortByKey(inspections, "inspection_date", "desc");
      })
    );
  }

  getQaInspections() {
    return combineLatest(this.$qaInspectionIds, this.$entities).pipe(
      map(([ids, entities]) => {
        const inspections = ids
          .filter(id => !!entities[id])
          .map(id => entities[id]);
        return CommonUtils.sortByKey(inspections, "inspection_date", "desc");
      })
    );
  }

  getEnergyInspections() {
    return combineLatest(this.$energyInspectionIds, this.$entities).pipe(
      map(([ids, entities]) => {
        const inspections = ids
          .filter(id => !!entities[id])
          .map(id => entities[id]);
        return CommonUtils.sortByKey(inspections, "inspection_date", "desc");
      })
    );
  }

  getQaEnergyInspections() {
    return combineLatest(this.$qaEnergyInspectionIds, this.$entities).pipe(
      map(([ids, entities]) => {
        const inspections = ids
          .filter(id => !!entities[id])
          .map(id => entities[id]);
        return CommonUtils.sortByKey(inspections, "inspection_date", "desc");
      })
    );
  }

  getQaEnergyChildrenInspections(key: string) {
    return combineLatest(this.$getqaEnergyGroupIds(key), this.$entities).pipe(
      map(([ids, entities]) => {
        const inspections = ((ids as []) || [])
          .filter(id => !!entities[id])
          .map(id => entities[id]);
        return CommonUtils.sortByKey(inspections, "inspection_date", "desc");
      })
    );
  }

  // This code ahead of this line is not of proper code practice because of client urgency and group by tweaks
  $getqaEnergyGroupLoading(key) {
    let subject$ = new BehaviorSubject<boolean>(false);
    this.$qaEnergyGroupInspection.pipe().subscribe(qaEnergyGroup => {
      subject$.next(!!qaEnergyGroup[key]?.loading);
    });
    return subject$.asObservable();
  }

  $getqaEnergyGroupLoaded(key) {
    let subject$ = new BehaviorSubject<boolean>(false);
    this.$qaEnergyGroupInspection.pipe().subscribe(qaEnergyGroup => {
      subject$.next(!!qaEnergyGroup[key]?.loaded);
    });
    return subject$.asObservable();
  }

  $getqaEnergyGroupOffset(key) {
    let subject$ = new BehaviorSubject<number>(0);
    this.$qaEnergyGroupInspection.pipe().subscribe(qaEnergyGroup => {
      subject$.next(qaEnergyGroup[key]?.offset || 0);
    });
    return subject$.asObservable();
  }

  $getqaEnergyGroupIds(key) {
    let subject$ = new BehaviorSubject<number[]>([]);
    this.$qaEnergyGroupInspection.pipe().subscribe(qaEnergyGroup => {
      subject$.next(qaEnergyGroup[key]?.ids || []);
    });
    return subject$.asObservable();
  }
}
