<div class="instructions">
  <p class="instruction-heading">Instruction</p>
  <p class="instruction-content">(Separate email by , (comma))</p>
</div>
<div class="elements">
  <p class="label !tw-flex-grow-0 !tw-flex-shrink-0">{{ this.placeholder }}</p>
  <div class="email-field !tw-w-full !tw-flex-grow-0">
    <mat-form-field
      floatLabel="never"
      class="!tw-w-full !tw-flex-grow-0 !tw-flex-shrink-0 !tw-max-w-full"
    >
      <textarea
        [(ngModel)]="value"
        (ngModelChange)="onValueChange($event)"
        matInput
        [placeholder]="placeholder"
        type="email"
      >
      </textarea>
      <mat-error>Please enter a valid Email.</mat-error>
    </mat-form-field>
  </div>
</div>
