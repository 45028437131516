import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "ae-map-dialogue",
  templateUrl: "./map-dialogue.component.html",
  styleUrls: ["./map-dialogue.component.scss"]
})
export class MapDialogueComponent implements OnInit {
  onSubmit = new EventEmitter<any>();
  titleControl = new FormControl(null);
  commentControl = new FormControl(null);

  constructor(
    public dialogRef: MatDialogRef<MapDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titleControl.patchValue(data?.title, { emitEvent: false });
    this.commentControl.patchValue(data?.comment, { emitEvent: false });
    this.data.isViewOnly
      ? this.commentControl.disable()
      : console.log("NOT VIEW MODE");
  }

  ngOnInit() {}

  closePopup() {
    this.dialogRef.close();
  }

  submit() {
    this.titleControl.markAsTouched();
    this.commentControl.markAsTouched();
    if (this.titleControl.invalid || this.commentControl.invalid) {
      return;
    } else {
      this.onSubmit.emit({
        title: this.titleControl.value,
        comment: this.commentControl.value
      });
      this.dialogRef.close();
    }
  }
}
