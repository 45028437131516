import { Injectable } from "@angular/core";
import { ID } from "@datorama/akita";
import { ApiService } from "@emc-modules/core/services/api.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ListResponse } from "../../../../../responses/list.response";
import { ShowResponse } from "../../../../../responses/show.response";
import {
  MailingListCompact,
  MailingListFull
} from "@emc-models/entities/mailing-list.model";

@Injectable({ providedIn: "root" })
export class MailingListsApiService {
  constructor(private apiService: ApiService) {}

  listMailingLists(): Observable<MailingListCompact[]> {
    return this.apiService
      .get<ListResponse<MailingListCompact>>("mailing-lists", true)
      .pipe(map(res => res.data));
  }

  addMailingList(data: any): Observable<MailingListFull> {
    return this.apiService
      .post<ShowResponse<MailingListFull>>("mailing-lists", true, data)
      .pipe(map(res => res.data));
  }

  showMailingList(mailingListId: ID): Observable<MailingListFull> {
    return this.apiService
      .get<
        ShowResponse<MailingListFull>
      >(`mailing-lists/${mailingListId}`, true)
      .pipe(map(res => res.data));
  }

  deleteMailingList(mailingListId: ID): Observable<void> {
    return this.apiService.delete(`mailing-lists/${mailingListId}`, true);
  }

  updateMailingList(mailingListId: ID, data: any): Observable<MailingListFull> {
    return this.apiService
      .put<
        ShowResponse<MailingListFull>
      >(`mailing-lists/${mailingListId}`, true, data)
      .pipe(map(res => res.data));
  }
}
