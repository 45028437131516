import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Permission } from "@emc-models/entities/permission.model";

export interface PermissionsState extends EntityState<Permission> {
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  loading: false,
  loaded: false
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "permissions" })
export class PermissionsStore extends EntityStore<
  PermissionsState,
  Permission
> {
  constructor() {
    super(initialState);
  }
}
