import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { Unsubscribe } from "../../../../unsubscribe";
import { Router } from "@angular/router";
import { InspectionsService } from "@emc-modules/inspections/services/inspections.service";
import { Inspection } from "@emc-models/entities/inspection.model";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { PermissionType } from "src/app/enums/permission-type.enum";
import { UserFull } from "@emc-models/entities/user.model";
import { ToastrService } from "ngx-toastr";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import { DialogService } from "@emc-modules/shared/services/dialog.service";
import { GenericConfirmationComponent } from "@emc-modules/shared/components/generic-confirmation/generic-confirmation.component";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { CommonUtils } from "@emc-utils/common-utils";
import { filter, takeUntil } from "rxjs/operators";
import { QuestionType } from "../../../../enums/question-type.enum";
import { ERecipientsDialogBoxComponent } from "@emc-modules/inspections/e-recepients-dialog-box/e-recipients-dialog-box.component";
import { SiteMap } from "@emc-models/entities/site-map.model";
import {
  MapData,
  MapOperationMode
} from "@emc-modules/shared/components/map-engine/map-engine.component";
import { FullScreenMapComponent } from "@emc-modules/inspections/components/dialogues/full-screen-map/full-screen-map.component";
import { OptionsService } from "@emc-services/options.service";
import { ResponsiveActionItem } from "@emc-models/entities/responsive-action-item.model";
import * as moment from "moment";
import * as _ from "lodash";
import { ResAcItemCardDialogComponent } from "@emc-modules/projects/components/responsive-action-item-card-dialog-box/res-ac-item-card-dialog.component";
import { Subscription } from "rxjs";
import { AddNewInspectionComponent } from "../add-new-inspection/add-new-inspection.component";
import { RainLogCardDialogComponent } from "../../../rain-log/components/rain-log-card-dialog-box/rain-log-card-dialog.component";

@Component({
  selector: "ae-inspection-card",
  templateUrl: "./inspection-card.component.html",
  styleUrls: ["./inspection-card.component.scss"]
})
export class InspectionCardComponent
  extends Unsubscribe
  implements OnInit, AfterContentInit, AfterViewInit
{
  @Input() selected = false;
  @Input() disabled = false;
  @Input() showButtons = true;
  siteMaps: SiteMap[] = [];
  siteMapsLoading: boolean;
  mapsLoading: boolean;
  markersLoading: boolean;
  PermissionUtils = PermissionUtils;
  PermissionType = PermissionType;
  CommonUtils = CommonUtils;
  QuestionType = QuestionType;
  authUser: UserFull;
  deleting: boolean;
  saving: boolean;
  isBeazerHomesUsa: boolean;
  activityPhase: string;
  isStormWater: boolean;
  isClient: boolean;
  canCertify: boolean;
  inspectionList: { title: string; id: number }[] = [];
  actionItems: ResponsiveActionItem[] = [];
  siteMaps$: Subscription;
  siteMapsLoading$: Subscription;
  markers$: Subscription;
  markersLoading$: Subscription;
  @Output() selectedChanged = new EventEmitter<boolean>();
  @ViewChild("menu") menu;
  childs: number = 0;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authManager: AuthManager,
    private dialogService: DialogService,
    private optionService: OptionsService,
    private inspectionService: InspectionsService,
    private _dialog: MatDialog,
    private detectRef: ChangeDetectorRef
  ) {
    super();
  }

  private _inspection: Inspection;

  get inspection(): Inspection {
    return this._inspection;
  }

  @Input() set inspection(inspection: Inspection) {
    this._inspection = inspection;
    if (inspection) {
      this.isBeazerHomesUsa = inspection.client_id === 1217;
      this.getActivityPhase();
      this.isStormWater =
        [
          QuestionType.STORM_WATER,
          QuestionType.PULTE,
          QuestionType.MARYLAND
        ].indexOf(this.inspection.type) !== -1;
    }
  }

  ngOnInit() {
    console.log("show", this.showButtons);
    this.authManager
      .getLoggedInUser()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(user => {
        this.authUser = user;
        this.isClient = user.is_client;
        this.canCertify =
          PermissionUtils.hasPermission(
            PermissionType.INSPECTION_SSWR_CERTIFY
          ) && user.is_client;
      });
  }

  ngAfterContentInit(): void {
    this.detectRef.markForCheck();
  }

  ngAfterViewInit() {
    this.childs = this.menu.items.length;
    this.detectRef.detectChanges();
  }

  getActivityPhase() {
    if (
      this.inspection?.safetyInspection?.safety_inspection_template_id === 7
    ) {
      this.activityPhase = "Land Development";
    } else {
      this.activityPhase = "Home Building";
    }
  }

  setUpPins() {
    if (this.markersLoading$ && !this.markersLoading$.closed) {
      this.markersLoading$.unsubscribe();
    }

    this.markersLoading$ = this.inspectionService
      .getProjectMarkersLoading(this.inspection.project_id)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(l => (this.markersLoading = l));

    if (this.markers$ && !this.markers$.closed) {
      this.markers$.unsubscribe();
    }

    this.markers$ = this.inspectionService
      .getProjectMarkers(this.inspection.project_id)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(pins => {
        this.actionItems = pins;
        this.inspectionList = _.uniqBy(
          pins
            .filter(p => !!p.inspection)
            .map(pin => {
              return {
                id: pin.inspection_id,
                title: moment(pin.inspection.inspection_date).format("LL")
              };
            }),
          item => item.id
        );
        this.onMapsClicked();
        this.mapsLoading = false;
      });
  }

  setUpSiteMaps() {
    if (this.siteMaps$ && !this.siteMaps$.closed) {
      this.siteMaps$.unsubscribe();
    }

    this.siteMaps$ = this.optionService
      .getSiteMapsForProjectId(this.inspection.project_id)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(maps => {
        this.siteMaps = maps;
        this.setUpPins();
      });

    if (this.siteMapsLoading$ && !this.siteMapsLoading$.closed) {
      this.siteMapsLoading$.unsubscribe();
    }

    this.siteMapsLoading$ = this.optionService
      .getSiteMapsLoading()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(l => (this.siteMapsLoading = l));
  }

  onDelete() {
    this.dialogService
      .open(GenericConfirmationComponent, {
        height: "200px",
        data: {
          title: `Delete Inspection`,
          description: `Are you sure you want to delete this inspection?`
        }
      })
      .afterClosed()
      .pipe(filter(d => !!d?.yesClicked))
      .subscribe(v => {
        this.deleting = true;
        this.inspectionService.deleteInspection(this.inspection.id).subscribe(
          () => {
            this.deleting = false;
            this.toastr.success("Inspection Successfully Deleted");
          },
          () => (this.deleting = false)
        );
      });
  }

  openRecepients() {
    this.dialogService.open(ERecipientsDialogBoxComponent, {
      minHeight: "200px",
      width: "400px",
      maxWidth: "90vw",
      data: {
        title: "E-Recepients",
        projectId: this.inspection.project_id,
        inspectionType: this.inspection.type,
        projectName: this.inspection.project.title
      }
    });
  }

  onChange(event: MatCheckboxChange) {
    this.selectedChanged.emit(event.checked);
  }

  onClick(inspection: Inspection, action: string) {
    if (
      [
        QuestionType.STORM_WATER,
        QuestionType.MARYLAND,
        QuestionType.PULTE
      ].includes(inspection.type)
    ) {
      this.router.navigate([
        "dashboard/inspections/site-inspections",
        inspection.id,
        action
      ]);
    } else if (inspection.type === QuestionType.SAFETY) {
      this.router.navigate([
        "dashboard/inspections/safety-inspections",
        inspection.id,
        action
      ]);
    } else if (inspection.type === QuestionType.ENERGY) {
      this.router.navigate([
        "dashboard/inspections/energy-inspections",
        inspection.id,
        action
      ]);
    } else if (inspection.type === QuestionType.PRE_ACQUISITION) {
      this.router.navigate([
        "dashboard/inspections/pre-acquisition-inspections",
        inspection.id,
        action
      ]);
    } else {
      this.router.navigate([
        "dashboard/inspections/qa-inspections",
        inspection.id,
        action
      ]);
    }
  }

  onMapsClicked(inspectionId = null) {
    const items = this.actionItems.filter(
      a =>
        a.inspection_id === (inspectionId ? inspectionId : this.inspection.id)
    );
    const mapData = this.getParsedData(items);

    console.log("map data", this.inspection.project_id, mapData);

    if (!mapData || !mapData.length) {
      return;
    }

    const refCategory = this._dialog.open(FullScreenMapComponent, {
      width: "99vw",
      height: "99vh",
      data: {
        maps: mapData,
        mapOperationMode: MapOperationMode.VIEW,
        inspectionList: this.inspectionList
      }
    });
    refCategory.componentInstance.inspectionChanged
      .pipe(takeUntil(this._destroyed$))
      .subscribe((newInspectionId: number) => {
        refCategory.close();
        this.onMapsClicked(newInspectionId);
        return;
      });
  }

  openMaps() {
    if (this.showButtons) {
      this.mapsLoading = true;
      console.log("inside api call");
      this.setUpSiteMaps();
    }
  }

  openResponsiveActionItems() {
    this.dialogService.open(ResAcItemCardDialogComponent, {
      height: "600px",
      maxWidth: "600px",
      width: "100vw",
      data: {
        title: ` Responsive Action Items`,
        projectId: this.inspection.project_id,
        inspectionId: this.inspection.id,
        is_pulte: this.inspection.type === QuestionType.PULTE
      }
    });
  }

  addRainLog() {
    console.log("add rain log", this.inspection);
    this.dialogService.open(RainLogCardDialogComponent, {
      maxHeight: "600px",
      maxWidth: "600px",
      width: "100vw",
      data: {
        title: ` Rain Log`,
        projectId: this.inspection.project_id,
        clientId: this.inspection.client_id
      }
    });
  }

  getParsedData(pinsData: any[]): MapData[] {
    const data: MapData[] = [];
    if (this.siteMaps.length) {
      this.siteMaps.forEach(map => {
        const temp: MapData = { mapImageUrl: map.image_url, uId: map.id };
        for (const val of pinsData) {
          if (val.site_map_id === map.id) {
            temp.pins && temp.pins.length
              ? (temp.pins = [
                  ...temp.pins,
                  {
                    x: val.coordinates.x,
                    y: val.coordinates.y,
                    type: val.coordinates.type,
                    title: val.title,
                    comment: val.comment
                  }
                ])
              : (temp.pins = [
                  {
                    x: val.coordinates.x,
                    y: val.coordinates.y,
                    type: val.coordinates.type,
                    title: val.title,
                    comment: val.comment
                  }
                ]);
          }
        }
        data.push(temp);
      });
    }
    return data;
  }

  followup() {
    this.saving = true;
    this.inspectionService.followUpInspection(this.inspection.id).subscribe(
      (inspection: Inspection) => {
        this.toastr.success("QA Inspection Follow Up Created");
        this.router.navigate([
          "/dashboard/inspections/qa-inspections",
          inspection.id,
          "edit"
        ]);
        this.saving = false;
      },
      () => (this.saving = false)
    );
  }

  export(format: "pdf" | "docx") {
    this.saving = true;
    this.inspectionService
      .exportInspection(this.inspection.id, format)
      .subscribe(
        () => (this.saving = false),
        () => (this.saving = false)
      );
  }

  remail() {
    this.saving = true;
    this.inspectionService.remail(this.inspection.id).subscribe(
      () => (this.saving = false),
      () => (this.saving = false)
    );
  }

  addNew(inspection: Inspection) {
    this.dialogService.open(AddNewInspectionComponent, {
      width: "700px",
      data: {
        inspectionId: inspection.id
      }
    });
  }
}
