<mat-form-field class="search-bar" floatLabel="never">
  <mat-label>Search</mat-label>
  <input
    [formControl]="control"
    [placeholder]="placeholder"
    matInput
    type="text"
  />
  <mat-icon *ngIf="!control.value" color="primary" matSuffix>search</mat-icon>
  <mat-icon
    (click)="clearSearchField()"
    *ngIf="control.value"
    color="warn"
    matSuffix
    >clear</mat-icon
  >
</mat-form-field>
