import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ResponsiveActionItem } from "@emc-models/entities/responsive-action-item.model";
import { takeUntil } from "rxjs/operators";
import { InspectionsService } from "@emc-modules/inspections/services/inspections.service";
import { Unsubscribe } from "../../../../unsubscribe";
import { DialogService } from "@emc-modules/shared/services/dialog.service";
import { PermissionUtils } from "@emc-utils/permission.utils";
import { PermissionType } from "../../../../enums/permission-type.enum";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ISelectOption } from "@emc-modules/shared/components/emc-search-field/emc-search-field.component";
import { OptionsService } from "@emc-services/options.service";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { QuestionType } from "../../../../enums/question-type.enum";

@Component({
  selector: "ae-rain-log-card-dialog",
  templateUrl: "./res-ac-item-card-dialog.component.html",
  styleUrls: ["./res-ac-item-card-dialog.component.scss"]
})
export class ResAcItemCardDialogComponent
  extends Unsubscribe
  implements OnInit
{
  inspectionType = QuestionType.STORM_WATER;
  title = "Open Action Item Card";
  projectId: number;
  inspectionId: number;
  items: ResponsiveActionItem[] = [];
  priorActionItemsLoading: boolean;
  selectedItems: number[] = [];
  isAllSelected = false;
  controls = {
    completed_by: new FormControl(null, [Validators.required]),
    completed_on: new FormControl(new Date(), [Validators.required]),
    responsive_actions: new FormControl([], [Validators.required])
  };
  form = new FormGroup(this.controls);
  userOptions: ISelectOption[] = [];
  updating = false;
  hasSignOffPermission = PermissionUtils.hasPermission(
    PermissionType.INSPECTION_SIGN_OFF_RESPONSIVE_ACTION
  );
  hasAddEditPermission = PermissionUtils.hasPermission(
    PermissionType.INSPECTION_ADD_EDIT_RESPONSIVE_ACTION
  );

  constructor(
    private dialogRef: MatDialogRef<ResAcItemCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      projectId: number;
      inspectionId: number;
      is_pulte: boolean;
    },
    private inspectionsService: InspectionsService,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private authManager: AuthManager,
    private option: OptionsService
  ) {
    super();
    if (data) {
      this.title = data.title;
      this.projectId = data.projectId;
      this.inspectionId = data.inspectionId;
      if (data.is_pulte) {
        this.inspectionType = QuestionType.PULTE;
      }
    }
  }

  ngOnInit() {
    this.priorActionItemsLoading = true;
    this.inspectionsService
      .getOpenActionItems(this.projectId, this.inspectionId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(items => {
        this.items = items;
        this.priorActionItemsLoading = false;
      });
    this.option
      .getUsers(this.inspectionType === QuestionType.PULTE)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(u => {
        if (u.length) {
          this.userOptions = u.map(user => {
            return {
              title:
                user.first_name +
                " " +
                user.last_name +
                " (" +
                user?.role?.title +
                ")",
              value: user.id
            };
          });
        }
      });
    this.authManager
      .getLoggedInUser()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(user => {
        this.controls.completed_by.setValue(user.id);
        if (user.is_client) {
          this.userOptions.push({
            title: user.first_name + " " + user.last_name,
            value: user.id
          });
        }
      });
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  update() {
    this.updating = true;
    const ids = _.uniq(this.selectedItems);
    const data: any = {
      responsive_action_item_ids: ids
    };

    if (this.hasSignOffPermission) {
      data.completed_by = this.form.value.completed_by;
      data.completed_on = this.controls.completed_on.value
        ? moment(this.controls.completed_on.value).format("YYYY-MM-DD")
        : null;
    }

    this.inspectionsService.bulkUpdateResponsiveActionItem(ids, data).subscribe(
      value => {
        this.updating = false;
        this.inspectionsService.isActionUpdated.next();
        this.close();
        this.toastr.success("Action Items Successfully Updated");
      },
      error => {
        this.updating = false;
        console.log("error", error);
      }
    );
  }

  downloadPdf() {
    this.inspectionsService
      .exportOpenActionItems(this.projectId, this.inspectionId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(items => {
        this.toastr.success(
          "Your Open Items report has been successfully downloaded"
        );
      });
  }

  getIsSelected(id) {
    return this.selectedItems.includes(id);
  }

  selectedItemChanged(selected: boolean, id: number) {
    if (selected) {
      this.selectedItems.push(id);
    } else {
      this.selectedItems = this.selectedItems.filter(v => v !== id);
      this.isAllSelected = false;
    }
  }
}
