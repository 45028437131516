import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { resetStores } from "@datorama/akita";
import { AUTH_TOKEN } from "@emc-utils/constants";

@Injectable()
export class AppService {
  constructor(private router: Router) {}

  logout() {
    localStorage.removeItem(AUTH_TOKEN);
    resetStores({ exclude: [] });
    this.router.navigate(["/auth/login"]);
  }
}
