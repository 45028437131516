<ae-main-container [showToggleButton]="false">
  <ng-template mainContainerBody>
    <div *ngIf="isLoading || saving" class="full-screen-mat-spinner">
      <mat-spinner [diameter]="50" strokeWidth="3"></mat-spinner>
    </div>
    <div
      *ngIf="!isLoading || !saving"
      [@contentToggle]="visibilityState"
      class="container"
    >
      <div class="header">
        <div class="title">
          <h1 *ngIf="!!state" class="page-title">
            {{ !!templateId ? "Edit" : "Add" }} {{ state.title }} Template
          </h1>
          <h1 *ngIf="!state && !!templateId" class="page-title">
            Edit {{ template?.title }} Template
          </h1>
          <h1 *ngIf="!templateId && client" class="page-title">
            Add {{ client.name }} Template
          </h1>
          <h1 *ngIf="!templateId && project" class="page-title">
            Add {{ project.title }} Template
          </h1>
        </div>
        <div
          *ngIf="visibilityState === VisibilityState.Visible"
          [@footerToggle]="visibilityState"
          class="buttons"
        >
          <button
            (click)="navigateBack()"
            color="warn"
            mat-button
            type="button"
          >
            Cancel
          </button>
          <button
            (click)="onSubmit()"
            class="primary"
            mat-flat-button
            type="button"
          >
            Save Changes
          </button>
        </div>
      </div>
      <ae-breadcrumb
        *ngIf="!isDialog"
        [breadcrumbs]="breadcrumbs"
      ></ae-breadcrumb>
      <div aeScrollListener class="body">
        <div class="elements">
          <p aeMarkAsterisk class="label">Title</p>
          <mat-form-field floatLabel="never">
            <input
              [formControl]="controls.title"
              matInput
              placeholder="Enter Title"
              type="text"
            />
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="elements">
          <p aeMarkAsterisk class="label">Type</p>
          <mat-form-field floatLabel="never">
            <mat-select
              [formControl]="controls.type"
              placeholder="Question Type"
            >
              <mat-option *ngFor="let type of questionTypes" [value]="type">
                {{ type | unslugify | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="elements">
          <mat-checkbox [formControl]="controls.is_active" color="primary"
            >Active</mat-checkbox
          >
        </div>

        <div class="dynamic-question">
          <ae-dynamic-question
            [group]="controls.dynamic_questions"
            [templateId]="templateId"
            (delete)="deleteDynamicQuestion($event)"
            [length]="controls?.dynamic_questions?.length"
            [type]="controls.type.value"
          >
          </ae-dynamic-question>
        </div>

        <div class="question-suggestions">
          <ae-question-suggestion
            (clone)="cloneQuestion(i)"
            (delete)="deleteQuestion(i, $event)"
            (sortOrderChange)="changeSortOrder($event, i)"
            *ngFor="
              let group of controls.question_suggestions.controls;
              let i = index
            "
            [group]="group"
            [templateId]="templateId"
            [length]="controls?.question_suggestions?.length"
            [type]="controls.type.value"
          >
          </ae-question-suggestion>
          <div (click)="addQuestion()" class="add-question emc-box">
            <span>Add Question</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template *ngIf="!isDialog" mainContainerSideNav> </ng-template>
</ae-main-container>
