import { Component, OnInit } from "@angular/core";
import { AuthManager } from "@emc-modules/core/managers/auth.manager";
import { Unsubscribe } from "../../../../unsubscribe";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonUtils } from "@emc-utils/common-utils";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "ae-signature-register",
  templateUrl: "./signature-register.component.html",
  styleUrls: ["./signature-register.component.scss"]
})
export class SignatureRegisterComponent extends Unsubscribe implements OnInit {
  controls = {
    signature: new FormControl(null, Validators.required)
  };

  form = new FormGroup(this.controls);
  saving: boolean;

  constructor(
    private authManager: AuthManager,
    private dialogRef: MatDialogRef<SignatureRegisterComponent>
  ) {
    super();
  }

  ngOnInit() {}

  submit() {
    if (this.form.invalid) {
      CommonUtils.markControlsAsTouched(this.form);
      return;
    }
    let data = this.form.value;
    // sending signature only if it is changed
    delete data.signature;

    const signature = CommonUtils.dataURItoFile(
      this.form.get("signature").value
    );

    if (!!signature) {
      data = { ...data, signature };
    }

    this.saving = true;
    this.authManager.updateMe(CommonUtils.getFormData(data)).subscribe(
      () => this.close(),
      () => (this.saving = false)
    );
  }

  close() {
    this.dialogRef.close();
  }
}
