export const INSPECTION_SCHEMA = {
  version: 0,
  type: "object",
  additionalProperty: true,
  properties: {
    id: {
      type: "string",
      primary: true
    },
    type: {
      type: "string"
    },
    data: {
      type: "object"
    },
    date: {
      type: "string"
    }
  }
};
