import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ISelectOption } from "@emc-modules/shared/components/emc-search-field/emc-search-field.component";
import { OptionsService } from "@emc-services/options.service";
import { OptionsQuery } from "@emc-state/option/options.query";
import { Unsubscribe } from "../../../../unsubscribe";
import { takeUntil } from "rxjs/operators";
import { CommonUtils } from "@emc-utils/common-utils";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from "@angular/material/dialog";
import { ResponsiveActionItem } from "@emc-models/entities/responsive-action-item.model";
import { InspectionsService } from "@emc-modules/inspections/services/inspections.service";
import { SiteMap } from "@emc-models/entities/site-map.model";
import { ToastrService } from "ngx-toastr";
import {
  MapData,
  MapOperationMode
} from "@emc-modules/shared/components/map-engine/map-engine.component";
import { FullScreenMapComponent } from "@emc-modules/inspections/components/dialogues/full-screen-map/full-screen-map.component";
import { Config } from "@emc-models/entities/config.model";

@Component({
  selector: "ae-add-edit-action-item",
  templateUrl: "./add-edit-action-item.component.html",
  styleUrls: ["./add-edit-action-item.component.scss"]
})
export class AddEditActionItemComponent extends Unsubscribe implements OnInit {
  itemOptions: ISelectOption[] = [];
  userOptions: ISelectOption[] = [];
  usersLoading: boolean;
  siteMaps: SiteMap[] = [];
  config: Config;
  // saving: boolean;
  item: ResponsiveActionItem;
  controls = {
    title: new FormControl(null),
    comment: new FormControl(null),
    assigned_to: new FormControl(null),
    coordinates: new FormControl(null),
    site_map_id: new FormControl(null)
  };
  group = new FormGroup(this.controls);

  constructor(
    private optionsService: OptionsService,
    private optionsQuery: OptionsQuery,
    private inspectionsService: InspectionsService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<AddEditActionItemComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _dialog: MatDialog
  ) {
    super();
    if (data.actionItem) {
      this.item = data.actionItem;
      this.config = data?.actionItem?.question?.config;
      this.itemOptions =
        data.actionItem.question.config.responsive_action_item_suggestions.map(
          item => {
            return {
              title: item.title,
              value: item.title
            };
          }
        );
      this.initialiseForm(data.actionItem);
    }

    if (data.siteMaps) {
      this.siteMaps = data.siteMaps;
    }
  }

  constructSiteMapData(): MapData[] {
    const mapData = [];
    this.siteMaps.forEach(map => {
      const ev: MapData = {
        mapImageUrl: map.image_url,
        uId: map.id,
        defaultTitle: this.group.controls.title.value,
        defaultComment: this.group.controls.comment.value
      };
      if (this.group.value.site_map_id === map.id) {
        ev.pins = [
          {
            x: this.group.value.coordinates.x,
            y: this.group.value.coordinates.y,
            type: this.group.value.coordinates.type,
            title: this.group.controls.title.value,
            comment: this.group.value.comment
          }
        ];
      }
      mapData.push(ev);
    });
    return mapData;
  }

  ngOnInit(): void {
    this.optionsService
      .getUsers()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(u => {
        this.userOptions = u.map(user => {
          return {
            title: user.first_name + " " + user.last_name,
            value: user.id
          };
        });
      });

    this.optionsQuery.$selectUsersLoading
      .pipe(takeUntil(this._destroyed$))
      .subscribe(l => (this.usersLoading = l));
  }

  onOpenMapClicked() {
    if (!this.siteMaps.length) {
      this.toastr.error("No Site Maps Present");
      return;
    }

    const refCategory = this._dialog.open(FullScreenMapComponent, {
      width: "99vw",
      height: "99vh",
      data: {
        maps: this.constructSiteMapData(),
        mapOperationMode: MapOperationMode.STRICT_CREATE
      }
    });
    refCategory.componentInstance.mapUpdated
      .pipe()
      .subscribe((val: MapData) => {
        if (!val || !val.pins || !val.pins.length) {
          this.group.controls.coordinates.setValue(null);
          this.group.controls.comment.setValue(null);
          this.group.controls.site_map_id.setValue(null);
        } else {
          this.group.controls.coordinates.setValue({
            x: val.pins[0].x,
            y: val.pins[0].y,
            type: val.pins[0].type
          });
          this.group.controls.comment.setValue(val.pins[0].comment);
          this.group.controls.site_map_id.setValue(val.uId);
        }
      });
  }

  save() {
    if (this.group.invalid) {
      CommonUtils.markControlsAsTouched(this.group);
      console.log("group", this.group);
      return;
    }
    this.close(this.group.value);
  }

  close(data?: ResponsiveActionItem) {
    this.dialogRef.close(data);
  }

  private initialiseForm(item: ResponsiveActionItem) {
    this.group.patchValue({
      title: item.title,
      assigned_to: item.assigned_to,
      comment: item.comment,
      coordinates: item.coordinates,
      site_map_id: item.site_map_id
    });
  }
}
