<div class="container">
  <mat-toolbar color="primary" class="header">
    <span>Edit</span>
    <mat-icon (click)="onClose()">clear</mat-icon>
  </mat-toolbar>
  <form (ngSubmit)="onSave()" [formGroup]="editForm" class="edit-form">
    <div class="body-elements">
      <mat-form-field floatLabel="never">
        <input formControlName="title" matInput placeholder="" type="text" />
        <mat-error>This field is required</mat-error>
      </mat-form-field>
    </div>
    <div class="action-container">
      <mat-spinner *ngIf="saving" [diameter]="20" strokeWidth="2"></mat-spinner>
      <button mat-button class="primary" [disabled]="saving">Save</button>
    </div>
  </form>
</div>
