import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ae-dr-horton",
  templateUrl: "./stanley-martin-safety.component.html",
  styleUrls: ["./stanley-martin-safety.component.scss"]
})
export class StanleyMartinSafetyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
