import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthManager } from "../managers/auth.manager";
import { combineLatest } from "rxjs";
import { take } from "rxjs/operators";
import { Location } from "@angular/common";
import { AppPath } from "../../../app.path";
import { directAccessUrlsWithoutLogin } from "../../../utils/common-utils";
import * as _ from "lodash";

@Injectable()
export class BootstrapGuard implements CanActivate {
  constructor(
    public router: Router,
    private authManager: AuthManager,
    private location: Location
  ) {}

  canActivate(): boolean {
    if (!this.authManager.hasAuthToken()) {
      if (this.location.path().startsWith("/" + AppPath.auth)) {
        this.router.navigateByUrl(this.location.path());
      } else if (this.location.path() === "/" + AppPath.privacy_policy) {
        this.router.navigate(["/" + AppPath.privacy_policy + "/index.html"]);
      } else if (
        _.includes(directAccessUrlsWithoutLogin, this.location.path())
      ) {
        this.router.navigateByUrl(this.location.path());
      } else {
        this.router.navigate(["/auth/login"]);
      }
      return false;
    }

    const loaded$ = combineLatest(
      this.authManager.isLoaded(),
      this.authManager.isBootstrapped(),
      (userLoaded: boolean, bootstrapped: boolean) => ({
        userLoaded,
        bootstrapped
      })
    );

    let isBootstrapped: boolean;

    loaded$.subscribe((data: any) => {
      if (data.userLoaded && data.bootstrapped) {
        this.authManager
          .getReturnUrl()
          .pipe(take(1))
          .subscribe(url => this.router.navigate([url]));
      }
      isBootstrapped = data.bootstrapped;
      return !data.bootstrapped;
    });
    return !isBootstrapped;
  }
}
