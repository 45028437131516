<div class="text-wrapper">
  <div class="title" data-content="404">404</div>

  <div
    class="subtitle"
    data-content="Oops, the page you're looking for doesn't exist"
  >
    Oops, the page you're looking for doesn't exist.
  </div>
</div>
