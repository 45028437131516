import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { environment } from "@emc-environment/environment";
import { CoreModule } from "@emc-modules/core/core.module";
import { SideNavContainerComponent } from "@emc-modules/shared/components/side-nav-conatiner/side-nav-container.component";
import { SharedModule } from "@emc-modules/shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BootstrapComponent } from "@emc-modules/core/components/bootstrap/bootstrap.component";
import { ToastrModule } from "ngx-toastr";
import { ServiceWorkerModule } from "@angular/service-worker";
import { DrHortonComponent } from "./modules/dashboard/components/dr-horton/dr-horton.component";

@NgModule({
  declarations: [
    AppComponent,
    BootstrapComponent,
    SideNavContainerComponent,
    DrHortonComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      autoDismiss: true,
      preventDuplicates: true
    }),
    CoreModule,
    SharedModule,
    AppRoutingModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
