import { Injectable } from "@angular/core";
import { UserFull } from "@emc-models/entities/user.model";
import { ApiService } from "@emc-modules/core/services/api.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LoginResponse } from "../../../responses/login.response";
import { ShowResponse } from "../../../responses/show.response";
import { BroadcasterService } from "@emc-modules/core/services/broadcaster.service";
import { environment } from "@emc-environment/environment";

@Injectable()
export class AuthService {
  constructor(
    private apiService: ApiService,
    private broadcaster: BroadcasterService
  ) {}

  login(credentials: {
    email: string;
    password: string;
    remember_me: boolean;
  }): Observable<any> {
    (credentials as any).version = environment.version;
    return this.apiService
      .post<LoginResponse>("login", false, credentials)
      .pipe(
        map(res => {
          return {
            token: res.token,
            user: res.user
          };
        })
      );
  }

  signup(data: any): Observable<UserFull> {
    return this.apiService
      .post<ShowResponse<UserFull>>("signup", false, data)
      .pipe(map(res => res.data));
  }

  forgotPassword(email): Observable<any> {
    return this.apiService.post("forgot-password", false, { email });
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.apiService.post("reset-password", false, { token, password });
  }

  changePassword(data: {
    old_password: string;
    new_password: string;
  }): Observable<UserFull> {
    return this.apiService.post("change-password", true, data);
  }

  getLoggedInUser(): Observable<UserFull> {
    return this.apiService
      .get<ShowResponse<UserFull>>("me", true)
      .pipe(map(res => res.data));
  }

  updateLoggedInUser(data: any): Observable<UserFull> {
    return this.apiService
      .put<ShowResponse<UserFull>>("me?type=user-signature", true, data)
      .pipe(map(res => res.data));
  }

  uploadProfilePic(url: string): Observable<UserFull> {
    return this.apiService
      .put<
        ShowResponse<UserFull>
      >("me?type=user-signature", true, { image_url: url })
      .pipe(map(res => res.data));
  }
}
