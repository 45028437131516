<div class="signature-pad">
  <div class="body">
    <canvas #signaturePad style="width: 100%; height: 100%"></canvas>
    <div class="actions">
      <button *ngIf="hasValue" (click)="clear()" color="warn" mat-button>
        Register New
      </button>
      <button *ngIf="!hasValue" (click)="clear()" color="warn" mat-button>
        Clear
      </button>
    </div>
  </div>
</div>
