<div class="dialog">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>{{ "Edit" }}</span>
      <span class="spacer"></span>
      <mat-icon (click)="close()">clear</mat-icon>
    </mat-toolbar-row>
  </mat-toolbar>
  <div [formGroup]="group" class="container">
    <!--  <div [formGroup]="group" class="emc-box">-->
    <div class="elements">
      <p class="label">Responsive Action</p>
      <ae-autocomplete
        [control]="controls.title"
        [options]="itemOptions"
        placeholder="Choose Responsive Action"
      >
      </ae-autocomplete>
    </div>
    <div class="elements">
      <p class="label">Location</p>
      <mat-form-field floatLabel="never">
        <input
          [formControl]="controls.comment"
          matInput
          placeholder="Location"
          type="text"
        />
      </mat-form-field>
      <button
        (click)="onOpenMapClicked()"
        class="secondary open-button"
        mat-button
        type="button"
      >
        Select on map
      </button>
    </div>
    <div class="elements" *ngIf="config?.show_assigned_to">
      <p class="label">Assign to</p>
      <ae-search-field
        [control]="controls.assigned_to"
        [options]="userOptions"
        placeholder="Choose Assignee"
      ></ae-search-field>
    </div>
    <span class="spacer"></span>
    <div class="buttons">
      <button (click)="save()" color="primary" mat-flat-button>Save</button>
    </div>
    <!--  </div>-->
  </div>
</div>
