<div [formGroup]="group" class="elements">
  <div class="info">
    <mat-form-field floatLabel="never">
      <input
        formControlName="title"
        matInput
        placeholder="Title"
        type="text"
        required
      />
    </mat-form-field>
    <div class="actions">
      <mat-checkbox
        color="primary"
        *ngIf="group?.get('is_trigger')"
        formControlName="is_trigger"
        >Trigger Action Items</mat-checkbox
      >
      <button (click)="onDelete()" mat-icon-button>
        <mat-icon color="warn" matTooltip="Delete">delete</mat-icon>
      </button>
    </div>
  </div>
</div>
