import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Unsubscribe } from "../../../../../../../../../unsubscribe";
import { InspectionCategory } from "@emc-models/entities/inspection-category.model";
import { InspectionCategoryService } from "@emc-modules/tools/modules/template-categories/services/inspection-category.service";
import { takeUntil } from "rxjs/operators";
import { InspectionCategoryQuery } from "@emc-state/tools/inspection-category/inspection-category.query";
import { QuestionType } from "../../../../../../../../../enums/question-type.enum";
import { TemplateService } from "@emc-modules/tools/modules/templates/services/template.service";
import * as _ from "lodash";

@Component({
  selector: "ae-question-suggestion",
  templateUrl: "./question-suggestion.component.html",
  styleUrls: ["./question-suggestion.component.scss"]
})
export class QuestionSuggestionComponent extends Unsubscribe implements OnInit {
  @Input() group: FormGroup;
  @Input() templateId: number;
  @Output() delete = new EventEmitter();
  @Output() sortOrderChange = new EventEmitter();
  @Output() clone = new EventEmitter();
  @Input() set type(type: QuestionType) {
    this._type = type;
    if (type === QuestionType.PULTE) {
      this.group.get("title").setValidators([]);
    } else {
      this.group.get("title").setValidators([Validators.required]);
    }
  }
  sortOptions: number[] = [];
  categories: InspectionCategory[] = [];
  categoriesLoading: boolean;
  isPanelOpen = true;
  _type: QuestionType = QuestionType.STORM_WATER;
  QuestionType = QuestionType;

  get questionCategories(): InspectionCategory[] {
    return this.categories.filter(c => String(c.type) === String(this._type));
  }

  constructor(
    private templateService: TemplateService,
    private inspectionCategoryService: InspectionCategoryService,
    private inspectionCategoryQuery: InspectionCategoryQuery
  ) {
    super();
  }

  @Input() set length(length: number) {
    this.sortOptions = Array.from({ length }, (v, i) => i + 1);
  }

  ngOnInit() {
    this.inspectionCategoryService
      .getCategories()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        categories =>
          (this.categories = _.sortBy(categories, c => c.sort_order))
      );

    this.inspectionCategoryQuery.isLoading$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(l => (this.categoriesLoading = l));

    this.group
      .get("has_textfield")
      .valueChanges.subscribe((checked: boolean) => {
        if (checked) {
          // Uncheck 'has_sub_questions' if 'has_textfield' is checked
          this.group.get("has_sub_questions").setValue(false);
          const array: FormArray = this.group.get(
            "responsive_action_item_suggestions"
          ) as FormArray;
          array.clear();
          const optionsArray: FormArray = this.group.get(
            "options"
          ) as FormArray;
          optionsArray.clear();
          const subQuestionsArray: FormArray = this.group.get(
            "sub_questions"
          ) as FormArray;
          subQuestionsArray.clear();
        }
      });
    this.group
      .get("has_sub_questions")
      .valueChanges.subscribe((checked: boolean) => {
        if (checked) {
          // Uncheck 'has_sub_questions' if 'has_textfield' is checked
          this.group.get("has_textfield").setValue(false);
        }
      });
  }

  addResponsiveItem() {
    const array: FormArray = this.group.get(
      "responsive_action_item_suggestions"
    ) as FormArray;
    array.insert(
      0,
      new FormGroup({
        title: new FormControl(null, Validators.required),
        is_active: new FormControl(true, Validators.required)
      })
    );
  }

  addSubQuestion() {
    const array: FormArray = this.group.get("sub_questions") as FormArray;
    if (this.templateId === 63) {
      array.push(
        new FormGroup({
          title: new FormControl(null, Validators.required),
          options: new FormControl([""])
        })
      );
    } else {
      array.push(
        new FormGroup({
          title: new FormControl(null, Validators.required),
          options: new FormControl(["Yes", "No"]),
          has_textfield: new FormControl(false, Validators.required)
        })
      );
    }
  }

  addOption() {
    const array: FormArray = this.group.get("options") as FormArray;
    array.push(
      new FormGroup({
        title: new FormControl(null, Validators.required),
        is_trigger: new FormControl(false, Validators.required)
      })
    );
  }

  deleteOption(index: number) {
    const array: FormArray = this.group.get("options") as FormArray;
    array.removeAt(index);
  }

  deleteSubQuestion(index: number) {
    const array: FormArray = this.group.get("sub_questions") as FormArray;
    array.removeAt(index);
  }

  deleteResponsiveItem(index: number) {
    const array: FormArray = this.group.get(
      "responsive_action_item_suggestions"
    ) as FormArray;
    array.removeAt(index);
  }

  deleteQuestionSuggestion() {
    const id = this.group.value.id;
    this.delete.emit(id);
  }

  cloneQuestionSuggestion() {
    this.clone.emit();
  }

  changeSortOrder(order: number) {
    this.sortOrderChange.emit(order);
  }
}
