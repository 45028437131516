import { Injectable } from "@angular/core";
import { ApiService } from "@emc-modules/core/services/api.service";
import { AdminReportsQuery } from "@emc-state/request-reports/admin-reports/admin-reports.query";
import { AdminReportsStore } from "@emc-state/request-reports/admin-reports/admin-reports.store";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { ListResponse } from "../../../../../responses/list.response";
import { CommonUtils } from "@emc-utils/common-utils";
import {
  AdminReportAttachment,
  AdminUploadReport
} from "@emc-models/entities/admin-upload-reports.model";
import * as _ from "lodash";
import { ID } from "@datorama/akita";
import { saveAs } from "file-saver/dist/FileSaver";
import * as moment from "moment";
import { ExportFormat } from "../../../../../utils/constants";

@Injectable({
  providedIn: "root"
})
export class AdminUploadReportService {
  constructor(
    private apiService: ApiService,
    private query: AdminReportsQuery,
    private store: AdminReportsStore
  ) {}

  listAdminReports(offset: number = 0): Observable<AdminUploadReport[]> {
    let _loading = false;
    let _loaded = false;
    let _offset = 0;
    let _data: any = { offset };

    this.query.selectOffset$.pipe(take(1)).subscribe(o => (_offset = o));

    this.query.selectedClientIds$.pipe(take(1)).subscribe(ids => {
      if (ids && ids.length) {
        _data = {
          ..._data,
          client_ids: ids.join(",")
        };
      }
    });

    this.query.selectedProjectIds$.pipe(take(1)).subscribe(ids => {
      if (ids && ids.length) {
        _data = {
          ..._data,
          project_ids: ids.join(",")
        };
      }
    });

    this.query.selectedUserIds$.pipe(take(1)).subscribe(ids => {
      if (ids && ids.length) {
        _data = {
          ..._data,
          user_ids: ids.join(",")
        };
      }
    });

    this.query.selectStartDate$.pipe(take(1)).subscribe(date => {
      if (date) {
        _data = {
          ..._data,
          start_date: date
        };
      }
    });

    this.query.selectEndDate$.pipe(take(1)).subscribe(date => {
      if (date) {
        _data = {
          ..._data,
          end_date: date
        };
      }
    });

    this.query.selectQuery$.pipe(take(1)).subscribe(q => {
      if (q) {
        _data = {
          ..._data,
          query: q
        };
      }
    });

    this.query.selectedInspectionType$.pipe(take(1)).subscribe(s => {
      if (s && s.length) {
        _data = {
          ..._data,
          inspection_types: s.join(",")
        };
      }
    });

    this.query.selectAdminReportLoading$
      .pipe(take(1))
      .subscribe(l => (_loading = l));

    this.query.selectAdminReportLoaded$
      .pipe(take(1))
      .subscribe(l => (_loaded = l));

    if (!_loading && !_loaded && _offset <= offset) {
      this.store.update({ loading: true, loaded: false });
      this.apiService
        .get<ListResponse<AdminUploadReport>>("uploaded-reports", true, _data)
        .pipe(map(res => res.data))
        .subscribe(adminReports => {
          if (adminReports.length > 0) {
            const ids = adminReports.map(m => m.id);
            const entities = CommonUtils.normalize(adminReports);
            this.store.update(state => {
              state.fullLoadedIds.forEach(id => {
                delete entities[id];
              });
              return {
                offset: state.offset + adminReports.length,
                loading: false,
                ids: [...state.ids, ...ids],
                entities: {
                  ...state.entities,
                  ...entities
                }
              };
            });
          } else {
            this.store.update({
              loading: false,
              loaded: true
            });
          }
        });
    }
    return this.query.selectAll();
  }

  getAdminReportsById(adminReportId: number): Observable<AdminUploadReport> {
    let _loading = false;
    let _loaded = false;

    this.query
      .$selectIsFullLoading(adminReportId)
      .pipe(take(1))
      .subscribe(v => (_loading = v));

    this.query
      .$selectIsFullLoaded(adminReportId)
      .pipe(take(1))
      .subscribe(v => (_loaded = v));

    if (!_loading && !_loaded) {
      this.store.setFullLoading(adminReportId);
      this.apiService
        .get<any>(`uploaded-reports/${adminReportId}`, true)
        .subscribe(res => {
          this.store.update(state => {
            let ids = [...state.ids, adminReportId];
            ids = _.uniq(ids);
            return {
              ids,
              entities: {
                ...state.entities,
                [adminReportId]: res.data
              }
            };
          });
          this.store.setFullLoaded(adminReportId);
        });
    }
    return this.query
      .selectEntity(adminReportId)
      .pipe(filter((adminReport: AdminUploadReport) => !!adminReport));
  }

  createAdminReport(data: any): Observable<AdminUploadReport> {
    return this.apiService
      .post<any>("uploaded-reports?type=uploaded-report", true, data)
      .pipe(
        map(res => {
          this.store.add(res.data);
          return res.data;
        })
      );
  }

  setAdminReportFilters(data: {
    start_date: string;
    end_date: string;
    client_ids: number[];
    project_ids: number[];
    user_ids: number[];
    search_query: string;
    inspection_types: string[];
  }) {
    this.store.reset();
    this.store.update({
      startDate: data.start_date,
      endDate: data.end_date,
      clientIds: data.client_ids,
      projectIds: data.project_ids,
      userIds: data.user_ids,
      query: data.search_query,
      inspectionType: data.inspection_types
    });
  }

  updateAdminReport(
    adminReportId: number,
    data: Partial<AdminUploadReport>
  ): Observable<AdminUploadReport> {
    return this.apiService
      .put<any>(
        `uploaded-reports/${adminReportId}?type=uploaded-report`,
        true,
        data
      )
      .pipe(
        map(res => {
          this.store.upsert(adminReportId, res.data);
          return res.data;
        })
      );
  }

  downloadInspectionAttachment(attachment: AdminReportAttachment): void {
    saveAs(attachment.attachment_url, attachment.title);
  }

  deleteAdminReport(id: ID): Observable<void> {
    return this.apiService.delete(`uploaded-reports/${id}`, true).pipe(
      map(res => {
        this.store.remove(id);
      })
    );
  }

  deleteAttachment(reportId: ID, attachmentId: ID): Observable<void> {
    return this.apiService
      .delete(`uploaded-report-attachments/${attachmentId}`, true)
      .pipe(
        map(res => {
          this.store.update(
            e => e.id === reportId,
            (report: AdminUploadReport) => {
              let attachments = report.attachments || [];
              const index = attachments.findIndex(a => a.id === attachmentId);
              if (index !== -1) {
                attachments = [
                  ...attachments.slice(0, index),
                  ...attachments.slice(index + 1)
                ];
              }

              return {
                ...report,
                attachments
              };
            }
          );
          return;
        })
      );
  }

  exportUploadReports(
    format: ExportFormat | "pdf",
    uploadReportIds: ID[],
    allSelected: boolean,
    filters?: any
  ): Observable<void> {
    let data: any = {
      format,
      is_export_email: format == "pdf"
    };

    if (allSelected) {
      if (filters.inspection_types) {
        data.inspection_types = filters.inspection_types;
      }

      if (filters?.start_date) {
        data.start_date = moment(filters.start_date).format("YYYY-MM-DD");
      }

      if (filters?.end_date) {
        data.end_date = moment(filters.end_date).format("YYYY-MM-DD");
      }

      if (filters?.client_ids?.length) {
        data.client_ids = filters.client_ids;
      }

      if (filters?.search_query) {
        data.query = filters.search_query;
      }

      if (filters?.user_ids?.length) {
        data.inspector_ids = filters.inspector_ids;
      }

      if (filters?.project_ids?.length) {
        data.project_ids = filters.project_ids;
      }

      data = {
        ...data,
        export_all: allSelected
      };
    } else {
      data = {
        ...data,
        uploaded_report_ids: uploadReportIds
      };
    }

    return this.apiService.exportUploadReports(data);
  }
}
