export enum QuestionType {
  STORM_WATER = "storm_water",
  SAFETY = "safety",
  MARYLAND = "maryland",
  PULTE = "pulte",
  ENERGY = "energy",
  QA = "qa",
  QA_ENERGY = "qa,energy",
  PRE_ACQUISITION = "pre_acquisition"
}
